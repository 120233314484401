import React, { useCallback } from "react";
import { GenericShopListFooter, useDeviceTypes } from "halifax";
import { RouteComponentProps } from "react-router";
import clsx from "clsx";

import "./styles.scss";
import { FlightFindMoreResultsConnectorProps } from "./container";
import { PATH_HOME, PATH_HOME_SEARCH } from "../../../../../../utils/urlPaths";
import { MobileFlightSearchStep } from "../../../../../search/reducer";
import * as constants from "./textConstants";
import { FlightShopType } from "redmond";
import { useNavigateToChfar } from "../../../../../change-for-any-reason/hooks/useNavigateToChfar";

export interface IFlightFindMoreResultsProps
  extends FlightFindMoreResultsConnectorProps,
    RouteComponentProps {
  className?: string;
}

export const FlightFindMoreResults = (props: IFlightFindMoreResultsProps) => {
  const {
    className,
    resetFilters,
    setMobileSearchProgress,
    history,
    setOpenFlightShopCalendarDesktop,
    filters,
    flightShopType,
  } = props;
  const { matchesMobile } = useDeviceTypes();

  const [appliedFilters, setAppliedFilters] = React.useState<string[]>([]);

  React.useEffect(() => {
    const appliedFilters = Object.keys(filters).filter(
      (key) => props.filters[key]
    );
    setAppliedFilters(appliedFilters);
  }, [filters]);

  const navigateToChfar = useNavigateToChfar();

  const handleOnClick = useCallback(() => {
    if (flightShopType === FlightShopType.CHANGE_FOR_ANY_REASON_EXERCISE) {
      return navigateToChfar();
    }
    if (matchesMobile) {
      setMobileSearchProgress(MobileFlightSearchStep.CalendarPicker);
      history.push(matchesMobile ? PATH_HOME_SEARCH : PATH_HOME);
    } else {
      setOpenFlightShopCalendarDesktop(true);
    }
  }, [
    matchesMobile,
    setMobileSearchProgress,
    history,
    setOpenFlightShopCalendarDesktop,
    flightShopType,
    navigateToChfar,
  ]);

  return (
    <>
      <GenericShopListFooter
        className={clsx("find-more-results", className, "b2b")}
        title={constants.NO_MORE_FLIGHTS_FOUND}
        subtitle={
          appliedFilters.length > 0
            ? constants.NO_FLIGHTS_FILTER
            : constants.NO_MORE_FLIGHTS_FOUND_SUBTITLE
        }
        buttons={
          appliedFilters.length > 0
            ? [
                {
                  title: constants.RESET_FILTERS,
                  onClick: () => resetFilters(),
                  className: "b2b",
                },
                {
                  title: constants.ADJUST_DATES,
                  isPrimary: true,
                  onClick: handleOnClick,
                  className: "b2b",
                },
              ]
            : [
                {
                  title: constants.ADJUST_DATES,
                  isPrimary: true,
                  onClick: handleOnClick,
                  className: "b2b",
                },
              ]
        }
        isMobile={matchesMobile}
      />
    </>
  );
};
