import { useSelector } from "react-redux";
import { FlightShopStep } from "redmond";
import {
  formatShortDate,
  getLocalTime,
  getTimeDifference,
  pluralize,
} from "halifax";
import { useChfarPolicy } from "./useChfarPolicy";
import { flightShopProgressSelector } from "../../shop/reducer";
import { useItineraryIdParam } from "./useItineraryIdParam";

export const usePreviousFlight = () => {
  const itineraryId = useItineraryIdParam();
  const { data } = useChfarPolicy(itineraryId);

  const flightShopStep = useSelector(flightShopProgressSelector);

  const isChooseDeparture = flightShopStep === FlightShopStep.ChooseDeparture;

  const slice = data?.slices[isChooseDeparture ? 0 : 1];

  if (!slice) {
    return null;
  }

  const {
    destinationName,
    destinationCode,
    airlines,
    stops: numberOfStops,
    departureDate,
    arrivalDate,
  } = slice;

  const formattedDate = formatShortDate(departureDate);
  const departureTime = getLocalTime(departureDate);
  const arrivalTime = getLocalTime(arrivalDate);

  const stops = `${numberOfStops} ${pluralize(numberOfStops, "stop", "stops")}`;

  const flightStops = numberOfStops === 0 ? "Nonstop" : stops;

  const flightDuration = getTimeDifference(arrivalDate, departureDate);

  return {
    formattedDate,
    departureTime,
    destinationName,
    destinationCode,
    arrivalTime,
    flightDuration,
    flightStops,
    airlines,
  };
};
