import React, { useMemo } from "react";
import { Box, Typography } from "@material-ui/core";
import { ActionLink, ButtonWrap } from "halifax";
import { FlightShopType } from "redmond";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import { RouteComponentProps } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { History } from "history";
import clsx from "clsx";

import "./styles.scss";
import { FlightShopProgressBarConnectorProps } from "./container";
import { FlightShopStep, ProductRedeemChoice } from "../../reducer";
import { PATH_BOOK } from "../../../../utils/urlPaths";
import { pushToDisruptionOverview } from "../../../ancillary/utils";
import * as constants from "./constants";
import { useNavigateToChfar } from "../../../change-for-any-reason/hooks/useNavigateToChfar";

interface IFlightShopProgressBarProps
  extends FlightShopProgressBarConnectorProps,
    RouteComponentProps {
  isReviewItineraryHidden?: boolean;
  useBackToChooseDeparture?: boolean;
  useDisruptionProtectionStep?: boolean;
  useCustomizeStep?: boolean;
  usePush?: boolean;
  prevPath?: string;
}

// note: in the single-page marketplace design, a "book" step (which indicates the checkout screen) is added to the end of the progress bar
const BOOK_STEP = "book-step";
const DISRUPTION_PROTECTION_STEP = "disruption-protection-step";
const CHFAR_MISSION_CONTROL_STEP = "chfar-mission-control-step";

type FlightShopStepV2 =
  | FlightShopStep
  | typeof BOOK_STEP
  | typeof DISRUPTION_PROTECTION_STEP
  | typeof CHFAR_MISSION_CONTROL_STEP;

enum CompareStepsResult {
  Before,
  Same,
  After,
  Unknown,
}

export const FlightShopProgressBar = (props: IFlightShopProgressBarProps) => {
  const {
    isOneWay,
    currentProgress,
    setFlightShopProgress,
    isTripDetailsEmpty,
    isSelectReturnReady,
    isFlightReviewReady,
    isReviewItineraryHidden,
    isAddOnOptionAvailable,
    isOptionSelectionComplete,
    isSelectingReturnFlightForRebook,
    populateFlightBookQueryParams,
    populateFlightShopQueryParams,
    useBackToChooseDeparture,
    useDisruptionProtectionStep,
    useCustomizeStep,
    usePush,
    prevPath,
    history,
    productRedeemChoice,
    flightShopType,
  } = props;

  const isChfarShopType =
    flightShopType === FlightShopType.CHANGE_FOR_ANY_REASON_EXERCISE;

  const renderProgressButtons = (progresses: FlightShopStepV2[]) => {
    const isInFlightBook = history.location.pathname === PATH_BOOK;

    const goToFlightShopStep = (progress: FlightShopStep) => {
      populateFlightShopQueryParams({
        history,
        prevPath,
        useHistoryPush: true,
        forceQueryUpdate: true,
        newQueryParams: {
          flightShopProgress: progress,
        },
      });
    };

    return progresses.map((progress: FlightShopStepV2, index: number) => {
      const selectedStep: FlightShopStepV2 = isInFlightBook
        ? BOOK_STEP
        : currentProgress;

      return (
        <React.Fragment key={index}>
          <FlightShopProgressButton
            key={`button-${index}`}
            isTripDetailsEmpty={isTripDetailsEmpty}
            productRedeemChoice={productRedeemChoice}
            isSelectReturnReady={isSelectReturnReady}
            isFlightReviewReady={isFlightReviewReady}
            isAddOnOptionAvailable={isAddOnOptionAvailable}
            isOptionSelectionComplete={isOptionSelectionComplete}
            flightShopType={flightShopType}
            selectedStep={selectedStep}
            progress={progress}
            history={history}
            customChooseDepartureCopy={
              useDisruptionProtectionStep
                ? isSelectingReturnFlightForRebook
                  ? constants.CHOOSE_RETURN_TEXT
                  : constants.CHOOSE_OUTBOUND_TEXT
                : undefined
            }
            setFlightShopProgress={setFlightShopProgress}
            goToCheckout={() => populateFlightBookQueryParams({ history })}
            goToFlightShopStep={usePush ? goToFlightShopStep : undefined}
          />
          {index < progresses.length - 1 && (
            <FontAwesomeIcon
              key={`separator-${index}`}
              className="progress-button-separator"
              icon={faChevronRight}
            />
          )}
        </React.Fragment>
      );
    });
  };

  const getProgresses = (): FlightShopStepV2[] => {
    if (isChfarShopType) {
      return [
        CHFAR_MISSION_CONTROL_STEP,
        FlightShopStep.ChooseDeparture,
        FlightShopStep.ReviewItinerary,
        BOOK_STEP,
      ];
    }

    const progresses: FlightShopStepV2[] = [];

    if (useDisruptionProtectionStep) {
      progresses.push(DISRUPTION_PROTECTION_STEP);
    }
    progresses.push(FlightShopStep.ChooseDeparture);
    if (!isOneWay) {
      progresses.push(FlightShopStep.ChooseReturn);
    }
    if (!isReviewItineraryHidden) {
      progresses.push(FlightShopStep.ReviewItinerary);
    }
    if (useCustomizeStep) {
      progresses.push(FlightShopStep.Customize);
      progresses.push(BOOK_STEP);
    }

    return progresses;
  };

  return (
    <Box className="flight-shop-progress-bar-root">
      <Box className="flight-shop-progress-bar-container">
        {useBackToChooseDeparture ? (
          <BackToChooseDeparture
            setFlightShopProgress={setFlightShopProgress}
          />
        ) : (
          renderProgressButtons(getProgresses())
        )}
      </Box>
    </Box>
  );
};

interface IFlightShopProgressButtonProps {
  isTripDetailsEmpty: boolean;
  isSelectReturnReady: boolean;
  isFlightReviewReady: boolean;
  isAddOnOptionAvailable: boolean;
  isOptionSelectionComplete: boolean;
  selectedStep: FlightShopStepV2;
  progress: FlightShopStepV2;
  customChooseDepartureCopy?: string;
  history: History;
  setFlightShopProgress: (progress: FlightShopStep) => void;
  goToFlightShopStep?: (progress: FlightShopStep) => void;
  goToCheckout?: () => void;
  productRedeemChoice?: ProductRedeemChoice;
  flightShopType: FlightShopType;
}

const FlightShopProgressButton = (props: IFlightShopProgressButtonProps) => {
  const {
    isTripDetailsEmpty,
    isSelectReturnReady,
    isFlightReviewReady,
    isAddOnOptionAvailable,
    isOptionSelectionComplete,
    selectedStep,
    progress,
    customChooseDepartureCopy,
    history,
    setFlightShopProgress,
    goToFlightShopStep,
    goToCheckout,
    productRedeemChoice,
    flightShopType,
  } = props;

  const isChfarShopType =
    flightShopType === FlightShopType.CHANGE_FOR_ANY_REASON_EXERCISE;

  const navigateToChfar = useNavigateToChfar();

  const getProgressText = (progress: FlightShopStepV2) => {
    switch (progress) {
      case CHFAR_MISSION_CONTROL_STEP:
        return constants.CHFAR_MISSION_CONTROL_TEXT;
      case DISRUPTION_PROTECTION_STEP: {
        return productRedeemChoice === "missed_connection_vi"
          ? // ? constants.MISSED_CONNECTION_GUARANTEE
            constants.MISSED_CONNECTION_REBOOKING
          : constants.DISRUPTION_PROTECTION_TEXT;
      }
      case FlightShopStep.ChooseDeparture: {
        if (isChfarShopType) {
          return constants.CHFAR_CHOOSE_NEW_FLIGHTS_TEXT;
        }
        return customChooseDepartureCopy ?? constants.CHOOSE_DEPARTURE_TEXT;
      }
      case FlightShopStep.ChooseReturn: {
        return constants.CHOOSE_RETURN_TEXT;
      }
      case FlightShopStep.ReviewItinerary: {
        return constants.REVIEW_ITINERARY_TEXT;
      }
      case FlightShopStep.Customize: {
        return constants.CUSTOMIZE_TEXT;
      }
      case BOOK_STEP: {
        return constants.BOOK_TEXT;
      }
      default: {
        return "";
      }
    }
  };

  const compareSteps = (step1: FlightShopStepV2, step2: FlightShopStepV2) => {
    // Only steps in the normal shop flow are added.
    const stepOrdering: FlightShopStepV2[] = [
      FlightShopStep.ChooseDeparture,
      FlightShopStep.ChooseReturn,
      FlightShopStep.ReviewItinerary,
      FlightShopStep.FareDetails,
      FlightShopStep.Customize,
      BOOK_STEP,
    ];

    const index1 = stepOrdering.findIndex((s) => s === step1);
    const index2 = stepOrdering.findIndex((s) => s === step2);

    if (index1 === -1 || index2 === -1) {
      return CompareStepsResult.Unknown;
    }
    if (index1 === index2) {
      return CompareStepsResult.Same;
    }
    if (index1 < index2) {
      return CompareStepsResult.Before;
    }
    return CompareStepsResult.After;
  };

  const isDisabled = (
    progress: FlightShopStepV2,
    selectedStep: FlightShopStepV2
  ) => {
    const isReviewItineraryReady = !isTripDetailsEmpty && isFlightReviewReady;

    if (compareSteps(selectedStep, progress) === CompareStepsResult.Before) {
      return true;
    }

    switch (progress) {
      case FlightShopStep.PricePrediction:
      case DISRUPTION_PROTECTION_STEP:
      case CHFAR_MISSION_CONTROL_STEP:
      case FlightShopStep.ChooseDeparture: {
        return false;
      }
      case FlightShopStep.ChooseReturn: {
        return !isSelectReturnReady;
      }
      case FlightShopStep.ReviewItinerary: {
        return !isReviewItineraryReady;
      }
      case FlightShopStep.Customize: {
        return !isReviewItineraryReady || !isAddOnOptionAvailable;
      }
      case BOOK_STEP: {
        return (
          !isReviewItineraryReady ||
          (isAddOnOptionAvailable && !isOptionSelectionComplete)
        );
      }
      default: {
        return true;
      }
    }
  };

  const handleOnClick = () => {
    switch (progress) {
      case BOOK_STEP: {
        if (goToCheckout) goToCheckout();
        break;
      }
      case CHFAR_MISSION_CONTROL_STEP: {
        navigateToChfar();
        break;
      }
      case DISRUPTION_PROTECTION_STEP: {
        pushToDisruptionOverview({ history });
        break;
      }
      default: {
        setFlightShopProgress(progress);
        if (goToFlightShopStep) goToFlightShopStep(progress);
        break;
      }
    }
  };

  const isSelected = useMemo(() => {
    // For ChFAR, the departure step and return step are combined into one breadcrumb, but it can only be associated with one step at a time.
    if (
      flightShopType === FlightShopType.CHANGE_FOR_ANY_REASON_EXERCISE &&
      selectedStep === FlightShopStep.ChooseReturn
    ) {
      return progress === FlightShopStep.ChooseDeparture;
    }
    return selectedStep === progress;
  }, [flightShopType, selectedStep, progress]);

  return (
    <ActionLink
      className={clsx(
        "flight-shop-progress-button",
        { selected: isSelected },
        "b2b"
      )}
      onClick={handleOnClick}
      content={getProgressText(progress)}
      disabled={isDisabled(progress, selectedStep)}
    />
  );
};

interface IBackToChooseDepartureProps {
  className?: string;
  setFlightShopProgress: (progress: FlightShopStep) => void;
}

const BackToChooseDeparture = (props: IBackToChooseDepartureProps) => {
  const { className, setFlightShopProgress } = props;
  return (
    <Box className={clsx("back-to-choose-departure-root", className)}>
      <Box className="back-to-choose-departure-container">
        <ButtonWrap
          className="back-to-flight-results-button"
          aria-label={constants.BACK_TO_FLIGHT_RESULTS}
          onClick={() => setFlightShopProgress(FlightShopStep.ChooseDeparture)}
        >
          <FontAwesomeIcon className="back-arrow-icon" icon={faChevronLeft} />
          <Typography className="back-to-flight-results-copy" variant="inherit">
            {constants.BACK_TO_FLIGHT_RESULTS}
          </Typography>
        </ButtonWrap>
      </Box>
    </Box>
  );
};
