import {
  SliceStopCountFilter,
  TripCategory,
  FlightShopType,
  FLIGHT_SHOP_TYPE,
  PRICE_FREEZE_ID_QUERY_PARAM,
  DisruptionProtectionQueryParam,
  FlightEntryTypeEnum,
} from "redmond";
import dayjs from "dayjs";

import * as H from "history";
import queryStringParser from "query-string";
import { PATH_SHOP } from "../../../utils/urlPaths";
import { FlightShopStep, MulticityFlightShopStep } from "../reducer";

export interface IFlightShopParsedQuery {
  origin: string;
  originRegionType: string | null;
  destination: string;
  destinationRegionType: string | null;
  departureDate: Date | null;
  returnDate: Date | null;
  tripCategory: TripCategory;
  noLCC: boolean;
  stopsOption: string;
  flightShopProgress: number;
  isFromFlightWatch?: boolean;
  [FLIGHT_SHOP_TYPE]?: FlightShopType;
  [PRICE_FREEZE_ID_QUERY_PARAM]?: string;
  [DisruptionProtectionQueryParam.ItineraryId]?: string;
  [DisruptionProtectionQueryParam.ProductRedeemChoice]?:
    | "missed_connection"
    | "missed_connection_vi"
    | "delay";
  [DisruptionProtectionQueryParam.SliceIndex]?: number;
  [DisruptionProtectionQueryParam.SegmentIndex]?: number;
  [DisruptionProtectionQueryParam.ActiveDisruption]?:
    | "missed_connection"
    | "missed_connection_vi"
    | "delay"
    | "involuntary_cancel"
    | "none";
  adultsCount?: number;
  childrenCount?: number;
  infantsInSeatCount?: number;
  infantsOnLapCount?: number;
  flightShopEntryPoint?: FlightEntryTypeEnum;
  fareClass?: string[];
}

// const sampleMulticityParams =
//   "departureDate0=2023-06-30&departureDate1=2023-07-05&departureDate2=2023-07-15&destination0=LGA&destination1=MCO&destination2=YOW&origin0=YYZ&origin1=CVG&origin2=FLL";

export interface IMulticityFlightShopParsedQuery {
  origin0: string;
  origin1: string;
  origin2?: string;
  origin3?: string;
  origin4?: string;
  destination0: string;
  destination1: string;
  destination2?: string;
  destination3?: string;
  destination4?: string;
  departureDate0: Date | null;
  departureDate1: Date | null;
  departureDate2?: Date | null;
  departureDate3?: Date | null;
  departureDate4?: Date | null;
  tripCategory: TripCategory;
  noLCC: boolean;
  stopsOption: string;
  multicityFlightShopProgress: number;
  [FLIGHT_SHOP_TYPE]?: FlightShopType;
  adultsCount?: number;
  childrenCount?: number;
  infantsInSeatCount?: number;
  infantsOnLapCount?: number;
  fareClass?: string[];
}

const getNullableStringFromParam = (param: string | string[] | null) => {
  if (Array.isArray(param)) {
    return param[0];
  }
  return param;
};

export const parseQueryString = (
  history: H.History,
  tripCategory?: TripCategory
): IFlightShopParsedQuery | IMulticityFlightShopParsedQuery => {
  const queryString = history?.location?.search || "";
  return parseQueryFromString(queryString, tripCategory);
};
export const parseQueryFromString = (
  queryString: string,
  tripCategory?: TripCategory
): IFlightShopParsedQuery | IMulticityFlightShopParsedQuery => {
  const parsedQueryStringPrimitive = queryStringParser.parse(queryString);
  const productRedeemChoice =
    parsedQueryStringPrimitive[
      DisruptionProtectionQueryParam.ProductRedeemChoice
    ];
  const queryTripCategory =
    tripCategory ?? (parsedQueryStringPrimitive.tripCategory as TripCategory);

  const passengerCounts = {
    adultsCount: Number(
      (parsedQueryStringPrimitive.adultsCount as string) ?? 1
    ),
    childrenCount: Number(
      (parsedQueryStringPrimitive.childrenCount as string) ?? 0
    ),
    infantsInSeatCount: Number(
      (parsedQueryStringPrimitive.infantsInSeatCount as string) ?? 0
    ),
    infantsOnLapCount: Number(
      (parsedQueryStringPrimitive.infantsOnLapCount as string) ?? 0
    ),
  };

  if (queryTripCategory === TripCategory.MULTI_CITY) {
    return {
      tripCategory: queryTripCategory,
      origin0: parsedQueryStringPrimitive.origin0 as string,
      origin1: parsedQueryStringPrimitive.origin1 as string,
      origin2: (parsedQueryStringPrimitive.origin2 as string) || undefined,
      origin3: (parsedQueryStringPrimitive.origin3 as string) || undefined,
      origin4: (parsedQueryStringPrimitive.origin4 as string) || undefined,
      destination0: parsedQueryStringPrimitive.destination0 as string,
      destination1: parsedQueryStringPrimitive.destination1 as string,
      destination2:
        (parsedQueryStringPrimitive.destination2 as string) || undefined,
      destination3:
        (parsedQueryStringPrimitive.destination3 as string) || undefined,
      destination4:
        (parsedQueryStringPrimitive.destination4 as string) || undefined,
      departureDate0: parsedQueryStringPrimitive.departureDate0
        ? dayjs(parsedQueryStringPrimitive.departureDate0 as string).toDate()
        : null,
      departureDate1: parsedQueryStringPrimitive.departureDate1
        ? dayjs(parsedQueryStringPrimitive.departureDate1 as string).toDate()
        : null,
      departureDate2: parsedQueryStringPrimitive.departureDate2
        ? dayjs(parsedQueryStringPrimitive.departureDate2 as string).toDate()
        : undefined,
      departureDate3: parsedQueryStringPrimitive.departureDate3
        ? dayjs(parsedQueryStringPrimitive.departureDate3 as string).toDate()
        : undefined,
      departureDate4: parsedQueryStringPrimitive.departureDate4
        ? dayjs(parsedQueryStringPrimitive.departureDate4 as string).toDate()
        : undefined,
      noLCC: (parsedQueryStringPrimitive.noLCC as string) === "true",
      stopsOption:
        parsedQueryStringPrimitive.stopsOption as SliceStopCountFilter,
      multicityFlightShopProgress: Number(
        parsedQueryStringPrimitive.multicityFlightShopProgress ??
          MulticityFlightShopStep.ChooseDeparture0
      ),
      [FLIGHT_SHOP_TYPE]: parsedQueryStringPrimitive[
        FLIGHT_SHOP_TYPE
      ] as FlightShopType,
      ...passengerCounts,
    } as IMulticityFlightShopParsedQuery;
  }

  return {
    tripCategory: queryTripCategory,
    origin: parsedQueryStringPrimitive.origin as string,
    destination: parsedQueryStringPrimitive.destination as string,
    departureDate: parsedQueryStringPrimitive.departureDate
      ? dayjs(parsedQueryStringPrimitive.departureDate as string).toDate()
      : null,
    returnDate: parsedQueryStringPrimitive.returnDate
      ? dayjs(parsedQueryStringPrimitive.returnDate as string).toDate()
      : null,
    noLCC: (parsedQueryStringPrimitive.noLCC as string) === "true",
    stopsOption: parsedQueryStringPrimitive.stopsOption as SliceStopCountFilter,
    flightShopProgress: Number(
      parsedQueryStringPrimitive.flightShopProgress ??
        FlightShopStep.ChooseDeparture
    ),
    isFromFlightWatch:
      (parsedQueryStringPrimitive.isFromFlightWatch as string) === "true" ||
      undefined,
    [FLIGHT_SHOP_TYPE]: parsedQueryStringPrimitive[
      FLIGHT_SHOP_TYPE
    ] as FlightShopType,
    [PRICE_FREEZE_ID_QUERY_PARAM]: parsedQueryStringPrimitive[
      PRICE_FREEZE_ID_QUERY_PARAM
    ] as string,
    [DisruptionProtectionQueryParam.ItineraryId]: parsedQueryStringPrimitive[
      DisruptionProtectionQueryParam.ItineraryId
    ] as string,
    [DisruptionProtectionQueryParam.ProductRedeemChoice]:
      productRedeemChoice === "missed_connection" ||
      productRedeemChoice === "delay" ||
      productRedeemChoice === "missed_connection_vi"
        ? productRedeemChoice
        : undefined,
    [DisruptionProtectionQueryParam.SliceIndex]: +(parsedQueryStringPrimitive[
      DisruptionProtectionQueryParam.SliceIndex
    ] as string),
    [DisruptionProtectionQueryParam.SegmentIndex]: +(parsedQueryStringPrimitive[
      DisruptionProtectionQueryParam.SegmentIndex
    ] as string),
    ...passengerCounts,
    flightShopEntryPoint:
      parsedQueryStringPrimitive.entryPoint as FlightEntryTypeEnum,
    fareClass: parsedQueryStringPrimitive.fareClass as string[],
    originRegionType: getNullableStringFromParam(
      parsedQueryStringPrimitive.originRegionType
    ),
    destinationRegionType: getNullableStringFromParam(
      parsedQueryStringPrimitive.destinationRegionType
    ),
  };
};

export const pushToPathWithExistingQueryParams = (
  history: H.History,
  pathname: string,
  params: any,
  replace?: boolean
) => {
  const getUpdatedParams = () => {
    const existingParams = parseQueryString(history);
    if (existingParams.tripCategory === TripCategory.MULTI_CITY) {
      const existingMulticityParams =
        existingParams as IMulticityFlightShopParsedQuery;
      return {
        ...existingMulticityParams,
        departureDate0: existingMulticityParams.departureDate0
          ? dayjs(existingMulticityParams.departureDate0).format("YYYY-MM-DD")
          : undefined,
        departureDate1: existingMulticityParams.departureDate1
          ? dayjs(existingMulticityParams.departureDate1).format("YYYY-MM-DD")
          : undefined,
        departureDate2: existingMulticityParams.departureDate2
          ? dayjs(existingMulticityParams.departureDate2).format("YYYY-MM-DD")
          : undefined,
        departureDate3: existingMulticityParams.departureDate3
          ? dayjs(existingMulticityParams.departureDate3).format("YYYY-MM-DD")
          : undefined,
        departureDate4: existingMulticityParams.departureDate4
          ? dayjs(existingMulticityParams.departureDate4).format("YYYY-MM-DD")
          : undefined,
        ...params,
      };
    } else {
      const existingOWRTParams = existingParams as IFlightShopParsedQuery;
      const newParams = {
        ...existingOWRTParams,
        departureDate: existingOWRTParams.departureDate
          ? dayjs(existingOWRTParams.departureDate).format("YYYY-MM-DD")
          : undefined,
        returnDate: existingOWRTParams.returnDate
          ? dayjs(existingOWRTParams.returnDate).format("YYYY-MM-DD")
          : undefined,
        ...params,
      };
      const newSimilarFlightsParams = {
        flightShopProgress: existingOWRTParams.flightShopProgress,
        [FLIGHT_SHOP_TYPE]: existingOWRTParams[FLIGHT_SHOP_TYPE],
        [PRICE_FREEZE_ID_QUERY_PARAM]:
          existingOWRTParams[PRICE_FREEZE_ID_QUERY_PARAM],
        ...params,
      };
      const newRebookFlightsParams = {
        flightShopProgress: existingOWRTParams.flightShopProgress,
        [FLIGHT_SHOP_TYPE]: existingOWRTParams[FLIGHT_SHOP_TYPE],
        [DisruptionProtectionQueryParam.ItineraryId]:
          existingOWRTParams[DisruptionProtectionQueryParam.ItineraryId],
        [DisruptionProtectionQueryParam.ProductRedeemChoice]:
          existingOWRTParams[
            DisruptionProtectionQueryParam.ProductRedeemChoice
          ],
        origin: existingOWRTParams[DisruptionProtectionQueryParam.Origin],
        destination:
          existingOWRTParams[DisruptionProtectionQueryParam.Destination],
        [DisruptionProtectionQueryParam.SliceIndex]:
          existingOWRTParams[DisruptionProtectionQueryParam.SliceIndex],
        [DisruptionProtectionQueryParam.SegmentIndex]:
          existingOWRTParams[DisruptionProtectionQueryParam.SegmentIndex],
        ...params,
      };

      return (() => {
        switch (newParams[FLIGHT_SHOP_TYPE]) {
          case FlightShopType.PRICE_FREEZE_EXERCISE:
            return newSimilarFlightsParams;
          case FlightShopType.DISRUPTION_PROTECTION_EXERCISE:
            return newRebookFlightsParams;
          default:
            return newParams;
        }
      })();
    }
  };

  if (replace) {
    history.replace(
      {
        pathname,
        search: queryStringParser.stringify(getUpdatedParams()),
      },
      { fromPage: history.location.pathname }
    );
  } else {
    history.push(
      {
        pathname,
        search: queryStringParser.stringify(getUpdatedParams()),
      },
      { fromPage: history.location.pathname }
    );
  }
};

export const updateFlightShopStep = (
  val: FlightShopStep,
  replace: boolean,
  history: H.History
) => {
  pushToPathWithExistingQueryParams(
    history,
    history.location.pathname,
    {
      flightShopProgress: val,
    },
    replace
  );
};

export const updateMulticityFlightShopStep = (
  val: MulticityFlightShopStep,
  replace: boolean,
  history: H.History
) => {
  pushToPathWithExistingQueryParams(
    history,
    PATH_SHOP,
    {
      multicityFlightShopProgress: val,
    },
    replace
  );
};

export const getMulticityDepartureStringValuesFromParsedShopQuery = (
  parsedShopQuery: IMulticityFlightShopParsedQuery
): { origin: string; destination: string; departureDate: string }[] => {
  const MAX_NUM_ROUTES = 5;

  const departureRoutes = [];

  for (let i = 0; i <= MAX_NUM_ROUTES; i++) {
    const origin = parsedShopQuery[`origin${i}`];
    const destination = parsedShopQuery[`destination${i}`];
    const departureDate = dayjs(parsedShopQuery[`departureDate${i}`]).format(
      "YYYY-MM-DD"
    );

    departureRoutes.push({ origin, destination, departureDate });
  }

  return departureRoutes.filter(
    (r) => !!r.origin && !!r.destination && !!r.departureDate
  );
};

export interface RecentlySearchedFlightShopQuery {
  tripCategory: TripCategory;
  origin: string;
  destination: string;
  departureDate: string;
  returnDate?: string;
  stopsOptions?: SliceStopCountFilter;
  noLCC?: boolean;
  flightShopProgress?: FlightShopStep;
  adultsCounts: number;
  childrenCount?: number;
  infantsInSeatsCount?: number;
  infantsOnLapCount?: number;
  entryPoint?: FlightEntryTypeEnum;
  fareClass?: string[];
}

export const transformToStringifiedRecentlySearchedFlightShopQuery = ({
  tripCategory,
  origin,
  destination,
  departureDate,
  returnDate,
  stopsOptions = SliceStopCountFilter.ANY_NUMBER,
  noLCC = false,
  flightShopProgress = FlightShopStep.ChooseDeparture,
  adultsCounts,
  childrenCount,
  infantsInSeatsCount,
  infantsOnLapCount,
  entryPoint,
  fareClass,
}: RecentlySearchedFlightShopQuery) => {
  const formatDepartureDate =
    typeof departureDate !== "string"
      ? dayjs(departureDate).format("YYYY-MM-DD")
      : departureDate;
  const formatReturnDate =
    returnDate && typeof returnDate !== "string"
      ? dayjs(returnDate).format("YYYY-MM-DD")
      : returnDate;

  let string = "";
  if (adultsCounts) {
    string += `?adultsCount=${adultsCounts}`;
  }
  if (childrenCount) {
    string += `&childrenCount=${childrenCount}`;
  }
  if (infantsInSeatsCount) {
    string += `&infantsInSeatCount=${infantsInSeatsCount}`;
  }
  if (infantsOnLapCount) {
    string += `&infantsOnLapCount=${infantsOnLapCount}`;
  }
  fareClass?.forEach((fc) => {
    string += `&fareClass=${fc}`;
  });
  if (formatDepartureDate && origin && destination) {
    string += `&departureDate=${formatDepartureDate}&destination=${destination}&flightShopProgress=${flightShopProgress}&noLCC=${noLCC}&origin=${origin}`;
  }
  if (formatReturnDate) {
    string += `&returnDate=${formatReturnDate}&stopsOption=${stopsOptions}&tripCategory=${tripCategory}&entryPoint=${entryPoint}`;
  } else {
    string += `&stopsOption=${stopsOptions}&tripCategory=${tripCategory}&entryPoint=${entryPoint}`;
  }
  return string;
};
