import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router";
import {LoadingIndicator, B2BSpinner, PricingBreakdown } from "halifax";
import { CallState } from "redmond";
import clsx from "clsx";
import { PriceBreakdownConnectorProps } from "./container";
import "./styles.scss";

export interface IPriceBreakdownProps
  extends PriceBreakdownConnectorProps,
    RouteComponentProps {
  isMobile?: boolean;
  className?: string;
}

export const PriceBreakdown = ({
  tripPricingCallState,
  currency,
  lineItems,
  summaryLineItems,
  pricingParams,
  fetchTripPricing,
  className,
  cfarOfferText,
  isVITripSelected,
  ancillaries,
}: IPriceBreakdownProps) => {
  useEffect(() => {
    fetchTripPricing();
  }, [
    pricingParams.ancillaryIds,
    pricingParams.tripId,
    pricingParams.fareId,
    pricingParams.priceFreezeId,
    pricingParams.lapInfants.length,
    pricingParams.seatedPassengers.length,
    fetchTripPricing,
  ]);

  return (
    <>
      {tripPricingCallState === CallState.InProcess ? (
        <LoadingIndicator
          indicatorSize="small"
          indicator={B2BSpinner}
          message="Fetching latest price"
         />
      ) : (
        <PricingBreakdown
          className={clsx("price-breakdown", className)}
          pricingItems={lineItems}
          summaryItems={summaryLineItems}
          currencySymbol={currency.currencySymbol}
          currencyCode={currency.currencyCode}
          isVITripSelected={isVITripSelected}
          ancillaries={{
            ...ancillaries,
            cfarText: cfarOfferText,
          }}
        />
      )}
    </>
  );
};
