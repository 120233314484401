import React, { useState, useEffect, useMemo } from "react";

import {
  HotelEntryTypeEnum,
  IIdLodgings,
  Lodging,
  ProductFeedTileTappedProperties,
  ProductFeedViewedProperties,
  PRODUCT_FEED_TILE_TAPPED,
  PRODUCT_FEED_VIEWED_EVENT,
  LodgingCollectionEnum,
} from "redmond";
import { RouteComponentProps } from "react-router";
import { HotelCrossSell } from "halifax";
import { localCache } from "@capone/common";
import { v4 as uuidv4 } from "uuid";
import { FlightToHotelCrossSellConnectorProps } from "./container";
import {
  useExperiments,
  getExperimentVariantCustomVariants,
  AVAILABLE,
  getExperimentVariant,
  PREMIER_COLLECTION_EXPERIMENT,
  LODGING_PROMOTIONS,
  LODGING_PROMOTIONS_AVAILABLE,
  LODGING_PROMOTIONS_VARIANTS,
  CACHE_HOTEL_TOKEN,
} from "../../context/experiments";
import { HOTEL_PATH_AVAILABILITY, HOTEL_PATH_SHOP } from "../../utils/urlPaths";
import {
  transformToStringifiedAvailabilityQuery,
  transformToStringifiedQuery,
} from "./utils/queryStringHelpers";

import { trackEvent } from "../../api/v0/analytics/trackEvent";

export interface IFlightToHotelCrossSellProps
  extends FlightToHotelCrossSellConnectorProps,
    RouteComponentProps {
  isMobile?: boolean;
  entryPoint: HotelEntryTypeEnum;
  className?: string;
  offerBannerText?: string;
}
export const FlightToHotelCrossSell = (props: IFlightToHotelCrossSellProps) => {
  const {
    lodgings,
    searchLocationResult,
    fromDate,
    untilDate,
    largestValueAccount,
    isMobile,
    hotelShopParams,
    history,
    setSelectedLodgingIndex,
    adultsCount,
    children,
    viewedHotelListProperties,
    className,
    latency,
    offerBannerText,
  } = props;

  const [lodgingsToDisplay, setLodgingsToDisplay] = useState<Lodging[]>([]);

  if (!lodgings?.length || !fromDate || !untilDate || !largestValueAccount)
    return null;

  const expState = useExperiments();

  const lodgingPromotions = getExperimentVariantCustomVariants(
    expState.experiments,
    LODGING_PROMOTIONS,
    LODGING_PROMOTIONS_VARIANTS
  );
  const isLodgingPromotionsExperiment = useMemo(
    () => lodgingPromotions === LODGING_PROMOTIONS_AVAILABLE,
    [lodgingPromotions]
  );

  const showEarnEnhancement =
    !!largestValueAccount && !!largestValueAccount.earn.hotelsMultiplier;

  const premierCollectionExperiment = getExperimentVariant(
    expState.experiments,
    PREMIER_COLLECTION_EXPERIMENT
  );
  const isPremierCollectionEnabled = useMemo(
    () => premierCollectionExperiment === AVAILABLE,
    [premierCollectionExperiment]
  );

  const cacheHotelTokenExperiment = getExperimentVariant(
    expState.experiments,
    CACHE_HOTEL_TOKEN
  );
  const isCacheHotelTokenEnabled = useMemo(
    () => cacheHotelTokenExperiment === AVAILABLE,
    [cacheHotelTokenExperiment]
  );

  useEffect(() => {
    const filteredLodgings = lodgings.filter(
      (l) => l.lodgingCollection !== LodgingCollectionEnum.Premier
    );
    if (isMobile && filteredLodgings.length > 5) {
      setLodgingsToDisplay(filteredLodgings.slice(0, 5));
    } else if (!isMobile && filteredLodgings.length > 8) {
      setLodgingsToDisplay(filteredLodgings.slice(0, 8));
    } else {
      setLodgingsToDisplay(filteredLodgings);
    }
  }, [lodgings, isMobile]);

  useEffect(() => {
    if (lodgingsToDisplay.length) {
      const xSellProps: ProductFeedViewedProperties = {
        feed_placement: "flight_confirmation",
        feed_type: "cross_sell_hotels",
        latency: latency!,
        tile_type: "cross_sell_hotel",
      };
      trackEvent({
        eventName: PRODUCT_FEED_VIEWED_EVENT,
        properties: {
          ...xSellProps,
        },
      });
    }
  }, [lodgingsToDisplay]);

  const onViewAllHotelsCTAClick = () => {
    const xSellProps: ProductFeedTileTappedProperties = {
      feed_type: "cross_sell",
      tile_type: "cross_sell_hotel",
      feed_placement: "flight_confirmation",
    };
    trackEvent({
      eventName: PRODUCT_FEED_TILE_TAPPED,
      properties: {
        ...viewedHotelListProperties.properties,
        ...xSellProps,
      },
      encryptedProperties: [...viewedHotelListProperties.encryptedProperties],
    });
    history.push(
      `${HOTEL_PATH_AVAILABILITY}${transformToStringifiedAvailabilityQuery(
        (searchLocationResult?.id as IIdLodgings).lodgingSelection.searchTerm,
        fromDate,
        untilDate,
        adultsCount,
        children,
        HotelEntryTypeEnum.FLIGHTS_CHECKOUT
      )}`
    );
  };
  const onHotelCardClick = (lodgingData: Lodging, index: number) => {
    const { isPreferred, lodging } = lodgingData;
    const available =
      typeof lodgingData.available === "undefined"
        ? true
        : lodgingData.available;
    const xSellProps: ProductFeedTileTappedProperties = {
      feed_type: "cross_sell",
      tile_type: "cross_sell_hotel",
      feed_placement: "flight_confirmation",
    };
    if (available) {
      trackEvent({
        eventName: PRODUCT_FEED_TILE_TAPPED,
        properties: {
          ...viewedHotelListProperties.properties,
          ...lodgingData?.trackingPropertiesV2?.properties,
          is_preferred_cot: isPreferred,
          lodging_row_index: index,
          is_pc:
            lodgingData.lodgingCollection === LodgingCollectionEnum.Premier,
          is_lc:
            lodgingData.lodgingCollection === LodgingCollectionEnum.Lifestyle,
          ...xSellProps,
        },
        encryptedProperties: [
          ...viewedHotelListProperties.encryptedProperties,
          lodgingData?.trackingPropertiesV2?.encryptedProperties ?? "",
        ],
      });
      setSelectedLodgingIndex(index);
      const params = transformToStringifiedQuery({
        lodgingId: lodging.id,
        lodgingSelection: (searchLocationResult?.id as IIdLodgings)
          .lodgingSelection,
        ...hotelShopParams,
        selectedLodgingIndex: index,
        entryPoint: HotelEntryTypeEnum.FLIGHTS_CHECKOUT,
        shopToken: isCacheHotelTokenEnabled
          ? lodgingData.price?.opaqueShopRequest
          : localCache.set(uuidv4(), lodgingData.price?.opaqueShopRequest || "")
              .key,
      });

      if (isMobile)
        history.push(`${HOTEL_PATH_SHOP}${params}`, {
          fromPage: location.pathname,
        });
      else window.open(`${HOTEL_PATH_SHOP}${params}`, "_blank");
    }
  };
  {
    return lodgingsToDisplay?.length ? (
      <HotelCrossSell
        {...props}
        lodgingsToDisplay={lodgingsToDisplay}
        onViewAllHotelsCTAClick={onViewAllHotelsCTAClick}
        onHotelCardClick={onHotelCardClick}
        showEarnEnhancement={showEarnEnhancement}
        isLodgingPromotionsExperiment={isLodgingPromotionsExperiment}
        className={className}
        isPremierCollectionEnabled={isPremierCollectionEnabled}
        bannerText={offerBannerText}
      />
    ) : null;
  }
};
