import { Typography, Box, Divider} from "@material-ui/core";
import clsx from "clsx";
import "./styles.scss";
import React from "react";

interface Traveler {
  firstName: string;
  lastName: string;
}
export interface IFlightTravelerInformationReadOnlyProps {
  className?: string;
  travelers: Traveler[];
  footerText: React.ReactNode;
}

export const FlightTravelerInformationReadOnly = (
  props: IFlightTravelerInformationReadOnlyProps
): JSX.Element => {
  const { className, travelers, footerText } = props;

  const travelerHeader = (() => {
    if (travelers.length === 0) return "No Adult";
    if (travelers.length === 1) return "1 Adult";
    return `${travelers.length} Adults`;
  })();
  const travelerList = travelers.map((traveler) => (
    <li key={`${traveler.firstName} ${traveler.lastName}`}>
      {traveler.firstName} {traveler.lastName}
    </li>
  ));
  return (
    <Box className={clsx(className, "flight-traveler-information-root")}>
      <Box>
        <Box className={clsx("traveler-list")}>
          <Typography variant="body1" className="title">
            {travelerHeader}
          </Typography>
          <ul>{travelerList}</ul>
        </Box>
      </Box>
      <Divider className={clsx("info-container-divider")} />
      <Box className={clsx("footer")}>{footerText}</Box>
    </Box>
  );
};
