import { createSelector } from "@reduxjs/toolkit";
import { CHFAR } from "../../../../context/experiments";
import { ChFARExperimentSliceState } from "./slice";

export type ChFARExperimentState = {
  changeForAnyReason: {
    experiments: ChFARExperimentSliceState;
  };
};

export const selectExperiments = (state: ChFARExperimentState) =>
  state.changeForAnyReason.experiments.values;

export const selectChfarVariation = createSelector(
  [selectExperiments],
  (experiments) => experiments[CHFAR]
);

export const selectIsChFAREnabled = createSelector(
  [selectChfarVariation],
  (variation) => variation === "available" || variation === "declineAll"
);
