import {
  TripCategory,
  PRICE_FREEZE_ID_QUERY_PARAM,
  FlightShopType,
} from "redmond";
import { select } from "redux-saga/effects";

import { IStoreState } from "../../../reducers/types";
import {
  getTripCategory,
  getMulticityRoutes,
} from "../../search/reducer/selectors";
import {
  IPopulateFlightBookQueryParams,
  IFlightBookOverwriteQueryParams,
} from "../actions/actions";
import * as H from "history";
import queryStringParser from "query-string";
import { PATH_BOOK } from "../../../utils/urlPaths";
import {
  selectedTripSelector,
  selectedMulticityTripsSelector,
  tripDetailsSelector,
  priceDropProtectionCandidateIdSelector,
  getSelectedFlightIndex,
  selectedCfarIdSelector,
  selectedChfarIdSelector,
  hasSelectedRefundableFareSelector,
  flightShopProgressSelector,
  flightShopMulticityProgressSelector,
  selectedDisruptionProtectionOfferSelector,
  refundableFaresPropertiesSelector,
} from "../../shop/reducer/selectors";
import { DO_NOT_APPLY_OPTION_KEY, ISelectedTrip } from "../../shop/reducer";

export interface IFlightBookParsedQuery {
  tripId: string;
  outgoingFareId: string;
  returnFareId: string;
  tripCategory: TripCategory;
  priceDropCandidateId: string;
  flightIndex: string;
  cfarPolicyId?: string;
  cfarQuoteId?: string;
  cfarProductId?: string;
  cfarChoice?: 2 | 1 | 0;
  cfarFareChoice?: 1 | 0;
  chfarQuoteId?: string;
  isRefundableFare?: boolean;
  disruptionProtectionPolicyId?: string;
  disruptionProtectionProductId?: string;
  disruptionProtectionQuoteId?: string;
  [PRICE_FREEZE_ID_QUERY_PARAM]: string;
  flightShopType?: FlightShopType;
  itineraryId?: string;
}

export interface IMulticityFlightBookParsedQuery {
  tripId: string;
  departureFareId0: string;
  departureFareId1: string;
  departureFareId2?: string;
  departureFareId3?: string;
  departureFareId4?: string;
  tripCategory: TripCategory;
  flightIndex: string;
  disruptionProtectionPolicyId?: string;
  disruptionProtectionProductId?: string;
}

const isMulticity = (tripCategory: TripCategory): boolean =>
  tripCategory === TripCategory.MULTI_CITY;

export function* populateFlightBookQueryParamsSaga(
  setUpQueryParamsAction: IPopulateFlightBookQueryParams
) {
  const state: IStoreState = yield select();
  if (isMulticity(state.flightSearch.tripCategory)) {
    populateFlightBookMulticityQueryParametersFromState({
      state,
      ...setUpQueryParamsAction,
    });
  } else {
    populateFlightBookQueryParametersFromState({
      state,
      ...setUpQueryParamsAction,
    });
  }
}

export const populateFlightBookQueryParametersFromState = ({
  state,
  history,
  pathname,
  preserveQuery,
  newQueryParams,
}: {
  state: IStoreState;
  history: H.History;
  pathname?: string;
  preserveQuery?: boolean;
  newQueryParams?: IFlightBookOverwriteQueryParams;
}): IFlightBookParsedQuery => {
  const {
    outgoingFareId,
    returnFareId,
    tripId,
    tripCategory,
    priceDropCandidateId,
    flightIndex,
    cfarPolicyId,
    cfarQuoteId,
    cfarProductId,
    cfarChoice,
    cfarFareChoice,
    chfarQuoteId,
    isRefundableFare,
    disruptionProtectionPolicyId,
    disruptionProtectionProductId,
    disruptionProtectionQuoteId,
  } = getExistingStateVariables(state);
  const flightShopProgress = flightShopProgressSelector(state);
  const queryString = parseQueryString(history) as IFlightBookParsedQuery;
  const defaultBookQuery = {
    tripCategory: queryString.tripCategory || tripCategory,
    outgoingFareId: queryString.outgoingFareId || outgoingFareId || "",
    returnFareId: queryString.returnFareId || returnFareId || "",
    tripId: queryString.tripId || tripId || "",
    flightIndex: queryString.flightIndex || flightIndex.toString() || "",
    priceDropCandidateId:
      queryString.priceDropCandidateId || priceDropCandidateId || "",
  };
  const exerciseBookQuery = {
    [PRICE_FREEZE_ID_QUERY_PARAM]:
      newQueryParams?.priceFreezeId ?? queryString[PRICE_FREEZE_ID_QUERY_PARAM],
  };
  const cfarBookQuery = {
    cfarPolicyId: queryString.cfarPolicyId || cfarPolicyId,
    cfarQuoteId: queryString.cfarQuoteId || cfarQuoteId,
    cfarProductId: queryString.cfarProductId || cfarProductId,
    cfarChoice: queryString.cfarChoice ?? cfarChoice,
    cfarFareChoice: queryString.cfarFareChoice ?? cfarFareChoice,
    isRefundableFare: queryString.isRefundableFare || isRefundableFare,
  };

  const chfarBookQuery = {
    chfarQuoteId: queryString.chfarQuoteId || chfarQuoteId,
  };

  const disruptionProtectionBookQuery = {
    disruptionProtectionPolicyId:
      queryString.disruptionProtectionPolicyId || disruptionProtectionPolicyId,
    disruptionProtectionProductId:
      queryString.disruptionProtectionProductId ||
      disruptionProtectionProductId,
    disruptionProtectionQuoteId:
      queryString.disruptionProtectionQuoteId || disruptionProtectionQuoteId,
  };

  const parsedQueryStringPrimitive = queryStringParser.parse(
    history.location.search
  );

  const flightShopType = parsedQueryStringPrimitive?.flightShopType;
  const itineraryId = parsedQueryStringPrimitive?.itineraryId;

  const newQuery = {
    ...cfarBookQuery,
    ...chfarBookQuery,
    ...disruptionProtectionBookQuery,
    // note: when priceFreezeId is given in the query param, treat the current session as a PF exercise flow
    ...(exerciseBookQuery[PRICE_FREEZE_ID_QUERY_PARAM]
      ? exerciseBookQuery
      : defaultBookQuery),
    flightShopType,
    itineraryId,
  };

  history.push({
    pathname: pathname ?? PATH_BOOK,
    search: queryStringParser.stringify(
      preserveQuery ? { ...parsedQueryStringPrimitive, ...newQuery } : newQuery
    ),
    state: {
      flightShopProgress,
    },
  });

  return { ...defaultBookQuery, ...cfarBookQuery, ...exerciseBookQuery };
};

export const populateFlightBookMulticityQueryParametersFromState = ({
  state,
  history,
  pathname,
  preserveQuery,
}: {
  state: IStoreState;
  history: H.History;
  pathname?: string;
  preserveQuery?: boolean;
}): IMulticityFlightBookParsedQuery => {
  const { selectedMulticityTrips, tripId, tripCategory, flightIndex } =
    getExistingStateVariables(state);
  const multicityFlightShopProgress =
    flightShopMulticityProgressSelector(state);

  const queryString = parseQueryString(
    history
  ) as IMulticityFlightBookParsedQuery;

  const [
    departureFareId0,
    departureFareId1,
    departureFareId2,
    departureFareId3,
    departureFareId4,
  ] = selectedMulticityTrips.map(
    (departureTrip) => departureTrip.departureFareId
  );

  const newQuery = {
    tripCategory: queryString.tripCategory || tripCategory,
    departureFareId0: queryString.departureFareId0 || departureFareId0 || "",
    departureFareId1: queryString.departureFareId1 || departureFareId1 || "",
    departureFareId2:
      queryString.departureFareId2 || departureFareId2 || undefined,
    departureFareId3:
      queryString.departureFareId3 || departureFareId3 || undefined,
    departureFareId4:
      queryString.departureFareId4 || departureFareId4 || undefined,
    tripId: queryString.tripId || tripId || "",
    flightIndex: queryString.flightIndex || flightIndex.toString() || "",
  };

  const parsedQueryStringPrimitive = queryStringParser.parse(
    history.location.search
  );

  history.push({
    pathname: pathname ?? PATH_BOOK,
    search: queryStringParser.stringify(
      preserveQuery ? { ...parsedQueryStringPrimitive, ...newQuery } : newQuery
    ),
    state: {
      multicityFlightShopProgress,
    },
  });

  return { ...newQuery };
};

export function getExistingStateVariables(state: IStoreState) {
  const cfarId = selectedCfarIdSelector(state);
  const refundableFaresProperties = refundableFaresPropertiesSelector(state);
  const chfarId = selectedChfarIdSelector(state);
  const disruptionProtection = selectedDisruptionProtectionOfferSelector(state);
  const cfarPolicyId =
    !cfarId?.policyId || cfarId.policyId === DO_NOT_APPLY_OPTION_KEY
      ? undefined
      : cfarId.policyId;
  const cfarQuoteId =
    !cfarId?.quoteId ||
    !cfarId.quoteId ||
    cfarId.quoteId === DO_NOT_APPLY_OPTION_KEY
      ? undefined
      : cfarId.quoteId;
  const cfarProductId =
    !cfarId?.productId || cfarId.productId === DO_NOT_APPLY_OPTION_KEY
      ? undefined
      : cfarId.productId;
  const cfarChoice = refundableFaresProperties.cfar_choice ?? undefined;
  const cfarFareChoice =
    refundableFaresProperties.cfar_fare_choice ?? undefined;
  const chfarQuoteId =
    !chfarId?.quoteId || chfarId.quoteId === DO_NOT_APPLY_OPTION_KEY
      ? undefined
      : chfarId.quoteId;
  const isRefundableFare =
    hasSelectedRefundableFareSelector(state) || undefined;
  const disruptionProtectionPolicyId =
    !disruptionProtection?.id?.policyId ||
    disruptionProtection.id.policyId === DO_NOT_APPLY_OPTION_KEY
      ? undefined
      : disruptionProtection.id.policyId;
  const disruptionProtectionProductId =
    !disruptionProtection?.id?.productId ||
    disruptionProtection.id.productId === DO_NOT_APPLY_OPTION_KEY
      ? undefined
      : disruptionProtection.id.productId;
  const selectedTrip = selectedTripSelector(state) as ISelectedTrip;
  const disruptionProtectionQuoteId =
    !disruptionProtection?.id?.quoteId ||
    disruptionProtection.id.quoteId === DO_NOT_APPLY_OPTION_KEY
      ? undefined
      : disruptionProtection.id.quoteId;
  return {
    tripId: selectedTrip.tripId,
    tripDetails: tripDetailsSelector(state, selectedTrip.tripId || ""),
    flightIndex: getSelectedFlightIndex(state) || "",
    outgoingFareId: selectedTrip.outgoingFareId,
    outgoingSliceId: selectedTrip.outgoingSliceId,
    returnFareId: selectedTrip.returnFareId,
    tripCategory: getTripCategory(state),
    selectedMulticityTrips: selectedMulticityTripsSelector(state),
    multicityRoutes: getMulticityRoutes(state),
    priceDropCandidateId: priceDropProtectionCandidateIdSelector(state),
    cfarPolicyId,
    cfarQuoteId,
    cfarProductId,
    cfarChoice,
    cfarFareChoice,
    chfarQuoteId,
    isRefundableFare,
    disruptionProtectionPolicyId,
    disruptionProtectionProductId,
    disruptionProtectionQuoteId,
  };
}

export const parseQueryString = (
  history: H.History
): IFlightBookParsedQuery | IMulticityFlightBookParsedQuery => {
  const queryString = history?.location?.search || "";
  const parsedQueryStringPrimitive = queryStringParser.parse(queryString);
  const tripCategory = parsedQueryStringPrimitive.tripCategory as TripCategory;

  if (tripCategory === TripCategory.MULTI_CITY) {
    return {
      tripCategory,
      departureFareId0: parsedQueryStringPrimitive.departureFareId0 as string,
      departureFareId1: parsedQueryStringPrimitive.departureFareId1 as string,
      departureFareId2: parsedQueryStringPrimitive.departureFareId2 as string,
      departureFareId3: parsedQueryStringPrimitive.departureFareId3 as string,
      departureFareId4: parsedQueryStringPrimitive.departureFareId4 as string,
      tripId: parsedQueryStringPrimitive.tripId as string,
      flightIndex: parsedQueryStringPrimitive.flightIndex as string,
      disruptionProtectionPolicyId:
        parsedQueryStringPrimitive.disruptionProtectionPolicyId as string,
      disruptionProtectionProductId:
        parsedQueryStringPrimitive.disruptionProtectionProductId as string,
    };
  } else {
    return {
      tripCategory,
      outgoingFareId: parsedQueryStringPrimitive.outgoingFareId as string,
      returnFareId: parsedQueryStringPrimitive.returnFareId as string,
      tripId: parsedQueryStringPrimitive.tripId as string,
      flightIndex: parsedQueryStringPrimitive.flightIndex as string,
      priceDropCandidateId:
        parsedQueryStringPrimitive.priceDropCandidateId as string,
      cfarPolicyId: parsedQueryStringPrimitive.cfarPolicyId as string,
      cfarQuoteId: parsedQueryStringPrimitive.cfarQuoteId as string,
      cfarProductId: parsedQueryStringPrimitive.cfarProductId as string,
      cfarChoice: parsedQueryStringPrimitive.cfarChoice as any,
      cfarFareChoice: parsedQueryStringPrimitive.cfarFareChoice as any,
      chfarQuoteId: parsedQueryStringPrimitive.chfarQuoteId as string,
      isRefundableFare:
        !!JSON.parse(
          (parsedQueryStringPrimitive.isRefundableFare as string) ?? false
        ) || undefined,
      disruptionProtectionPolicyId:
        parsedQueryStringPrimitive.disruptionProtectionPolicyId as string,
      disruptionProtectionProductId:
        parsedQueryStringPrimitive.disruptionProtectionProductId as string,
      disruptionProtectionQuoteId:
        parsedQueryStringPrimitive.disruptionProtectionQuoteId as string,
      [PRICE_FREEZE_ID_QUERY_PARAM]: parsedQueryStringPrimitive[
        PRICE_FREEZE_ID_QUERY_PARAM
      ] as string,
    };
  }
};
