import React, { type PropsWithChildren } from "react";
import { useSelector } from "react-redux";
import type { FlightShopType } from "redmond"; // Assuming ShopStep is an imported type
import {
  flightShopTypeSelector,
  flightShopProgressSelector,
  type FlightShopStep,
} from "../../../reducer";

interface FlightShopVisibilityProps {
  hideOnShopType?: FlightShopType[];
  showOnShopType?: FlightShopType[];
  hideOnShopStep?: FlightShopStep[];
  showOnShopStep?: FlightShopStep[];
}

/**
 * This component will hide or show content based on the current FlightShopType and ShopStep.
 * @example
 * <FlightShopVisibility hideOnShopType={[FlightShopType.CHECKOUT]} showOnShopStep={[ShopStep.SELECTION]}>
 *   <ContentForCheckoutOnly />
 * </FlightShopVisibility>
 */
export const FlightShopVisibility: React.FC<
  PropsWithChildren<FlightShopVisibilityProps>
> = ({
  hideOnShopType = [],
  showOnShopType = [],
  hideOnShopStep = [],
  showOnShopStep = [],
  children,
}) => {
  const flightShopType = useSelector(flightShopTypeSelector);
  const flightShopStep = useSelector(flightShopProgressSelector);

  // Show children if both conditions are satisfied for FlightShopType and FlightShopStep
  const shouldShow =
    // Hide conditions
    !(hideOnShopType.length > 0 && hideOnShopType.includes(flightShopType)) &&
    !(hideOnShopStep.length > 0 && hideOnShopStep.includes(flightShopStep)) &&
    // Show conditions
    (showOnShopType.length === 0 || showOnShopType.includes(flightShopType)) &&
    (showOnShopStep.length === 0 || showOnShopStep.includes(flightShopStep));

  return shouldShow ? <>{children}</> : null;
};
