import { put, select } from "redux-saga/effects";
import { actions } from "../../actions";
import { validatePassengers } from "../../../../api/v0/book/book-flow/validatePassengers";
import {
  InvalidPassengers,
  PassengersResponse,
  PassengersResponseEnum,
} from "redmond";
import {
  getViewedTripSummaryProperties,
  selectedChfarIdSelector,
  selectedTripSelector,
} from "../../../shop/reducer/selectors";
import { IStoreState } from "../../../../reducers/types";
import { getUserPassengers, selectedAncillaryIdsSelector } from "../../reducer";
import {
  DO_NOT_APPLY_OPTION_KEY,
  ISelectedMulticityTrip,
  ISelectedTrip,
} from "../../../shop/reducer";

export function* validatePassengersSaga({
  seatedPassengerIds,
  lapInfantsIds,
}: actions.IValidatePassengers) {
  try {
    const state: IStoreState = yield select();
    const selectedTrip = selectedTripSelector(state);
    const selectedOWRTrip = selectedTrip as ISelectedTrip;
    const selectedMcTrip = selectedTrip as ISelectedMulticityTrip;
    const passengers = getUserPassengers(state);
    const ancillaryIds = selectedAncillaryIdsSelector(state);
    const selectedChfarId = selectedChfarIdSelector(state);
    const viewedTripSummaryProperties = getViewedTripSummaryProperties(state);
    if (!selectedTrip || !passengers) {
      yield put(actions.setPassengerValidationCallStateFailure());
    }
    const response: PassengersResponse = yield validatePassengers({
      tripId: selectedTrip.tripId!,
      fareId: (selectedOWRTrip.returnFareId ||
        selectedOWRTrip.outgoingFareId ||
        selectedMcTrip.departureFareId) as string,
      seatedPassengers: passengers
        .filter((p) => seatedPassengerIds.includes(p.id))
        .map((u) => u.id),
      lapInfants: passengers
        .filter((p) => lapInfantsIds.includes(p.id))
        .map((u) => u.id),
      ancillaryIds,
      chfarQuoteId:
        selectedChfarId == null ||
        selectedChfarId.quoteId == DO_NOT_APPLY_OPTION_KEY
          ? undefined
          : selectedChfarId.quoteId,
    });

    switch (response.PassengersResponse) {
      case PassengersResponseEnum.InvalidPassengers:
        const errors = (response as InvalidPassengers).errors;
        yield put(actions.setPassengersInvalid(errors));
        yield put(
          actions.setUserSelectedPassengerIds({
            userSelectedPassengerIds: [],
            multiTicketType: viewedTripSummaryProperties?.multi_ticket_type,
          })
        );
        yield put(
          actions.setUserSelectedLapInfantIds({ userSelectedLapInfantIds: [] })
        );
        yield put(actions.setPassengerValidationCallStateFailure());
        break;
      case PassengersResponseEnum.PassengersOk:
        yield put(actions.setPassengersValid());
        yield put(actions.setPassengerValidationCallStateSuccess());
        break;
    }
  } catch (e) {
    yield put(actions.setPassengerValidationCallStateFailure());
  }
}
