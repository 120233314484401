import { IFlightSearchState } from "../modules/search/reducer";
import { IFlightShopState } from "../modules/shop/reducer";
import { IFlightBookState } from "../modules/book/reducer";
import { IFlightRewardsState } from "../modules/rewards/reducer";
import { IFlightFreezeState } from "../modules/freeze/reducer";
import { IFlightTravelWalletState } from "../modules/travel-wallet/reducer";
import { IFlightAncillaryState } from "../modules/ancillary/reducer";
import { IFlightToHotelCrossSellState } from "../modules/cross-sell/reducer";
import {
  chfarFlightApiReducerPath,
  type IChFARFlightsApiState,
} from "../modules/change-for-any-reason/reducers/flights/api";
import type { ChangeForAnyReasonState } from "../modules/change-for-any-reason/reducers";
import {
  chfarLocationApiReducerPath,
  type IChFARLocationApiState,
} from "../modules/change-for-any-reason/reducers/location/api";

export const RESET_STATE = "RESET_STATE";
export type RESET_STATE = typeof RESET_STATE;

export interface IStoreState {
  flightSearch: IFlightSearchState;
  flightShop: IFlightShopState;
  flightBook: IFlightBookState;
  flightRewards: IFlightRewardsState;
  flightFreeze: IFlightFreezeState;
  flightTravelWallet: IFlightTravelWalletState;
  flightAncillary: IFlightAncillaryState;
  flightToHotelCrossSell: IFlightToHotelCrossSellState;
  changeForAnyReason: ChangeForAnyReasonState;
  [chfarFlightApiReducerPath]: IChFARFlightsApiState;
  [chfarLocationApiReducerPath]: IChFARLocationApiState;
}
