import axios from "axios";
import { type ShopSummaryResponseV2 } from "@b2bportal/air-shopping-api";
import {
  airChfarApi,
  type AirChfarExerciseShopRequest,
} from "@b2bportal/air-chfar-api";
import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";

export const chfarFlightsApi = createApi({
  reducerPath: "chfarFlightsApi",
  baseQuery: fakeBaseQuery(),
  tagTypes: ["ChfarFlights"],
  endpoints: (builder) => ({
    getFlights: builder.query<
      ShopSummaryResponseV2,
      AirChfarExerciseShopRequest
    >({
      queryFn: async (request) => {
        try {
          const res = await airChfarApi(axios).apiV1ChfarAirExerciseShopPost(
            request
          );

          if (res.data.AirChfarExerciseShopResponse === "Failure") {
            throw new Error(res.data.Failure);
          }

          const shopSummaryResponse = res.data.value
            .value as ShopSummaryResponseV2; // opaque so we need to cast
          const { fareIdToPriceDifference } = res.data;

          const coverageAdjustedFares: Record<
            string,
            ShopSummaryResponseV2["flights"]["fares"][number]
          > = Object.keys(shopSummaryResponse.flights.fares).reduce(
            (acc, fareId) => {
              if (fareIdToPriceDifference[fareId] == null) {
                return acc;
              }

              acc[fareId] = {
                ...shopSummaryResponse.flights.fares[fareId],
                amount: fareIdToPriceDifference[fareId],
                het: "CHFAR",
              };
              return acc;
            },
            {}
          );

          const priceAdjustedShopSummary: ShopSummaryResponseV2 = {
            ...shopSummaryResponse,
            flights: {
              ...shopSummaryResponse.flights,
              fares: coverageAdjustedFares,
            },
          };

          return { data: priceAdjustedShopSummary };
        } catch (error: unknown) {
          if (axios.isAxiosError(error)) {
            return {
              error: {
                status: error.response?.status,
                message: error.message,
              },
            };
          }

          return {
            error: { error: (error as Error).message ?? "Unknown error" },
          };
        }
      },
      providesTags: ["ChfarFlights"],
    }),
  }),
});

export const { useGetFlightsQuery } = chfarFlightsApi;
export const chfarFlightApiReducerPath = chfarFlightsApi.reducerPath;
export const chfarFlightsApiReducer = chfarFlightsApi.reducer;
export const chfarFlightsApiMiddleware = chfarFlightsApi.middleware;

export type IChFARFlightsApiState = ReturnType<typeof chfarFlightsApi.reducer>;
