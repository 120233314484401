import type { SingleFlightItineraryResponse } from "@b2bportal/air-booking-api";

const getChfarSlicesFromItinerary = ({
  itinerary,
  context,
}: SingleFlightItineraryResponse) =>
  itinerary.bookedItinerary.travelItinerary.slices.map((slice) => {
    const firstSegment = slice.segments[0];
    const lastSegment = slice.segments[slice.segments.length - 1];
    return {
      originName:
        context.airports[firstSegment.origin.locationCode].servedCityName,
      originCode: firstSegment.origin.locationCode,
      departureDate: firstSegment.scheduledDeparture,
      destinationName:
        context.airports[lastSegment.destination.locationCode].servedCityName,
      destinationCode: lastSegment.destination.locationCode,
      arrivalDate: lastSegment.scheduledArrival,
      stops: slice.segments.length - 1,
      airlines: slice.segments.map((segment) => ({
        name: context.airlines[segment.marketingAirline.code].displayName,
        code: segment.marketingAirline.code,
      })),
    };
  });

const getChfarFlightRouteFromItinerary = ({
  itinerary,
  context,
}: SingleFlightItineraryResponse) => {
  const { slices } = itinerary.bookedItinerary.travelItinerary;
  const firstSlice = slices[0];
  const lastSlice = slices[slices.length - 1];
  const firstSegment = firstSlice.segments[0];
  const lastSegment = lastSlice.segments[lastSlice.segments.length - 1];

  return {
    origin: {
      name: context.airports[firstSegment.origin.locationCode].servedCityName,
      code: firstSegment.origin.locationCode,
      regionType: "airport",
    },
    destination: {
      name: context.airports[lastSegment.destination.locationCode]
        .servedCityName,
      code: lastSegment.destination.locationCode,
      regionType: "airport",
    },
  };
};

const getChfarFlightDatesFromItinerary = (
  itinerary: SingleFlightItineraryResponse["itinerary"]
) => {
  const { slices } = itinerary.bookedItinerary.travelItinerary;
  const firstSlice = slices[0];
  const lastSlice = slices[slices.length - 1];

  return {
    departureDate: firstSlice.segments[0].scheduledDeparture,
    ...(slices.length > 1 && {
      returnDate:
        lastSlice.segments[lastSlice.segments.length - 1].scheduledDeparture,
    }),
  };
};

const getChfarPassengersFromItinerary = (
  itinerary: SingleFlightItineraryResponse["itinerary"]
) => {
  const { alone, withLapInfants } = itinerary.bookedItinerary.passengers;
  return {
    adults: Number(
      alone.filter((passenger) => passenger.type === "ADT").length +
        withLapInfants.length
    ),
    children: Number(
      alone.filter((passenger) => passenger.type === "CNN").length
    ),
    infantInSeat: Number(
      alone.filter((passenger) => passenger.type === "INS").length
    ),
    infantInLap: Number(withLapInfants.length),
  };
};

const getChfarDataFromItinerary = ({
  itinerary,
  context,
}: SingleFlightItineraryResponse) => {
  if (!itinerary.chfar) {
    return undefined;
  }
  return {
    contractId: itinerary.chfar.contractId,
    slices: getChfarSlicesFromItinerary({ itinerary, context }),
    coverage: itinerary.chfar?.coverage.fiat,
    flightRoute: getChfarFlightRouteFromItinerary({ itinerary, context }),
    flightDates: getChfarFlightDatesFromItinerary(itinerary),
    passengers: getChfarPassengersFromItinerary(itinerary),
    passengerDetails: itinerary.bookedItinerary.passengers,
  };
};

export default getChfarDataFromItinerary;
