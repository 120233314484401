import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { rootSaga } from "../sagas";
import { rootReducer } from "../reducers";
import { chfarMiddleWare } from "../modules/change-for-any-reason/reducers";

// REDUX SAGA middleware
export const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
      .concat(sagaMiddleware)
      .concat(chfarMiddleWare),
});

sagaMiddleware.run(rootSaga);
