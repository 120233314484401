import { isCorpTenant } from "@capone/common";
import { CustomerAccountRole, RewardsAccount, Tenant } from "redmond";

// TODO: relocate Cap One specific language to McLean; see https://hopper-jira.atlassian.net/wiki/spaces/ENGWEB/pages/1959952414/Discussion+on+practical+ways+to+prevent+adding+Cap+One+specific+language+into+Pawtucket
export const PAYMENT_STEP_TITLE = (step: number, canRedeemRewards: boolean) =>
  `Step ${step}: ${canRedeemRewards ? "Rewards and " : ""}Payment`;
export const PAYMENT_STEP_SUBTITLE = (canRedeemRewards: boolean) =>
  canRedeemRewards
    ? "You can use rewards and the payment method you add to book this trip."
    : "Choose the payment method to book this trip.";
export const PAYMENT_METHOD_TITLE = "Payment method";
export const REWARDS_ACCOUNT_TITLE = (tenant: Tenant) =>
  isCorpTenant(tenant) ? "Apply rewards" : "Select an account";
export const REWARDS_ACCOUNT_SUBTITLE = (tenant: Tenant) =>
  isCorpTenant(tenant)
    ? "Select if you would like to apply your rewards to this booking."
    : "Only the rewards from one of these accounts can be applied per transaction.";
export const UNABLED_TO_ADD_PAYMENT =
  "We are unable to add your payment method";
export const ADD_PAYMENT_AGAIN = "Please add your payment method again";
export const CAP_ONE_INVALID_CREDIT_CARD_TITLE =
  "It looks like you're trying to add an invalid credit card.";
export const CAP_ONE_INVALID_CREDIT_CARD_SUBTITLE =
  "Ensure you are using an eligible Capital One credit card.";
export const EDIT_PAYMENT_METHOD = "Edit Payment Method";
export const TRY_AGAIN = "Try Again";
export const CTA_SINGLE_ACCOUNT_ADD_YOUR_TEXT = "Add your";
export const CTA_SINGLE_ACCOUNT_CREDIT_CARD_TEXT = "credit card";
export const CTA_CORP_NON_FINANCIAL_USER_CREDIT_CARD_TEXT = "Add credit card";
export const ADD_PAYMENT_METHOD_CTA_MULTIPLE_ACCOUNTS =
  "Add an eligible Capital One credit card";
export const ADD_PAYMENT_METHOD_MODAL_TITLE =
  "Add one of your Capital One credit cards";
export const ADD_ADDITIONAL_PAYMENT_METHOD_CTA = "Add another payment method";
export const BACK_TO_CARD_SELECTION_CTA = "Back to card selection";
export const CARD_ENDING_IN_TEXT = "Ending in";
export const ADD_PAYMENT_FORM_HEADER_TEXT = (cardName: string) =>
  `Add your <b>${cardName}</b> credit card`;
export const ADD_PAYMENT_FORM_SUBTITLE_TEXT = `You can choose to add your <b>physical card number</b> or <b>your virtual card number</b> if you’ve been issued one.`;
export const MOBILE_PAYMENT_STEP_TITLE = (canRedeemRewards: boolean) =>
  canRedeemRewards ? "Rewards and Payment" : "Payment";
export const MOBILE_PAYMENT_STEP_SUBTITLE = (canRedeemRewards: boolean) =>
  canRedeemRewards
    ? "Pay for your booking using rewards, on a Capital One credit card, or use a combination of both."
    : "Pay for your booking using a Capital One credit card.";
// generate a string like "Quicksilver, Venture, and Spark Cash"
export const REWARDS_ACCOUNT_NAMES = (rewardsAccounts: RewardsAccount[]) =>
  rewardsAccounts.reduce((accountNamesString, account, index) => {
    if (rewardsAccounts.length > 1) {
      if (index === rewardsAccounts.length - 1) {
        return `${accountNamesString}${account.productDisplayName}`;
      }
      if (index === rewardsAccounts.length - 2) {
        return `${accountNamesString}${account.productDisplayName}${
          rewardsAccounts.length > 2 ? "," : ""
        } and `;
      }
      return `${accountNamesString}${account.productDisplayName}, `;
    }
    return account.productDisplayName;
  }, "");
export const INELIGIBLE_ACCOUNTS_NOTICE = (
  rewardsAccounts: RewardsAccount[]
) => {
  const ineligibleAccounts = rewardsAccounts.filter(
    (account) => !(account.allowRewardsRedemption ?? true)
  );
  return ineligibleAccounts.length === rewardsAccounts.length
    ? "Please use your eligible credit card to pay for this booking."
    : `Your ${REWARDS_ACCOUNT_NAMES(ineligibleAccounts)} account${
        ineligibleAccounts.length > 1 ? "s are" : " is"
      } not eligible to be redeemed from.`;
};
export const ACCOUNT_ROLE_TEXT = (
  ineligibleRewardsAccounts: RewardsAccount[]
) => {
  const roleToUse = ineligibleRewardsAccounts[0]?.customerAccountRole;

  switch (roleToUse) {
    case CustomerAccountRole.AuthorizedUser:
    default:
      return "authorized user";
    case CustomerAccountRole.AccountManager:
      return "account manager";
  }
};
export const INELIGIBLE_ACCOUNTS_TOOLTIP = (
  ineligibleRewardsAccounts: RewardsAccount[]
) =>
  `Your ${REWARDS_ACCOUNT_NAMES(ineligibleRewardsAccounts)} rewards account${
    ineligibleRewardsAccounts.length > 1 ? "s are" : " is"
  } not eligible to be redeemed for travel because you are an ${ACCOUNT_ROLE_TEXT(
    ineligibleRewardsAccounts
  )}. Please use your eligible credit card to pay for this booking.`;

export const PAYMENT_CARD_SUBTITLE_WITH_CREDITS_AND_OFFERS = (
  includeOffers: boolean
) =>
  `Combine your travel credits,${
    includeOffers ? " a travel offer," : ""
  } rewards, and the payment method you add to book this trip.`;
