import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router";
import { Box, Typography } from "@material-ui/core";
import {
  ActionButton,
  GenericItemsList,
  GenericFlightSummary,
  LoadingPopup,
  GenericInfoPopup,
  B2BSpinner,
  Icon,
  IconName,
  getPriceString,
  getCurrencySymbol,
  getPricesWithComma,
  useFocusRef,
} from "halifax";
import {
  CallState,
  GeneralPurchaseErrorEnum,
  MODAL_ALERT,
  CAP1_REBOOKING_REVIEW_DETAILS,
  PartialOriginalItineraryDpExerciseItineraryFacts,
  PartialDisruptedFlightDpExerciseFacts,
  Cap1DpExerciseFlightsListFactsProperties,
  ModalAlertProperties,
  ModalCategoryType,
} from "redmond";
import clsx from "clsx";
import { FlightShopStep } from "../../../shop/reducer";
import {
  IFlightShopParsedQuery,
  parseQueryString,
} from "../../../shop/utils/parseQueryString";
import {
  PATH_DISRUPTION_PROTECTION_REBOOK_CONFIRMATION,
  PATH_DISRUPTION_PROTECTION_VIRTUAL_INTERLINE_REBOOK_CONFIRMATION,
} from "../../../../utils/urlPaths";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import { handleRebookingCompleteBuyEvent } from "../../utils";
import { RebookBanner } from "../index";
import { RebookReviewItineraryConnectorProps } from "./container";
import * as constants from "./constants";
import "./styles.scss";
import { DateToggleFilter } from "../../reducer";

export interface IRebookReviewItineraryProps
  extends RebookReviewItineraryConnectorProps,
    RouteComponentProps {
  isMobile?: boolean;
  isViProductRedeemChoice?: boolean;
}

export const RebookReviewItinerary = (props: IRebookReviewItineraryProps) => {
  const {
    isMobile,
    passengerNamesAndIds,
    exerciseDisruptionProtectionRebookCallState,
    exerciseDisruptionProtectionRebookCallFailureReason,
    flightSummaryProps,
    rebookCoverage,
    finalizedItinerary,
    reviewItineraryEligibilityDpExerciseFactsProperties,
    originalItineraryDpExerciseItineraryFactsProperties,
    rebookedItineraryDpExerciseItineraryFactsProperties,
    rebookedItineraryDpRebookingCompleteBuyFactsProperties,
    isSelectingReturnFlight,
    priceQuoteErrors,
    history,
    resetBookingErrors,
    setExerciseDisruptionProtectionRebookCallStateNotCalled,
    setFlightShopProgress,
    populateFlightShopQueryParams,
    exerciseDisruptionProtectionRebook,
    disruptedFlightDpExerciseFactsProperties,
    dpExercisePassengerProperties,
    dateToggleFilter,
    productRedeemChoice,
    rebookSummary,
    isViProductRedeemChoice,
  } = props;

  const initialFocusRef = useFocusRef([]);
  const isMissedConnectionVi = productRedeemChoice === "missed_connection_vi";
  const importantInfoItems = (() => {
    const items = [
      { message: constants.IMPORTANT_INFORMATION_POINT_ONE },
      { message: constants.IMPORTANT_INFORMATION_POINT_TWO },
    ];

    if (rebookCoverage) {
      const cap = getPricesWithComma(
        getPriceString({
          price: rebookCoverage.amount,
          currencySymbol: getCurrencySymbol(rebookCoverage.currency),
        })
      );
      items.push({
        message: constants.IMPORTANT_INFORMATION_POINT_THREE(cap),
      });
    }

    items.push({ message: constants.IMPORTANT_INFORMATION_POINT_FOUR });

    return items;
  })();

  const reinitializeRebookingStates = () => {
    setExerciseDisruptionProtectionRebookCallStateNotCalled();
    /*
      note: the one-second delay is added to combat the lingering effect of the Dialog component after it's closed;
      when this delay isn't added, the content inside the error modal would change before it's closed.
    */
    setTimeout(() => {
      resetBookingErrors();
    }, 1000);
  };

  const errorModalProps: {
    title: string;
    subtitle: string;
    buttonText: string;
    iconName: IconName;
    onClick: () => void;
  } = (() => {
    const hasNoAvailability = priceQuoteErrors.includes(
      GeneralPurchaseErrorEnum.NoAvailability
    );

    if (hasNoAvailability) {
      return {
        title: constants.NO_AVAILABILITY_MODAL_TITLE(
          passengerNamesAndIds.length
        ),
        subtitle: constants.NO_AVAILABILITY_MODAL_SUBTITLE,
        buttonText: constants.SEARCH_AGAIN,
        iconName: IconName.UnableToProcess,
        onClick: () => {
          reinitializeRebookingStates();
          setFlightShopProgress(FlightShopStep.ChooseDeparture);
        },
      };
    } else {
      return {
        title: constants.ERROR_MODAL_TITLE,
        subtitle: constants.ERROR_MODAL_SUBTITLE,
        buttonText: constants.TRY_AGAIN,
        iconName: IconName.ErrorState,
        onClick: () => exerciseDisruptionProtectionRebook({}),
      };
    }
  })();

  useEffect(() => {
    trackEvent({
      eventName: CAP1_REBOOKING_REVIEW_DETAILS,
      properties: {
        ...originalItineraryDpExerciseItineraryFactsProperties,
        ...dpExercisePassengerProperties,
        ...disruptedFlightDpExerciseFactsProperties,
        rebooking_tool_filter:
          dateToggleFilter === DateToggleFilter.TODAY ? "today" : "tomorrow",
        rebook_origin: flightSummaryProps?.tripSlice.originCode,
        rebook_destination: flightSummaryProps?.tripSlice.destinationCode,
      } as PartialOriginalItineraryDpExerciseItineraryFacts &
        PartialDisruptedFlightDpExerciseFacts &
        Cap1DpExerciseFlightsListFactsProperties,
    });
  }, []);

  useEffect(() => {
    if (exerciseDisruptionProtectionRebookCallState === CallState.Failed) {
      const { productRedeemChoice } = parseQueryString(
        history
      ) as IFlightShopParsedQuery;

      handleRebookingCompleteBuyEvent(
        {
          ...rebookedItineraryDpExerciseItineraryFactsProperties,
          ...rebookedItineraryDpRebookingCompleteBuyFactsProperties,
          ...reviewItineraryEligibilityDpExerciseFactsProperties,
          ...disruptedFlightDpExerciseFactsProperties,
          success: false,
        },
        exerciseDisruptionProtectionRebookCallFailureReason
      );
      const properties: ModalAlertProperties = {
        type: "RebookFailed",
        screen: "disruption_rebooking",
        modal_subtitle: errorModalProps.subtitle,
        modal_title: errorModalProps.title,
        agent_title: errorModalProps.title,
        agent_subtitle: errorModalProps.subtitle,
        funnel: "flights",
        step: "disruption_review_itinerary",
        category: ModalCategoryType.TROUBLE,
        primary_button: errorModalProps.buttonText,
        secondary_button: "",
      };
      trackEvent({
        eventName: MODAL_ALERT,
        properties: {
          ...properties,
          modal_type: "RebookFailed",
          description: "disruption_rebooking_error",
          active_disruption:
            reviewItineraryEligibilityDpExerciseFactsProperties?.active_disruption,
          product_redeem_choice: productRedeemChoice,
        },
      });
    }
  }, [exerciseDisruptionProtectionRebookCallState]);

  useEffect(() => {
    if (finalizedItinerary) {
      if (isViProductRedeemChoice) {
        history.push(
          PATH_DISRUPTION_PROTECTION_VIRTUAL_INTERLINE_REBOOK_CONFIRMATION
        );
      } else {
        history.push(PATH_DISRUPTION_PROTECTION_REBOOK_CONFIRMATION);
      }
    }
  }, [finalizedItinerary]);

  return (
    <>
      <Box
        className={clsx("rebook-review-itinerary-root", { mobile: isMobile })}
      >
        <Box className="rebook-review-itinerary-container">
          <Box className={clsx("review-itinerary-content", "header-section")}>
            <Typography
              tabIndex={0}
              innerRef={initialFocusRef}
              className="header-copy"
              variant="h2"
            >
              {constants.REBOOK_REVIEW_ITINERARY_TITLE}
            </Typography>
            <Typography className="subtitle-copy" variant="subtitle1">
              {constants.REBOOK_REVIEW_ITINERARY_SUBTITLE}
            </Typography>
          </Box>
          <Box className={clsx("review-itinerary-content", "banner-section")}>
            <RebookBanner />
          </Box>
          {flightSummaryProps && (
            <Box
              className={clsx(
                "review-itinerary-content",
                "flight-summary-section"
              )}
            >
              <GenericFlightSummary
                {...flightSummaryProps}
                headerType={isSelectingReturnFlight ? "return" : "outbound"}
                isMobile={isMobile}
                useChevron={isMobile}
                useFullWidth={!isMobile}
                tag={{
                  message: constants.NEW_FLIGHT,
                  theme: "green",
                }}
                buttons={
                  !isMobile
                    ? [
                        {
                          message: constants.VIEW_DETAILS,
                          ariaLabel: constants.VIEW_DETAILS_ARIA_LABEL,
                          onClick: "open-modal",
                        },
                        {
                          message: constants.CHANGE,
                          ariaLabel: constants.CHANGE_ARIA_LABEL,
                          onClick: () =>
                            populateFlightShopQueryParams({
                              history,
                              useHistoryPush: true,
                              forceQueryUpdate: true,
                              newQueryParams: {
                                flightShopProgress:
                                  FlightShopStep.ChooseDeparture,
                              },
                            }),
                        },
                      ]
                    : undefined
                }
                airlineProps={{
                  WN: {
                    hideFlightDetailsSummaryBanner: true,
                  },
                }}
              />
            </Box>
          )}
          {isMissedConnectionVi && (
            <Box className="review-itinerary-content know-before-rebook-section">
              <Box className="title">
                <Typography variant="body1">{constants.KNOW_BEFORE}</Typography>
              </Box>
              <Box className="description">
                <ul>
                  {constants
                    .KNOW_BEFORE_STEPS(rebookSummary?.tripType)
                    .map((step) => (
                      <li key={step}>
                        <Typography variant="body1">{step}</Typography>
                      </li>
                    ))}
                </ul>
              </Box>
            </Box>
          )}
          <Box
            className={clsx("review-itinerary-content", "travelers-section")}
          >
            <GenericItemsList
              className="travelers"
              title={constants.TRAVELERS}
              items={passengerNamesAndIds.map((entry) => ({
                message: entry.name,
              }))}
            />
          </Box>
          <Box className={clsx("review-itinerary-content", "info-section")}>
            <GenericItemsList
              className="important-info"
              title={constants.IMPORTANT_INFORMATION}
              items={
                isMissedConnectionVi
                  ? constants.IMPORTANT_INFORMATION_MCP_VI_STEPS
                  : importantInfoItems
              }
              useNumberedList
            />
          </Box>
          <Box
            className={clsx("review-itinerary-content", "button-section", {
              floating: isMobile,
            })}
          >
            <ActionButton
              className="rebook-button"
              defaultStyle="h4r-primary"
              onClick={() => {
                exerciseDisruptionProtectionRebook({});
              }}
              message={constants.CONFIRM_AND_REBOOK}
              ariaLabelText={constants.CONFIRM_AND_REBOOK_ARIA_LABEL}
            />
          </Box>
        </Box>
      </Box>
      <LoadingPopup
        classes={[clsx("rebook-itinerary-loading-modal", { mobile: isMobile })]}
        open={
          exerciseDisruptionProtectionRebookCallState === CallState.InProcess
        }
        indicator={B2BSpinner}
        message={constants.LOADING_POPOVER_TITLE}
        subtitle={
          <Typography
            variant="inherit"
            className="rebook-itinerary-loading-subtitle"
          >
            {constants.LOADING_POPOVER_SUBTITLE}
          </Typography>
        }
      />
      <GenericInfoPopup
        className="rebook-itinerary-error-modal"
        open={exerciseDisruptionProtectionRebookCallState === CallState.Failed}
        title={errorModalProps.title}
        subtitle={
          <Typography
            variant="inherit"
            className="rebook-itinerary-error-subtitle"
            dangerouslySetInnerHTML={{ __html: errorModalProps.subtitle }}
          />
        }
        image={<Icon className="error-icon" name={errorModalProps.iconName} />}
        buttons={[
          {
            buttonText: errorModalProps.buttonText,
            defaultStyle: "h4r-primary",
            onClick: errorModalProps.onClick,
          },
        ]}
        isMobile={isMobile}
        onClose={reinitializeRebookingStates}
        showCloseButton
      />
    </>
  );
};
