import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { CorpPriceQuoteData } from "redmond";
import {
  setTripPurpose,
  setUserSelectedTravelersList,
  updateUserPassengersMultiple,
} from "../../../actions/actions";

import { IStoreState } from "../../../../../reducers/types";
import { CorpDesktopFlightBookWorkflow } from "./component";
import {
  getCardPaymentAccount,
  getPolicyLimit,
  getPriceQuoteWithUpdatedAncillary,
  getUserSelectedPassengerIds,
  getUserSelectedTravelersList,
} from "../../../reducer";
import { isSinglePageMarketplaceEnabledSelector } from "../../../../ancillary/reducer/selectors";
import {
  getCorporateTravel,
  getShopRequest,
  getViewedTripSummaryProperties,
  selectedFareDetailsSelector,
} from "../../../../shop/reducer/selectors";
import {
  mapStateToProps as baseMapStateToProps,
  mapDispatchToProps as baseMapDispatchToProps,
} from "../../capone/DesktopFlightBookWorkflow/container";
import { setCorpFintechEligibility } from "../../../../shop/actions/actions";

const mapStateToProps = (state: IStoreState) => ({
  ...baseMapStateToProps(state),
  corporateTravel: getCorporateTravel(state),
  priceQuote: getPriceQuoteWithUpdatedAncillary(state) as CorpPriceQuoteData,
  isSinglePageMarketplaceEnabled: isSinglePageMarketplaceEnabledSelector(state),
  cardPaymentAccount: getCardPaymentAccount(state),
  policyLimit: getPolicyLimit(state),
  fareDetails: selectedFareDetailsSelector(state),
  selectedPaxIds: getUserSelectedPassengerIds(state),
  shopRequest: getShopRequest(state),
  viewedTripSummaryProperties: getViewedTripSummaryProperties(state),
  selectedTravelersList: getUserSelectedTravelersList(state),
});

const mapDispatchToProps = {
  ...baseMapDispatchToProps,
  setTripPurpose,
  setCorpFintechEligibility,
  updateUserPassengersMultiple,
  setUserSelectedTravelersList,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type CorpDesktopFlightBookWorkflowConnectorProps = ConnectedProps<
  typeof connector
>;

export const CorpConnectedDesktopFlightBookWorkflow = withRouter(
  connector(CorpDesktopFlightBookWorkflow)
);
