/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { CHFAR, ChfarVariantsType } from "../../../../context/experiments";

export type ChFARExperimentSliceState = {
  values: {
    [CHFAR]: ChfarVariantsType;
  };
};

export const initialState: ChFARExperimentSliceState = {
  values: {
    [CHFAR]: "control",
  },
};

const chfarExperimentsSlice = createSlice({
  name: "chfarExperiments",
  initialState,
  reducers: {
    setExperiments(
      experiments,
      action: PayloadAction<Partial<ChFARExperimentSliceState["values"]>>
    ) {
      experiments.values = { ...experiments.values, ...action.payload };
    },
  },
});

export const { setExperiments } = chfarExperimentsSlice.actions;

export default chfarExperimentsSlice.reducer;
