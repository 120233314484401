import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { actions } from "../actions";
import { IStoreState } from "../../../reducers/types";
import { MulticityFlightShop } from "./component";
import {
  isFlightShopLoadingTripSummaries,
  flightShopMulticityProgressSelector,
  tripDetailsByIdSelector,
  tripSummariesErrorSelector,
  tripSummariesErrorCodeSelector,
  hasSetNonFareclassFiltersSelectorV2,
  getSelectedOutgoingSlicePropertiesV2,
  flightShopTypeSelector,
  getBestOfferOverall,
  getOffersByTripId,
  getTravelOfferProperties,
  sortOptionSelector,
  hasSetMaxPriceFilterSelectorV2,
  selectedMulticityTripsSelector,
  flightsByFlightShopTypeSelector,
  getSortedAndFilteredFlights,
  getViewedMulticityFlightListPropertiesForV2,
  getSelectedMulticitySliceProperties,
  airportsSelector,
  selectedTripSelector,
  invertedStopsFilterFlightsSelector,
  isSpiritOrFrontierAirlinesSelectedSelector,
  minFlightPriceSelectorV2,
  getCorporateTravelProperties,
} from "../reducer";
import {
  getRewardsAccountsReferenceIds,
  getRewardsAccountWithLargestEarnForBanner,
  getSelectedAccountReferenceIdIfRedemptionEnabled,
} from "../../rewards/reducer";
import {
  getFareclassOptionFilter,
  getHasSetFareClassFilter,
  getHasSetPolicyFilter,
  getMaxPriceFilter,
  getMulticityRoutes,
  getOrigin,
  getStopsOption,
} from "../../search/reducer";
import {
  isCfarEnabledSelector,
  isCustomizePageMarketplaceEnabledSelector,
} from "../../ancillary/reducer";
import {
  setOpenFlightShopCalendarDesktop,
  setFlightShopType,
  setSortOption,
  setSelectedMarketingAirlineCodes,
  setSelectedOperatingAirlineCodes,
  setPolicyLimit,
} from "../actions/actions";
import { actions as searchActions } from "../../search/actions";
import { fetchRewardsAccounts } from "../../rewards/actions/actions";
import { populateFlightBookQueryParams } from "../../book/actions/actions";
import { fetchTravelWalletDetails } from "../../travel-wallet/actions/actions";
import { getTravelWalletCredit } from "../../travel-wallet/reducer";
import { MulticityFlights } from "@b2bportal/air-shopping-api";
import { getPotentialCrossSellOffers } from "../../cross-sell/reducer/selectors";
import { fetchPotentialCrossSellOffers } from "../../cross-sell/actions/actions";

const mapStateToProps = (state: IStoreState) => {
  const selectedTrip = selectedTripSelector(state);
  const tripId = selectedTrip.tripId || "";

  return {
    tripSummariesLoading: isFlightShopLoadingTripSummaries(state),
    hasFlightsError: tripSummariesErrorSelector(state) || false,
    flightsErrorCode: tripSummariesErrorCodeSelector(state) || null,
    multicityFlights: flightsByFlightShopTypeSelector(
      state
    ) as MulticityFlights | null,
    flightList: getSortedAndFilteredFlights(state),
    invertedStopsFilterFlightList: invertedStopsFilterFlightsSelector(state),
    rewardsKey: getSelectedAccountReferenceIdIfRedemptionEnabled(state),
    fareClassFilter: getFareclassOptionFilter(state),
    multicityFlightShopProgress: flightShopMulticityProgressSelector(state),
    origin: getOrigin(state),
    tripDetailsById: tripDetailsByIdSelector(state),
    multicityRoutes: getMulticityRoutes(state),
    selectedMulticityTrips: selectedMulticityTripsSelector(state),
    viewedMulticityFlightlistProperties:
      getViewedMulticityFlightListPropertiesForV2(state),
    selectedMulticitySliceProperties:
      getSelectedMulticitySliceProperties(state),
    maxFlightPriceFilter: getMaxPriceFilter(state),
    hasAppliedFareClassFilter: getHasSetFareClassFilter(state),
    hasAppliedNonFareclassFilter: hasSetNonFareclassFiltersSelectorV2(state),
    selectedOutgoingSliceProperties:
      getSelectedOutgoingSlicePropertiesV2(state),
    flightShopType: flightShopTypeSelector(state),
    bestOfferOverall: getBestOfferOverall(state),
    offersByTripId: getOffersByTripId(state),
    travelOfferProperties: getTravelOfferProperties(state),
    isCustomizePageMarketplaceEnabled:
      isCustomizePageMarketplaceEnabledSelector(state),
    credit: getTravelWalletCredit(state),
    largestValueAccount: getRewardsAccountWithLargestEarnForBanner(state),
    sortOption: sortOptionSelector(state),
    hasSetMaxPriceFilter: hasSetMaxPriceFilterSelectorV2(state),
    isCfarEnabled: isCfarEnabledSelector(state),
    airports: airportsSelector(state, tripId),
    stopsOption: getStopsOption(state),
    isSpiritOrFrontierAirlinesSelected:
      isSpiritOrFrontierAirlinesSelectedSelector(state),
    minFlightPrice: minFlightPriceSelectorV2(state),
    potentialCrossSellOffers: getPotentialCrossSellOffers(state),
    isInPolicyFilter: getHasSetPolicyFilter(state),
    corporateTravelProperties: getCorporateTravelProperties(state),
    accountReferenceIds: getRewardsAccountsReferenceIds(state),
  };
};

const mapDispatchToProps = {
  fetchTripSummaries: actions.fetchMulticitySummaries,
  stopFetchTripSummaries: actions.stopFetchMulticityTripSummaries,
  fetchTripDetails: actions.fetchTripDetails,
  setMulticityFlightShopProgress: actions.setMulticityFlightShopProgress,
  setOpenFlightShopCalendarDesktop,
  setTripCategory: searchActions.setTripCategory,
  setAirlineFilter: searchActions.setAirlineFilter,
  setStopsOption: searchActions.setStopsOption,
  setFlightNumberFilter: searchActions.setFlightNumberFilter,
  setOutboundArrivalTimeRange: searchActions.setOutboundArrivalTimeRange,
  setOutboundDepartureTimeRange: searchActions.setOutboundDepartureTimeRange,
  setChosenDepartureSlice: actions.setChosenDepartureSlice,
  setMaxPriceFilter: searchActions.setMaxPriceFilter,
  setFareclassOptionFilter: searchActions.setFareclassOptionFilter,
  setAirportFilter: searchActions.setAirportFilter,
  populateFlightBookQueryParams,
  setOpenFlightShopCalendarMobile: actions.setOpenFlightShopCalendarMobile,
  setSelectedFlightIndex: actions.setSelectedFlightIndex,
  populateFlightShopQueryParams: actions.populateFlightShopQueryParams,
  fetchTravelWalletDetails,
  fetchRewardsAccounts,
  setFlightShopType,
  setSortOption,
  setSelectedMarketingAirlineCodes,
  setSelectedOperatingAirlineCodes,
  fetchPotentialCrossSellOffers,
  setPolicyLimit,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type FlightShopConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedMulticityFlightShop = withRouter(
  connector(MulticityFlightShop)
);
