import { combineReducers, Middleware } from "@reduxjs/toolkit";
import experimentsReducer from "./experiments/slice";
import {
  chfarFlightsApiReducer,
  chfarFlightApiReducerPath,
  chfarFlightsApiMiddleware,
} from "./flights/api";
import {
  chfarItinerariesApiReducer,
  chfarItinerariesApiReducerPath,
  chfarItinerariesApiMiddleware,
} from "./itineraries/api";
import {
  chfarLocationApiMiddleware,
  chfarLocationApiReducer,
  chfarLocationApiReducerPath,
} from "./location/api";

// All non-api reducers are combined here
export const reducer = combineReducers({
  experiments: experimentsReducer,
});

export const chfarMiddleWare: Middleware[] = [
  chfarFlightsApiMiddleware,
  chfarLocationApiMiddleware,
  chfarItinerariesApiMiddleware,
];

export const chfarReducers = {
  changeForAnyReason: reducer,
  [chfarFlightApiReducerPath]: chfarFlightsApiReducer,
  [chfarLocationApiReducerPath]: chfarLocationApiReducer,
  [chfarItinerariesApiReducerPath]: chfarItinerariesApiReducer,
};

export type ChangeForAnyReasonState = ReturnType<typeof reducer>;
