import { connect, ConnectedProps } from "react-redux";
import { TripCategory } from "redmond";
import { withRouter } from "react-router";

import { getTripCategory } from "../../../search/reducer/selectors";
import { isSelectingReturnFlightSelector } from "../../../ancillary/reducer/selectors";
import {
  flightShopProgressSelector,
  selectedTripSelector,
  tripDetailsSelector,
  isSelectReturnReadySelector,
  isFlightReviewReadySelector,
  isAddOnOptionAvailableSelector,
  isOptionSelectionCompleteSelector,
  flightShopProductRedeemChoice,
  flightShopTypeSelector,
} from "../../reducer/selectors";
import {
  setFlightShopProgress,
  populateFlightShopQueryParams,
} from "../../actions/actions";
import { populateFlightBookQueryParams } from "../../../book/actions/actions";
import { FlightShopProgressBar } from "./component";
import { IStoreState } from "../../../../reducers/types";

const mapStateToProps = (state: IStoreState) => {
  // TODO: remove once selected fare option is properly stored in redux
  const tripId =
    selectedTripSelector(state)?.tripId || "uvvDSXpSPPMkcvOKyOJcDqJTJIVbmq";

  return {
    isOneWay: getTripCategory(state) === TripCategory.ONE_WAY,
    currentProgress: flightShopProgressSelector(state),
    productRedeemChoice: flightShopProductRedeemChoice(state),
    isTripDetailsEmpty: tripDetailsSelector(state, tripId) === undefined,
    isSelectReturnReady: isSelectReturnReadySelector(state),
    isFlightReviewReady: isFlightReviewReadySelector(state),
    isAddOnOptionAvailable: isAddOnOptionAvailableSelector(state),
    isOptionSelectionComplete: isOptionSelectionCompleteSelector(state),
    isSelectingReturnFlightForRebook: isSelectingReturnFlightSelector(state),
    flightShopType: flightShopTypeSelector(state),
  };
};

const mapDispatchToProps = {
  setFlightShopProgress,
  populateFlightBookQueryParams,
  populateFlightShopQueryParams,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type FlightShopProgressBarConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedFlightShopProgressBar = connector(
  withRouter(FlightShopProgressBar)
);
