import React from "react";
import { Box, Typography } from "@material-ui/core";
import { ActionLink, Icon, IconName } from "halifax";
import { useNavigateToChfar } from "../../hooks/useNavigateToChfar";

import styles from "./styles.module.scss";

export const ChfarMissionControlEditLink: React.FC = () => {
  const navigateToChfar = useNavigateToChfar();

  return (
    <Box className={styles["chfar-mission-control-edit-link"]}>
      <ActionLink
        content={
          <Box className={styles["chfar-mission-control-edit-link__content"]}>
            <Typography
              variant="body2"
              className={styles["chfar-mission-control-edit-link__copy"]}
            >
              Edit travel dates/airports
            </Typography>
            <Icon
              className={styles["chfar-mission-control-edit-link__icon"]}
              name={IconName.PencilEdit}
            />
          </Box>
        }
        onClick={navigateToChfar}
      />
    </Box>
  );
};
