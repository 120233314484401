import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@material-ui/core";

import { BackButton, Header } from "halifax";
import { FlightShopStep, TripCategory } from "redmond";

import { flightShopProgressSelector } from "../../../shop/reducer";
import { getTripCategory } from "../../../search/reducer";
import { useNavigateToChfar } from "../../hooks/useNavigateToChfar";
import { setFlightShopProgress } from "../../../shop/actions/actions";

export const ChfarMobileFlightShopHeader: React.FC = () => {
  const dispatch = useDispatch();
  const flightShopStep = useSelector(flightShopProgressSelector);
  const isRoundTrip = useSelector(getTripCategory) === TripCategory.ROUND_TRIP;

  const navigateToChfar = useNavigateToChfar();

  const handleGoBack = useCallback(() => {
    switch (flightShopStep) {
      case FlightShopStep.ChooseDeparture:
        navigateToChfar();
        break;

      case FlightShopStep.ChooseReturn:
        dispatch(setFlightShopProgress(FlightShopStep.ChooseDeparture));
        break;

      case FlightShopStep.ReviewItinerary:
        if (isRoundTrip) {
          dispatch(setFlightShopProgress(FlightShopStep.ChooseReturn));
        } else {
          dispatch(setFlightShopProgress(FlightShopStep.ChooseDeparture));
        }
        break;

      default: {
        navigateToChfar();
        break;
      }
    }
  }, [flightShopStep, isRoundTrip, navigateToChfar, setFlightShopProgress]);

  return (
    <Header
      left={<BackButton onClick={handleGoBack} />}
      center={<Typography variant="body1">Choose new flight</Typography>}
      isMobile
      fullWidth
    />
  );
};
