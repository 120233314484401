import { transformToStringifiedPackagesAvailabilityQuery } from "b2b-base/src/utils/queryStringHelpers";
import {
  FareclassOptionFilter,
  FareclassShelfBrandName,
  ITripTerminus,
  PackagesEntryTypeEnum,
  SliceStopCountFilter,
} from "redmond";
import { getCheapestSelectedFareclass } from "./fareClass";
import {
  PATH_PACKAGES,
  PATH_PACKAGES_HOTELS_AVAILABILITY,
  PATH_PACKAGES_SEARCH,
} from "./urlPaths";

export function generatePackagesCTAUrl(
  {
    fareClass,
    destination,
    origin,
    fromDate,
    untilDate,
    stopsOption,
    adultsCount,
    childrenCount,
    infantsInSeatCount,
    infantsOnLapCount,
    matchesMobile,
    entryPoint,
  }: {
    fareClass: FareclassOptionFilter;
    destination: ITripTerminus | null;
    origin: ITripTerminus | null;
    fromDate: Date | null;
    untilDate?: Date | null;
    stopsOption?: SliceStopCountFilter;
    adultsCount: number;
    childrenCount?: number;
    infantsInSeatCount?: number;
    infantsOnLapCount?: number;
    matchesMobile: boolean;
    entryPoint: PackagesEntryTypeEnum;
  },
  defaultFare: FareclassShelfBrandName = "basic"
): string {
  const cheapestSelectedFareClass = getCheapestSelectedFareclass(
    fareClass,
    defaultFare
  );

  const airportsRegex = /\(.*$/;
  const destinationLabelWithoutAirports = destination?.label
    .replace(airportsRegex, "")
    .trimEnd();

  const hasChildrenOrInfants =
    childrenCount || infantsInSeatCount || infantsOnLapCount;

  const isAllRequiredFieldsPresent =
    !!origin && !!destination && fromDate && untilDate && !hasChildrenOrInfants;

  let urlPath;
  if (isAllRequiredFieldsPresent) {
    urlPath = `${PATH_PACKAGES_HOTELS_AVAILABILITY}`;
  } else if (matchesMobile) {
    urlPath = `${PATH_PACKAGES_SEARCH}`;
  } else {
    urlPath = `${PATH_PACKAGES}`;
  }

  return `${urlPath}${transformToStringifiedPackagesAvailabilityQuery({
    origin: origin?.id?.code?.code,
    destination: destinationLabelWithoutAirports,
    fromDate,
    untilDate,
    stopsOption: stopsOption!,
    fareClass: cheapestSelectedFareClass,
    adultsCount,
    childrenCount,
    infantsInSeatCount,
    infantsOnLapCount,
    entryPoint,
  })}`;
}
