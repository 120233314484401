import { PostBookingConfirmAndBookConnectorProps } from "./container";
import { RouteComponentProps } from "react-router";
import "./styles.scss";
import {
  AncillaryPostBookingValidOffer,
  TravelProductEnum,
  FlightItinerarySlice,
  TripSegment,
  FlightItinerarySegment,
  DISRUPTION_PROTECTION_BODY,
  CallState,
  TokenizeCardErrors,
  AncillaryPostBookingPurchaseResponseEnum,
  AncillaryPostBookingPurchaseKindEnum,
  MultiMethod,
  MessageMethodEnum,
  UserCard,
  Rewards,
  PaymentV2Enum,
  PaymentV2,
  TravelWalletOffer,
  TravelWalletCredit,
} from "redmond";
import {
  ActionButton,
  GenericDetailsCard,
  GenericDetailsCardComponentEnum,
  InfoBox,
  Icon,
  IconName,
  twoDecimalFormatter,
  formatDateTime,
  getTimeDifference,
  B2BPaymentMethodSelectWorkflow,
  CheckoutPaymentForm,
  pluralize,
  AirlineIcon,
  renderFDABulletList,
  renderFDAInfoBoxOptionOne,
  renderFDAInfoBoxOptionTwo,
  LoadingPopup,
  B2BSpinner,
  PoweredByHopper,
  DesktopPopupModal,
  FlightDetailsSummary,
  useDeviceTypes,
  MobilePopoverCard,
  TEST_CARD_LAST_FOURS,
  GenericInfoPopup,
  GenericDetailsCardComponent,
  roundToTwoDecimals,
  PricingBreakdown,
  NotificationBanner,
  BannerSeverity,
} from "halifax";
import { isCorpTenant } from "@capone/common";
import React, { useEffect, useState } from "react";
import { Typography, Box, Checkbox, Button } from "@material-ui/core";
import clsx from "clsx";
import * as constants from "./constants";
import { PATH_DISRUPTION_PROTECTION } from "../../../../utils/urlPaths";
import { config } from "../../../../api/config";
import * as paymentTextConstants from "../../../book/components/PaymentCard/textConstants";
import {
  OPT_IN_APP_NOTIF_ID,
  OPT_IN_SMS_ID,
} from "../../../book/components/AlertOptIn/textConstants";
import { PaymentCard } from "../../../common/PaymentCard";
import { Prices } from "@b2bportal/air-shopping-api";
import {
  IPricingLineItem,
  ISummaryLineItem,
} from "halifax/build/PriceBreakdown";

type AmountType<T extends PaymentV2> = { model: T; fiatValue: number };

export interface IPostBookingConfirmAndBookConnectorPropsProps
  extends PostBookingConfirmAndBookConnectorProps,
    RouteComponentProps {
  validOffer: AncillaryPostBookingValidOffer;
}

interface ErrorModalInput {
  title: string;
  subtitle: string;
  secondary: {
    text: string;
    onClick: () => any;
  };
  primary: {
    text: string;
    onClick: () => any;
  };
}

const handleViewTerms = () => {
  window.open(PATH_DISRUPTION_PROTECTION, "_blank")?.focus();
};

export const AncillaryPostBookingConfirmAndBook = (
  props: IPostBookingConfirmAndBookConnectorPropsProps
) => {
  const {
    purchasePostBookingOffer,
    validOffer,
    fetchRewardsAccounts,
    rewardsAccounts,
    paymentMethods,
    verifyPaymentMethod,
    setSelectedPaymentMethodId,
    selectedPaymentMethodId,
    selectedRewardsPaymentAccountId,
    selectedRewardsPaymentAccount,
    listPaymentMethods,
    listPaymentMethodCallState,
    verifyPaymentMethodCallState,
    history,
    disruptionOptInEnabled,
    isDisruptionPushNotifEnabled,
    disruptionDelayRewardsEnabled,
    setTravelWalletCredit,
    setTravelWalletOffers,
  } = props;

  const canShowNotifications =
    disruptionOptInEnabled && isDisruptionPushNotifEnabled;

  const { matchesMobile } = useDeviceTypes();
  const [openErrorPaymentModal, setOpenErrorPaymentModal] = useState<
    ErrorModalInput | undefined
  >(undefined);
  const [isNotCapOneAccount, setIsNotCapOneAccount] = useState(false);
  const [tokenizeErrors, setTokenizeErrors] = useState<TokenizeCardErrors[]>(
    []
  );
  const [openViewDetailsModal, setOpenViewDetailsModal] =
    useState<boolean>(false);
  const [openMobileModal, setOpenMobileModal] = useState<boolean>(false);
  const [sliceIndex, setSliceIndex] = useState<number>();
  const [multiMethod, setMultiMethod] = useState<MultiMethod>(
    validOffer.offer.multiMethod
  );

  const choice = validOffer.offer.choices[0];

  const policyDetails = choice.policyDetails;

  useEffect(() => {
    setTravelWalletCredit(validOffer.offer.wallet.credit);
    setTravelWalletOffers([]);
  }, []);

  useEffect(() => {
    if (listPaymentMethodCallState === CallState.NotCalled) {
      listPaymentMethods();
    }

    fetchRewardsAccounts(true);
  }, []);

  useEffect(() => {
    if (
      selectedPaymentMethodId ||
      paymentMethods.length == 0 ||
      rewardsAccounts.length == 0
    )
      return;

    for (var i = 0; i < rewardsAccounts.length; i++) {
      const account = rewardsAccounts[i];
      const matchingSavedPayment = paymentMethods.find(
        (payment) =>
          payment.last4 === account.lastFour ||
          account.lastFourVirtualCardNumbers?.includes(payment.last4 || "")
      );
      if (!matchingSavedPayment) continue;
      setSelectedPaymentMethodId({
        paymentMethodId: matchingSavedPayment.id,
        accountId: account.accountReferenceId,
      });
      return;
    }
  }, [selectedPaymentMethodId, paymentMethods.length, rewardsAccounts.length]);

  const handleCloseErrorPopup = () => {
    isNotCapOneAccount && setIsNotCapOneAccount(false);
    setOpenErrorPaymentModal(undefined);
  };

  const attemptPurchase = () => {
    if (amountRemaining() > 0) return;

    setOpenErrorPaymentModal(undefined);
    purchasePostBookingOffer({
      offer: validOffer.offer,
      request: {
        ...choice.purchaseRequest,
        payments: amounts.map((amount) => amount.model),
        accountReferenceId: selectedRewardsPaymentAccountId ?? undefined,
        multiMethod: canShowNotifications ? multiMethod : undefined,
      },
    });
  };

  const addPaymentError: ErrorModalInput = {
    title: isNotCapOneAccount
      ? paymentTextConstants.CAP_ONE_INVALID_CREDIT_CARD_TITLE
      : paymentTextConstants.UNABLED_TO_ADD_PAYMENT,
    subtitle: isNotCapOneAccount
      ? paymentTextConstants.CAP_ONE_INVALID_CREDIT_CARD_SUBTITLE
      : tokenizeErrors.length > 0
      ? tokenizeErrors[0].message
      : paymentTextConstants.ADD_PAYMENT_AGAIN,
    secondary: {
      text: paymentTextConstants.EDIT_PAYMENT_METHOD,
      onClick: handleCloseErrorPopup,
    },
    primary: {
      text: paymentTextConstants.TRY_AGAIN,
      onClick: handleCloseErrorPopup,
    },
  };

  useEffect(() => {
    if (openErrorPaymentModal) return;
    const attemptState = validOffer.attemptState;
    if (attemptState.state != AncillaryPostBookingPurchaseKindEnum.Failed)
      return;
    switch (attemptState.response.PostBookingOfferPurchaseResponseV2) {
      case AncillaryPostBookingPurchaseResponseEnum.PaymentFailed:
        setOpenErrorPaymentModal({
          title: "Payment Failed",
          subtitle:
            "Please try again, select another payment, or add another payment",
          secondary: {
            text: "Try Again",
            onClick: attemptPurchase,
          },
          primary: {
            text: "Close",
            onClick: handleCloseErrorPopup,
          },
        });
    }
  }, [validOffer.attemptState.state]);

  const premiumAmount = policyDetails.premiumAmount;
  const premiumAmountFormatted = twoDecimalFormatter(
    premiumAmount.perPaxAmount
  );

  // todo: rm this later
  if (
    !validOffer.offer.itinerary.itinerary.bookedItinerary.travelItinerary.slices
  ) {
    return <>no slices were returned</>;
  }

  const itinerary =
    validOffer.offer.itinerary.itinerary.bookedItinerary.travelItinerary;

  const airportMap = validOffer.offer.itinerary.context.airports;
  const airlines = validOffer.offer.itinerary.context.airlines;
  const totalSlices = itinerary.slices.length;

  const totalAmountFormatted = twoDecimalFormatter(premiumAmount.totalAmount);

  const minDelayMinutes = policyDetails.policyDetails.minDelayMinutes;
  const delayThresholdHours = minDelayMinutes == 180 ? "3" : "2";

  const amounts: AmountType<PaymentV2>[] = [];

  function totalAmountReadyToPay() {
    return (
      Math.round(
        amounts.reduce((acc, obj) => {
          acc += obj.fiatValue;
          return acc;
        }, 0) * 100
      ) / 100
    );
  }

  function amountRemaining() {
    return (
      Math.round(
        Math.max(0, premiumAmount.totalAmount - totalAmountReadyToPay()) * 100
      ) / 100
    );
  }

  const offerToApplyAmout = getTravelWalletOfferToApplyAmount(
    choice.prices.fiat.value,
    {
      creditToApply: props.creditsToApply,
    }
  );

  if (props.creditToApply && offerToApplyAmout > 0) {
    amounts.push({
      model: {
        PaymentV2: PaymentV2Enum.TravelWalletCredit,
        offerId: props.creditToApply.id,
        description: "TravelWalletCredit", // creditToApply doesn't have a description
        paymentAmount: {
          fiatValue: {
            amount: roundToTwoDecimals(offerToApplyAmout),
            currency: props.creditToApply.amount.currency,
          },
        },
      },
      fiatValue: offerToApplyAmout,
    });
  }

  const doesCreditCoverWholeAmount = amountRemaining() <= 0;

  if (
    !doesCreditCoverWholeAmount &&
    props.rewardsPaymentInFiatCurrency &&
    props.rewardsPaymentInRewardsCurrency &&
    props.selectedRewardsPaymentAccount
  ) {
    const splitRewardsPayment: Rewards = {
      paymentAmount: {
        rewardsAccountId:
          props.selectedRewardsPaymentAccount.accountReferenceId,
        fiatValue: {
          amount: roundToTwoDecimals(props.rewardsPaymentInFiatCurrency.value),
          currency: props.rewardsPaymentInFiatCurrency.currencyCode,
        },
        rewardsPrice: {
          value: roundToTwoDecimals(
            props.rewardsPaymentInRewardsCurrency.value
          ),
          currency: props.rewardsPaymentInRewardsCurrency.currency,
        },
      },
      PaymentV2: PaymentV2Enum.Rewards,
    };
    amounts.push({
      model: splitRewardsPayment,
      fiatValue: props.rewardsPaymentInFiatCurrency.value,
    });
  }

  if (
    selectedPaymentMethodId &&
    selectedPaymentMethodId !== "" &&
    amountRemaining() > 0
  ) {
    const amount = amountRemaining();
    const splitUserCardPayment: UserCard & { last4?: string } = {
      paymentId: selectedPaymentMethodId,
      accountReferenceId: props.selectedRewardsPaymentAccountId ?? undefined,
      paymentAmount: {
        currency: premiumAmount.currency,
        amount: roundToTwoDecimals(amount),
      },
      PaymentV2: PaymentV2Enum.UserCard,
    };
    amounts.push({
      model: splitUserCardPayment,
      fiatValue: amount,
    });
  }

  const totalAmountRemaining = amountRemaining();

  const goBack = ({ isMobile }: { isMobile: boolean }) => {
    return (
      <Box
        className={clsx("post-booking-offer-header-container", { isMobile })}
      >
        <Box className="back-button-container" onClick={() => history.goBack()}>
          <Icon name={IconName.ChevronLeftBlueThin} />
          <span className="text-container">Back</span>
        </Box>
      </Box>
    );
  };

  const getSegmentHeading = ({
    flightItinerary,
    sliceIndex,
    includeDestinationCode,
  }: {
    flightItinerary: FlightItinerarySlice;
    sliceIndex: number;
    includeDestinationCode: boolean;
  }) => {
    const departureDate = flightItinerary.segments[0].updatedDeparture;
    const destinationCode =
      flightItinerary.segments.slice(-1)[0].destination.locationCode;

    const destinationAirport = airportMap[destinationCode];

    if (totalSlices <= 2) {
      const direction =
        totalSlices < 2
          ? constants.OUTBOUND
          : sliceIndex < totalSlices - 1
          ? constants.OUTBOUND
          : constants.RETURN;
      return (
        <>
          <span className="bold">{direction}</span> to{" "}
          {destinationAirport.cityName}{" "}
          {includeDestinationCode && `(${destinationCode})`} on{" "}
          {formatDateTime(departureDate, constants.DATE_FORMAT)}
        </>
      );
    } else {
      const departureCode =
        flightItinerary.segments.slice(-1)[0].origin.locationCode;

      const departureAirport = airportMap[departureCode];
      return (
        <>
          <span className="bold">
            {constants.FLIGHT_NUM(sliceIndex + 1)}
            {": "}
            {departureAirport.cityName}
            {includeDestinationCode && `(${departureCode})`}
            {" to "}
            {destinationAirport.cityName}{" "}
            {includeDestinationCode && `(${destinationCode})`}
          </span>
          {" on "}
          {formatDateTime(departureDate, constants.DATE_FORMAT)}
        </>
      );
    }
  };

  const getAirlineName = (flightItinerary: FlightItinerarySlice) => {
    const flightAirline =
      flightItinerary.segments[0].operatingAirline ??
      flightItinerary.segments[0].marketingAirline;
    const airlineName = airlines[flightAirline.code];

    return (
      <Box className="airline-row">
        <AirlineIcon airlineCode={flightAirline.code} />
        <div>{airlineName.fullName}</div>
      </Box>
    );
  };

  const getFlightDurationRow = (flightItinerary: FlightItinerarySlice) => {
    const departureTime = flightItinerary.segments[0].updatedDeparture;
    const arrivalTime = flightItinerary.segments.slice(-1)[0].updatedArrival;
    const flightDuration = getTimeDifference(
      flightItinerary.segments.slice(-1)[0].updatedArrival,
      flightItinerary.segments[0].updatedDeparture
    );
    return (
      <>
        <div className="flex-1">
          {formatDateTime(departureTime, constants.TIME_FORMAT)} -{" "}
          {formatDateTime(arrivalTime, constants.TIME_FORMAT)}
        </div>
        <div className="flex-1">{flightDuration}</div>
      </>
    );
  };

  const getNumStopsText = (flightItinerary: FlightItinerarySlice) => {
    const numStops = flightItinerary.segments.length - 1;
    return numStops == 0
      ? constants.NONSTOP
      : `${numStops} ${pluralize(numStops, constants.STOP, constants.STOPS)}`;
  };

  const PaymentAddingElement = () => (
    <LoadingPopup
      indicatorSize={"small"}
      indicator={B2BSpinner}
      open={true}
      popupSize={"small"}
      message={"Adding your payment method"}
      footer={PoweredByHopper}
    />
  );

  const getFDAHeading = () => (
    <div
      className={clsx([
        !matchesMobile && "header-border-bottom",
        "postbooking-confirm-and-book-header-row",
      ])}
    >
      <div>
        <div className={clsx("flex-row", "align-center")}>
          <Icon name={IconName.CheckShieldBlue} />
          <span className="capital-one-tag">
            Provided by <span className="bold">Capital One Travel</span>
          </span>
        </div>
        <div className={clsx(["header-title", "fda-header-title"])}>
          {constants.FLIGHT_DISRUPTION_ASSISTANCE}
        </div>
      </div>
      <div className="premium-amount-container">
        <div className="premium-amount">
          {currencySymbol}
          {premiumAmountFormatted}
        </div>
        <div className="per-traveler">{constants.PER_TRAVELER}</div>
      </div>
    </div>
  );

  const getFDAInfoBoxes = () => (
    <>
      <div className="info-box-row">
        <div className="info-box-item-1-3">
          <InfoBox
            title={constants.INFO_BOX_PRIMARY_TITLE}
            bodySections={[
              <div className="info-box-item-primary">
                {renderFDABulletList({ delayThresholdHours })}
              </div>,
            ]}
            mobile={false}
          />
        </div>
        <div className="info-box-item-2-3">
          <InfoBox
            className="secondary-info-box"
            mobile={false}
            title={constants.INFO_BOX_SECONDARY_TITLE}
            bodySections={[
              renderFDAInfoBoxOptionOne(),
              renderFDAInfoBoxOptionTwo(),
            ]}
          />
        </div>
      </div>
    </>
  );

  // need to make a FlightItinerarySegment into a TripSegment to pass it into the reusable component
  const transform = (
    flightItinerarySegs: FlightItinerarySegment[]
  ): TripSegment[] => {
    return flightItinerarySegs.reduce((acc, cur) => {
      const destinationName = airportMap[cur.destination.locationCode].name;
      const originName = airportMap[cur.origin.locationCode].name;
      acc.push({
        ...cur,
        airlineCode: cur.marketingAirline.code,
        airlineName: airlines[cur.marketingAirline.code].fullName,
        flightNumber: cur.marketingAirline.flightNumber.toString(),
        departureTime: cur.updatedDeparture,
        arrivalTime: cur.updatedArrival,
        originName,
        originCode: cur.origin.locationCode,
        destinationName,
        destinationCode: cur.destination.locationCode,
        marketingAirline: {
          ...cur.marketingAirline,
          name: airlines[cur.marketingAirline.code].fullName,
        },
        operatingAirline: {
          ...(cur.operatingAirline ?? cur.marketingAirline),
          name: airlines[
            cur.operatingAirline?.code ?? cur.marketingAirline.code
          ].fullName,
        },
      });
      return acc;
    }, [] as TripSegment[]);
  };

  const tripSegmentMap =
    validOffer.offer.itinerary.itinerary.bookedItinerary.travelItinerary.slices.map(
      (slice) => transform(slice.segments)
    );

  const getYourTripSection = () => {
    function createBox(
      slices: { slice: FlightItinerarySlice; index: number }[]
    ) {
      return (
        <div className="trip-section-container">
          {slices.map((holder) => {
            const { slice, index } = holder;
            return (
              <div
                className={clsx(
                  "trip-section-box",
                  matchesMobile && "flex-row",
                  matchesMobile && "space-between"
                )}
              >
                <Box>
                  <div className="trip-segment-heading">
                    {getSegmentHeading({
                      flightItinerary: slice,
                      sliceIndex: index,
                      includeDestinationCode: true,
                    })}
                  </div>
                  <div className="flex-row">{getFlightDurationRow(slice)}</div>
                  <div className={clsx(["secondary-copy", "flex-row"])}>
                    <div className="flex-1">{getAirlineName(slice)}</div>
                    <div className="flex-1">
                      <span>{getNumStopsText(slice)}</span>
                    </div>
                  </div>
                  {!matchesMobile && (
                    <div
                      className="view-details"
                      onClick={() => {
                        setOpenViewDetailsModal(true);
                        setSliceIndex(index);
                      }}
                    >
                      {constants.VIEW_DETAILS}
                    </div>
                  )}
                </Box>
                {matchesMobile && (
                  <Box className={clsx("flex-row", "align-center")}>
                    <Box
                      className="mobile-icon-container"
                      onClick={() => {
                        setOpenMobileModal(true);
                        setSliceIndex(index);
                      }}
                    >
                      <Icon name={IconName.ChevronRightBlueThin} />
                    </Box>
                  </Box>
                )}
              </div>
            );
          })}
        </div>
      );
    }

    const formatted = itinerary.slices.map((slice, index) => {
      return { slice: slice, index: index };
    });

    if (formatted.length <= 2) {
      return createBox(formatted);
    } else {
      return (
        <div className="multi-leg-trip">
          {formatted.map((holder) => {
            return createBox([holder]);
          })}
        </div>
      );
    }
  };

  const disruptionNotificationsSection = () => {
    if (!canShowNotifications) {
      return null;
    }

    return (
      <Box className="post-booking-notifications-container">
        <Typography
          variant="h4"
          className="post-booking-notifications-text"
          dangerouslySetInnerHTML={{
            __html: constants.WELL_SEND_ALERTS(
              validOffer.offer.emailAddress,
              delayThresholdHours
            ),
          }}
        />
        <Typography
          variant="h4"
          className="post-booking-notifications-text"
          dangerouslySetInnerHTML={{
            __html: constants.INTERESTED_IN_ALERTS,
          }}
        />
        <Box className="post-booking-notifications-checkbox-container">
          <Box
            className={clsx([
              "post-booking-notifications-text",
              "checkbox-row",
            ])}
          >
            <Checkbox
              checked={!!multiMethod.push}
              onChange={(value) => {
                setMultiMethod({
                  ...multiMethod,
                  push: value.target.checked
                    ? { MessageMethod: MessageMethodEnum.Push }
                    : undefined,
                });
              }}
              value={"app-notif"}
              id={OPT_IN_APP_NOTIF_ID}
              name={OPT_IN_APP_NOTIF_ID}
              disabled={false}
              style={{
                paddingBottom: "4px",
                paddingTop: "4px",
              }}
            />
            <Typography
              variant="h4"
              className="post-booking-notifications-text"
            >
              <span className="title-bold"> {constants.APP_NOTIFICATIONS}</span>
            </Typography>
          </Box>

          {validOffer.offer.phoneNumber && (
            <Box
              className={clsx([
                "post-booking-notifications-text",
                "checkbox-row",
              ])}
            >
              <Checkbox
                checked={!!multiMethod.sms}
                onChange={(value) => {
                  setMultiMethod({
                    ...multiMethod,
                    sms: value.target.checked
                      ? { MessageMethod: MessageMethodEnum.Sms }
                      : undefined,
                  });
                }}
                value={"app-notif"}
                id={OPT_IN_SMS_ID}
                name={OPT_IN_SMS_ID}
                disabled={false}
                style={{
                  paddingBottom: "4px",
                  paddingTop: "4px",
                }}
              />
              <Typography
                variant="h4"
                className="post-booking-notifications-text"
              >
                <span className="title-bold"> {constants.SMS}</span>
              </Typography>
            </Box>
          )}
        </Box>
        {!!multiMethod.sms && validOffer.offer.phoneNumber ? (
          <Typography
            variant="h4"
            className="post-booking-notifications-text"
            dangerouslySetInnerHTML={{
              __html: constants.INTERESTED_IN_ALERTS_PHONE_NUMBER(
                validOffer.offer.phoneNumber
              ),
            }}
          />
        ) : null}
        {!!multiMethod.push ? (
          <NotificationBanner
            className="price-watch-notification-banner"
            icon={<Icon name={IconName.InfoCircle} className="info-icon" />}
            severity={BannerSeverity.NOTICE}
            html={constants.OPT_IN_BANNER_COPY}
          />
        ) : null}
        <Typography
          variant="h4"
          className="post-booking-notifications-text"
          dangerouslySetInnerHTML={{
            __html: constants.NOTIFICATIONS_DISCLAIMER,
          }}
        />
      </Box>
    );
  };

  const getPaymentMethodHeader = () => (
    <div
      className={clsx([
        "card-header-container",
        "header-title",
        "header-border-bottom",
      ])}
    >
      {constants.PAYMENT_METHOD}
    </div>
  );

  const handleOnAddPaymentMethod = (token: string, last4: string) => {
    // note: cap1 specific logic:
    // A card should be deemed ineligible if the last four digits do not match one of the cards associated with their accounts.
    const account = rewardsAccounts.find(
      (account) =>
        account.lastFour === last4 ||
        account.lastFourVirtualCardNumbers?.includes(last4)
    );
    let matchingRewardsAccount = account;

    const isAddingVCNPaymentMethod = !!(
      matchingRewardsAccount?.lastFourVirtualCardNumbers &&
      matchingRewardsAccount?.lastFourVirtualCardNumbers?.includes(last4)
    );

    // TODO: bad practice, remove this in favor of real test accounts when we have them
    const isTestCard =
      window.__mclean_env__.ENV !== "production" &&
      TEST_CARD_LAST_FOURS.includes(last4);
    if (isTestCard) {
      matchingRewardsAccount = rewardsAccounts[0];
    }

    if (!!matchingRewardsAccount || isTestCard || isCorpTenant(config.TENANT)) {
      verifyPaymentMethod(
        { token },
        matchingRewardsAccount?.accountReferenceId!,
        isAddingVCNPaymentMethod
      );
    } else {
      setIsNotCapOneAccount(true);
      setOpenErrorPaymentModal(addPaymentError);
    }
  };

  const renderCheckoutPaymentForm = () => (
    <CheckoutPaymentForm
      loading={verifyPaymentMethodCallState === CallState.InProcess}
      loadingEl={<PaymentAddingElement />}
      onSubmit={(token, last4) => {
        handleOnAddPaymentMethod(token, last4);
      }}
      saveLabel="Save"
      onError={(errors) => {
        setTokenizeErrors(errors);
        setOpenErrorPaymentModal(addPaymentError);
      }}
      spreedlyEnvironmentKey={config.spreedlyEnvironmentKey}
      isMobile={matchesMobile}
      className="b2b"
      buttonClassName="b2b"
    />
  );

  const getAddPaymentFlow = () => (
    <B2BPaymentMethodSelectWorkflow
      errorModalOpen={!!openErrorPaymentModal}
      rewardsAccounts={rewardsAccounts ?? []}
      savedPayments={paymentMethods ?? []}
      selectedPaymentHopperId={selectedPaymentMethodId}
      paymentMethodDisabled={() => false}
      // empty fcn bc we are not enabling the delete option rn
      removePaymentMethod={() => {}}
      selectPaymentMethod={(paymentId, rewardsAccount) => {
        setSelectedPaymentMethodId({
          paymentMethodId: paymentId,
          accountId: rewardsAccount?.accountReferenceId,
        });
      }}
      renderCheckoutPaymentForm={renderCheckoutPaymentForm}
      product={TravelProductEnum.Flights}
      titles={{
        addAdditionalPaymentCta: "Add another payment method",
        addPaymentCta: "Add payment",
        addPaymentModalTitle: "Add one of your Capital One credit cards",
        backToCardSelectionCta: "Back",
        paymentFormHeader: (cardName) => (
          <Typography
            variant={"h4"}
            dangerouslySetInnerHTML={{
              __html: constants.ADD_PAYMENT_FORM_HEADER_TEXT(cardName),
            }}
          />
        ),
        cardEndingIn: "Ending in",
      }}
      disabled={false}
      hideDeletePaymentMethod
    />
  );

  const notificationsSection = disruptionNotificationsSection();

  const pricing = choice.prices;

  const totalPrice: Prices = {
    fiat: pricing.fiat,
    accountSpecific: {
      ...pricing.accountSpecific,
    },
  };

  function createPaymentComponents(): GenericDetailsCardComponent[] {
    if (!disruptionDelayRewardsEnabled) {
      return [
        {
          content: getPaymentMethodHeader(),
          component: GenericDetailsCardComponentEnum.Custom,
        },
        {
          content: getAddPaymentFlow(),
          component: GenericDetailsCardComponentEnum.Custom,
        },
      ];
    } else {
      return [
        {
          content: (
            <PaymentCard
              {...{
                ...props,
                rewardsTitleId: "",
                rewardsSubtitleId: "",
                total: totalPrice,
                type: "disruption",
                isTravelCreditPaymentOnly: doesCreditCoverWholeAmount,
              }}
            />
          ),
          component: GenericDetailsCardComponentEnum.Custom,
        },
      ];
    }
  }

  const pricingItems: IPricingLineItem[] = validOffer.offer.passengers.map(
    (passengerName) => {
      return {
        lineTitle: passengerName,
        disruptionProtectionFee: premiumAmount.perPaxAmount,
        skipIfPriceUnavailable: true,
      };
    }
  );

  const summaryLineItems: ISummaryLineItem[] = [
    {
      type: "total",
      fiatPrice: choice.prices.fiat,
      rewardsPrice:
        choice.prices.rewards[selectedRewardsPaymentAccountId ?? ""],
    },
  ];

  const currencySymbol = choice.prices.fiat.currencySymbol;

  if (!!props.creditsToApply) {
    summaryLineItems.push({
      type: "custom",
      icon: "piggy-bank-icon",
      label: "Travel credits applied:",
      fiatPrice: {
        currencyCode: choice.prices.fiat.currencyCode,
        currencySymbol: choice.prices.fiat.currencySymbol,
        value: -getTravelWalletOfferToApplyAmount(choice.prices.fiat.value, {
          creditToApply: props.creditsToApply,
        }),
      },
      className: "travel-wallet-line",
      travelCreditBreakdown: [],
    });
  }

  if (
    props.rewardsPaymentInFiatCurrency &&
    selectedRewardsPaymentAccount &&
    props.rewardsPaymentInRewardsCurrency
  ) {
    summaryLineItems.push({
      type: "rewards",
      fiatPrice: props.rewardsPaymentInFiatCurrency,
      rewardsAccountName: selectedRewardsPaymentAccount.productDisplayName,
      rewardsPrice: props.rewardsPaymentInRewardsCurrency,
    });
  }

  const ccPaymentAmount = amounts.find(
    (amount) => amount.model.PaymentV2 === PaymentV2Enum.UserCard
  )?.fiatValue;

  summaryLineItems.push({
    type: "payment",
    fiatPrice: {
      currencyCode: choice.prices.fiat.currencyCode,
      currencySymbol: currencySymbol,
      value: ccPaymentAmount ?? totalAmountRemaining,
    },
    lastFour: paymentMethods.find((cc) => cc.id === selectedPaymentMethodId)
      ?.last4,
  });

  const getCheckoutBreakdown = () => (
    <PricingBreakdown
      className={clsx("price-breakdown", "")}
      pricingItems={pricingItems}
      summaryItems={summaryLineItems}
      currencySymbol={currencySymbol}
      currencyCode={choice.prices.fiat.currencyCode}
      isVITripSelected={false}
    />
  );

  const getCheckoutBreakdownOld = () => (
    <>
      {getCheckoutBreakdown()}
      <Box className="action-button-container">
        <ActionButton
          className="action-button"
          message={
            matchesMobile ? (
              <span className="action-button-text">
                Add and pay for {currencySymbol}
                {totalAmountFormatted}
              </span>
            ) : (
              <span className="action-button-text">
                {constants.ADD_TO_TRIP}
              </span>
            )
          }
          defaultStyle="h4r-primary"
          disabled={(totalAmountRemaining ?? 0) > 0}
          onClick={attemptPurchase}
        />
      </Box>
    </>
  );

  const getCheckoutBreakdownHolder = () => (
    <Box className="checkout-template-column-right-wrapper">
      <Box
        className={clsx(
          "checkout-template-card-content-container",
          "pricing-breakdown"
        )}
      >
        <Box className="pricing-breakdown-header-container">
          <Typography className="header" variant="h2">
            {constants.CHECKOUT_BREAKDOWN}
          </Typography>
        </Box>
        <Box className={clsx("payment-break-down")}>
          {getCheckoutBreakdown()}
        </Box>
        <Box className="confirm-book-button-container">
          <Button
            className="confirm-book-button"
            disabled={(totalAmountRemaining ?? 0) > 0}
            onClick={attemptPurchase}
          >
            {matchesMobile ? (
              <span className="action-button-text">
                Add and pay for {currencySymbol}
                {totalAmountFormatted}
              </span>
            ) : (
              <span className="action-button-text">
                {constants.ADD_TO_TRIP}
              </span>
            )}
          </Button>
        </Box>
      </Box>
    </Box>
  );

  const renderMobile = () => {
    return (
      <>
        <Box
          className={clsx("postbooking-confirm-and-book-root", {
            isMobile: matchesMobile,
          })}
        >
          <Box className="postbooking-header-container">
            {constants.VIEW_DETAILS_CONFIRM_PURCHASE}
          </Box>
          <Box className="postbooking-subtitle-container">
            {constants.LEARN_ABOUT_FDA}
          </Box>

          <GenericDetailsCard
            contentClassName="postbooking-confirm-and-book-card"
            openModal={false}
            contentOnly={true}
            mainContent={[
              {
                content: getFDAHeading(),
                component: GenericDetailsCardComponentEnum.Custom,
              },
              {
                content: getFDAInfoBoxes(),
                component: GenericDetailsCardComponentEnum.Custom,
              },
              {
                className: clsx("disruption-body-air-offer-redesign"),
                title: constants.MORE_DETAILS_TITLE,
                items: [
                  constants.MORE_DETAILS_LINE_ONE,
                  constants.MORE_DETAILS_LINE_TWO,
                  constants.MORE_DETAILS_LINE_THREE,
                  constants.MORE_DETAILS_LINE_FOUR,
                ],
                isOrdered: false,
                newSection: true,
                component: GenericDetailsCardComponentEnum.PointsGroup,
              },
              {
                content: [
                  {
                    title: constants.T_C_COPY,
                    body: DISRUPTION_PROTECTION_BODY,
                  },
                ],
                component: GenericDetailsCardComponentEnum.AccordionGroup,
              },
            ]}
            isMobile={true}
          />

          <Box className="jump-to-checkout">
            <ActionButton
              defaultStyle="h4r-secondary"
              message={
                <>
                  <Box className={clsx("flex-row", "align-center")}>
                    <Icon name={IconName.ChevronLeftBlueThin} />
                    <span className="text">Jump to checkout</span>
                  </Box>
                </>
              }
              onClick={() =>
                document
                  .getElementById("postbooking-checkout-breakdown")
                  ?.scrollIntoView({ behavior: "smooth" })
              }
            />
          </Box>
          <GenericDetailsCard
            contentClassName="postbooking-confirm-and-book-card"
            openModal={false}
            contentOnly={true}
            mainContent={[
              {
                copy: constants.UPCOMING_TRIP,
                type: "primary",
                component: GenericDetailsCardComponentEnum.GenericCopy,
              },
              {
                copy: constants.FDA_ITINERARY,
                type: "subtitle",
                component: GenericDetailsCardComponentEnum.GenericCopy,
              },
              {
                content: getYourTripSection(),
                component: GenericDetailsCardComponentEnum.Custom,
              },
            ]}
            isMobile={true}
          />
          {notificationsSection && (
            <GenericDetailsCard
              contentClassName="postbooking-confirm-and-book-card"
              openModal={false}
              contentOnly={true}
              mainContent={[
                {
                  copy: constants.SIGN_UP_ALERTS,
                  type: "primary",
                  component: GenericDetailsCardComponentEnum.GenericCopy,
                },
                {
                  content: notificationsSection,
                  component: GenericDetailsCardComponentEnum.Custom,
                },
              ]}
              isMobile={true}
            />
          )}
          <GenericDetailsCard
            contentClassName="postbooking-confirm-and-book-card"
            openModal={false}
            contentOnly={true}
            mainContent={createPaymentComponents()}
            isMobile={true}
          />
          <GenericDetailsCard
            contentClassName="postbooking-confirm-and-book-card"
            openModal={false}
            contentOnly={true}
            mainContent={[
              {
                content: (
                  <Box className="postbooking-header-container mb-10">
                    {constants.CHECKOUT_BREAKDOWN}
                  </Box>
                ),
                component: GenericDetailsCardComponentEnum.Custom,
              },
              {
                content: getCheckoutBreakdownOld(),
                component: GenericDetailsCardComponentEnum.Custom,
              },
            ]}
            isMobile={true}
          />
        </Box>
        <MobilePopoverCard
          open={openMobileModal}
          topRightButton={
            <Box
              onClick={() => {
                setOpenMobileModal(false);
              }}
            >
              <Icon name={IconName.BlueX} />
            </Box>
          }
        >
          <Box
            className={clsx("postbooking-modal-root", {
              isMobile: matchesMobile,
            })}
          >
            <Box className="modal-popup-container">
              <Typography variant="body1">
                {getSegmentHeading({
                  flightItinerary: itinerary.slices[sliceIndex ?? 0],
                  sliceIndex: sliceIndex ?? 0,
                  includeDestinationCode: false,
                })}
              </Typography>
            </Box>
            <FlightDetailsSummary
              departureTime={tripSegmentMap[sliceIndex ?? 0][0].departureTime}
              segments={tripSegmentMap[sliceIndex ?? 0]}
              planeInfo=""
              fareClass={
                tripSegmentMap[sliceIndex ?? 0][0].cabinClassName ?? ""
              }
              showTitle={false}
              className="postbooking-trips-modal"
              unsetStopoverMaxWidth={true}
            />
          </Box>
        </MobilePopoverCard>
      </>
    );
  };

  const renderDesktop = () => {
    return (
      <>
        <Box className="postbooking-confirm-and-book-root">
          <Box className="postbooking-header-container">
            {constants.VIEW_DETAILS_CONFIRM_PURCHASE}
          </Box>
          <Box className="postbooking-subtitle-container">
            {constants.LEARN_ABOUT_FDA}
          </Box>
          <Box className="flex-row">
            <Box className="primary-col">
              <GenericDetailsCard
                contentClassName="postbooking-confirm-and-book-card"
                openModal={false}
                contentOnly={true}
                mainContent={[
                  {
                    content: getFDAHeading(),
                    component: GenericDetailsCardComponentEnum.Custom,
                  },
                  {
                    content: getFDAInfoBoxes(),
                    component: GenericDetailsCardComponentEnum.Custom,
                  },
                  {
                    className: clsx("disruption-body-air-offer-redesign"),
                    title: constants.MORE_DETAILS_TITLE,
                    items: [
                      constants.MORE_DETAILS_LINE_ONE,
                      constants.MORE_DETAILS_LINE_TWO,
                      constants.MORE_DETAILS_LINE_THREE,
                      constants.MORE_DETAILS_LINE_FOUR,
                    ],
                    isOrdered: false,
                    newSection: true,
                    component: GenericDetailsCardComponentEnum.PointsGroup,
                  },
                  {
                    message: constants.T_C_COPY,
                    onClick: handleViewTerms,
                    position: "left",
                    underline: true,
                    component: GenericDetailsCardComponentEnum.ClickableLink,
                  },
                ]}
                isMobile={false}
                redesignClassName="air-offer-redesign"
              />
              <GenericDetailsCard
                contentClassName="postbooking-confirm-and-book-card"
                openModal={false}
                contentOnly={true}
                mainContent={[
                  {
                    copy: constants.UPCOMING_TRIP,
                    type: "primary",
                    component: GenericDetailsCardComponentEnum.GenericCopy,
                  },
                  {
                    copy: constants.FDA_ITINERARY,
                    type: "subtitle",
                    component: GenericDetailsCardComponentEnum.GenericCopy,
                  },
                  {
                    content: getYourTripSection(),
                    component: GenericDetailsCardComponentEnum.Custom,
                  },
                ]}
              />
              {notificationsSection && (
                <GenericDetailsCard
                  contentClassName="postbooking-confirm-and-book-card"
                  openModal={false}
                  contentOnly={true}
                  mainContent={[
                    {
                      copy: constants.SIGN_UP_ALERTS,
                      type: "primary",
                      component: GenericDetailsCardComponentEnum.GenericCopy,
                    },
                    {
                      content: notificationsSection,
                      component: GenericDetailsCardComponentEnum.Custom,
                    },
                  ]}
                />
              )}
              <GenericDetailsCard
                contentClassName="postbooking-confirm-and-book-card"
                openModal={false}
                contentOnly={true}
                mainContent={createPaymentComponents()}
              />
            </Box>
            <Box className="secondary-col">{getCheckoutBreakdownHolder()}</Box>
          </Box>
        </Box>
        <DesktopPopupModal
          open={openViewDetailsModal}
          onClose={() => setOpenViewDetailsModal(false)}
        >
          <Box className="postbooking-modal-root">
            <Box className="modal-popup-container">
              <Typography variant="body1">
                {getSegmentHeading({
                  flightItinerary: itinerary.slices[sliceIndex ?? 0],
                  sliceIndex: sliceIndex ?? 0,
                  includeDestinationCode: false,
                })}
              </Typography>
            </Box>
            <FlightDetailsSummary
              departureTime={tripSegmentMap[sliceIndex ?? 0][0].departureTime}
              segments={tripSegmentMap[sliceIndex ?? 0]}
              planeInfo=""
              fareClass={
                tripSegmentMap[sliceIndex ?? 0][0].cabinClassName ?? ""
              }
              showTitle={false}
              className="postbooking-trips-modal"
              unsetStopoverMaxWidth={true}
            />
          </Box>
        </DesktopPopupModal>
      </>
    );
  };

  const errorModal = () => {
    const input = openErrorPaymentModal;
    if (!input) return <></>;

    return (
      <GenericInfoPopup
        open={!!input}
        image={
          <Icon
            className="error-icon"
            name={
              isNotCapOneAccount
                ? IconName.ErrorState
                : IconName.UnableToProcess
            }
          />
        }
        title={input.title}
        subtitle={input.subtitle}
        buttons={[
          {
            buttonText: input.secondary.text,
            onClick: input.primary.onClick,
            defaultStyle: "h4r-secondary",
          },
          {
            buttonText: input.primary.text,
            onClick: input.primary.onClick,
            defaultStyle: "h4r-primary",
            buttonWrapperClassName: "b2b",
          },
        ]}
        isMobile={matchesMobile}
      ></GenericInfoPopup>
    );
  };

  return (
    <>
      {goBack({ isMobile: matchesMobile })}
      {matchesMobile ? renderMobile() : renderDesktop()}
      {errorModal()}
      {validOffer.attemptState.state ==
        AncillaryPostBookingPurchaseKindEnum.InProgress && (
        <LoadingPopup
          indicatorSize={"small"}
          indicator={B2BSpinner}
          open={true}
          message={"Purchasing"}
          classes={[
            "post-booking-view-offer-loading-modal",
            ...(matchesMobile ? ["mobile"] : []),
          ]}
        />
      )}
    </>
  );
};

const getTravelWalletOfferToApplyAmount = (
  totalBeforeWalletItems: number,
  travelWalletItemsToApply: {
    offerToApply?: TravelWalletOffer;
    creditToApply?: TravelWalletCredit;
  }
) => {
  if (travelWalletItemsToApply.creditToApply && totalBeforeWalletItems > 0) {
    const maxApplicableCredit =
      travelWalletItemsToApply.offerToApply?.maxApplicableCredit?.amount;

    let amount =
      maxApplicableCredit &&
      maxApplicableCredit < travelWalletItemsToApply.creditToApply.amount.amount
        ? maxApplicableCredit
        : travelWalletItemsToApply.creditToApply.amount.amount;
    if (totalBeforeWalletItems < amount * -1) {
      if (
        maxApplicableCredit &&
        maxApplicableCredit <
          travelWalletItemsToApply.creditToApply.amount.amount &&
        travelWalletItemsToApply.creditToApply.amount.amount * -1 <
          totalBeforeWalletItems
      ) {
        return travelWalletItemsToApply.creditToApply.amount.amount * -1;
      }
      return totalBeforeWalletItems;
    }
    if (
      maxApplicableCredit &&
      maxApplicableCredit < travelWalletItemsToApply.creditToApply.amount.amount
    ) {
      return travelWalletItemsToApply.creditToApply.amount.amount * -1;
    }
    return amount * -1;
  } else {
    return 0;
  }
};
