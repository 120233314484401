import React, { useContext, useEffect, useMemo, useState } from "react";
import { Box, Chip, Divider, Typography } from "@material-ui/core";
import {
  CallState,
  CorpSessionInfo,
  TokenizeCardErrors,
  TravelProductEnum,
} from "redmond";
import {
  GenericInfoPopup,
  B2BSpinner,
  LoadingIndicator,
  useDeviceTypes,
  LoadingPopup,
  PoweredByHopper,
  CheckoutPaymentForm,
  B2BPaymentMethodSelectWorkflow,
  Icon,
  IconName,
  createElementId,
  TEST_CARD_LAST_FOURS,
  NotificationBanner,
  BannerSeverity,
  getPaymentCardSubtitle,
} from "halifax";
import clsx from "clsx";
import { useExperimentIsVariant } from "@capone/experiments";
import { RouteComponentProps } from "react-router";
import { isCorpTenant, OUT_OF_POLICY_REWARDS_BANNER } from "@capone/common";

import { RewardsSelection } from "../RewardsSelection";
import { PaymentCardConnectorProps } from "./container";
import {
  ADD_PAYMENT_AGAIN,
  EDIT_PAYMENT_METHOD,
  UNABLED_TO_ADD_PAYMENT,
  PAYMENT_STEP_TITLE,
  PAYMENT_STEP_SUBTITLE,
  REWARDS_ACCOUNT_TITLE,
  REWARDS_ACCOUNT_SUBTITLE,
  CAP_ONE_INVALID_CREDIT_CARD_TITLE,
  CAP_ONE_INVALID_CREDIT_CARD_SUBTITLE,
  PAYMENT_METHOD_TITLE,
  CTA_SINGLE_ACCOUNT_ADD_YOUR_TEXT,
  CTA_SINGLE_ACCOUNT_CREDIT_CARD_TEXT,
  ADD_PAYMENT_METHOD_CTA_MULTIPLE_ACCOUNTS,
  ADD_PAYMENT_METHOD_MODAL_TITLE,
  ADD_ADDITIONAL_PAYMENT_METHOD_CTA,
  BACK_TO_CARD_SELECTION_CTA,
  CARD_ENDING_IN_TEXT,
  ADD_PAYMENT_FORM_HEADER_TEXT,
  MOBILE_PAYMENT_STEP_TITLE,
  MOBILE_PAYMENT_STEP_SUBTITLE,
  TRY_AGAIN,
  INELIGIBLE_ACCOUNTS_NOTICE,
  INELIGIBLE_ACCOUNTS_TOOLTIP,
  ADD_PAYMENT_FORM_SUBTITLE_TEXT,
  PAYMENT_CARD_SUBTITLE_WITH_CREDITS_AND_OFFERS,
  CTA_CORP_NON_FINANCIAL_USER_CREDIT_CARD_TEXT,
} from "./textConstants";
import { config } from "../../../../api/config";
import { CheckoutSteps } from "../../reducer/selectors";
import {
  ANNUAL_TRAVEL_CREDITS,
  AVAILABLE,
  CONTROL,
  CREDIT_OFFER_STACKING_V1,
  getExperimentVariant,
  getExperimentVariantCustomVariants,
  TRAVEL_SALE,
  TRAVEL_SALE_VARIANTS,
  useExperiments,
  VCN_ENABLEMENT,
} from "../../../../context/experiments";
import { TravelWalletSelection } from "../TravelWalletSelection";
import "./styles.scss";
import { FlightFreezeTravelWalletSelection } from "../../../freeze/components/FlightFreezeTravelWalletSelection";
import { ClientContext } from "../../../../App";

const getAddPaymentMethodSubtitle = (
  nonFinancialUser: boolean,
  creditAndOfferStackingExperimentV1: boolean,
  corpHideTravelOffers: boolean,
  matchesMobile: boolean,
  canRedeemRewards: boolean
) => {
  if (nonFinancialUser) {
    return PAYMENT_STEP_SUBTITLE(false);
  }

  return creditAndOfferStackingExperimentV1
    ? PAYMENT_CARD_SUBTITLE_WITH_CREDITS_AND_OFFERS(!corpHideTravelOffers)
    : matchesMobile
    ? MOBILE_PAYMENT_STEP_SUBTITLE(canRedeemRewards)
    : PAYMENT_STEP_SUBTITLE(canRedeemRewards);
};

const PaymentAddingElement = () => (
  <LoadingPopup
    indicatorSize="small"
    indicator={B2BSpinner}
    open
    popupSize="small"
    message="Adding your payment method"
    footer={PoweredByHopper}
  />
);

export interface IPaymentCardProps
  extends PaymentCardConnectorProps,
    RouteComponentProps {
  disabled?: boolean;
  // note: this prop is used to overwrite the copy in step-title
  paymentStepTitle?: string;
  className?: string;
  isPriceFreezePurchase?: boolean;
}

export const PaymentCard = ({
  listPaymentMethods,
  verifyPaymentMethod,
  deletePaymentMethod,
  setSelectedPaymentMethodId,
  fetchRewardsAccounts,
  fetchProductToEarn,
  fetchAllEarnForProduct,
  selectedRewardsPaymentAccount,
  selectedRewardsPaymentAccountId,
  isCreditCardPaymentRequired,
  totalCreditCardPaymentRequired,
  paymentMethods,
  rewardsAccounts,
  selectedPaymentMethodId,
  listPaymentMethodCallState,
  verifyPaymentMethodCallState,
  deletePaymentMethodCallState,
  paymentStepTitle,
  disabled = false,
  getCheckoutStepNumber,
  hasError,
  className,
  isTravelCreditPaymentOnly,
  isStackedTravelWalletPaymentOnly,
  isTravelWalletOfferPaymentOnly,
  canRedeemRewards,
  userIsPrimary,
  viewedTripSummaryProperties,
  isPriceFreezePurchase,
  isPriceFreezeTravelCreditPaymentOnly,
  canUseTravelWallet,
  offerToApply,
  earnByRewardsAccountId,
  totalCardPaymentRequired,
}: IPaymentCardProps) => {
  const { matchesMobile } = useDeviceTypes();
  const [openErrorPaymentModal, setOpenErrorPaymentModal] = useState(false);
  const [isNotCapOneAccount, setIsNotCapOneAccount] = useState(false);
  const [tokenizeErrors, setTokenizeErrors] = useState<TokenizeCardErrors[]>(
    []
  );

  const expState = useExperiments();
  const { sessionInfo } = useContext(ClientContext);

  const allowAnyCards = useExperimentIsVariant(
    "c1-corporate-credit-card-validation",
    "any-spark"
  );

  const vcnEnablement = getExperimentVariant(
    expState.experiments,
    VCN_ENABLEMENT
  );
  const isVCNEnabled = React.useMemo(
    () => vcnEnablement === AVAILABLE,
    [vcnEnablement]
  );

  const creditAndOfferStackingExperimentV1 = getExperimentVariant(
    expState.experiments,
    CREDIT_OFFER_STACKING_V1
  );
  const isCreditAndOfferStackingExperimentV1 = useMemo(
    () => creditAndOfferStackingExperimentV1 === AVAILABLE,
    [creditAndOfferStackingExperimentV1]
  );

  const isAnnualTravelCreditsExperiment =
    getExperimentVariant(expState.experiments, ANNUAL_TRAVEL_CREDITS) ===
    AVAILABLE;

  const travelSaleVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    TRAVEL_SALE,
    TRAVEL_SALE_VARIANTS
  );
  const isTravelSaleEnabled = travelSaleVariant !== CONTROL;

  const isCorpHideTravelOffers = useExperimentIsVariant(
    "corp-hide-travel-wallet-offers",
    AVAILABLE
  );

  const corpSessionInfo = sessionInfo && (sessionInfo as CorpSessionInfo);

  const isNonFinancialUser = corpSessionInfo?.corporateInfo?.cap1Role === "NFU";

  useEffect(() => {
    listPaymentMethods();
    fetchRewardsAccounts(true, sessionInfo);
  }, []);

  useEffect(() => {
    fetchAllEarnForProduct();
  }, [totalCardPaymentRequired?.value, offerToApply]);

  const ineligibleRewardsAccounts = useMemo(
    () =>
      rewardsAccounts.filter(
        (account) => !(account.allowRewardsRedemption ?? true)
      ),
    [rewardsAccounts]
  );

  const handleOnAddPaymentMethod = (token: string, last4: string) => {
    // note: cap1 specific logic:
    // A card should be deemed ineligible if the last four digits do not match one of the cards associated with their accounts.
    const account = rewardsAccounts.find(
      (account) =>
        account.lastFour === last4 ||
        account.lastFourVirtualCardNumbers?.includes(last4)
    );
    let matchingRewardsAccount = account;

    const isAddingVCNPaymentMethod = !!(
      matchingRewardsAccount?.lastFourVirtualCardNumbers &&
      matchingRewardsAccount?.lastFourVirtualCardNumbers?.includes(last4)
    );

    // TODO: bad practice, remove this in favor of real test accounts when we have them
    const isTestCard =
      window.__mclean_env__.ENV !== "production" &&
      TEST_CARD_LAST_FOURS.includes(last4);
    if (isTestCard) {
      matchingRewardsAccount = rewardsAccounts[0];
    }

    if (!!matchingRewardsAccount || isTestCard || isCorpTenant(config.TENANT)) {
      verifyPaymentMethod(
        { token },
        matchingRewardsAccount?.accountReferenceId!,
        isAddingVCNPaymentMethod
      );
    } else {
      setIsNotCapOneAccount(true);
      setOpenErrorPaymentModal(true);
    }
  };

  const handleCloseErrorPopup = () => {
    isNotCapOneAccount && setIsNotCapOneAccount(false);
    setOpenErrorPaymentModal(false);
  };

  const renderCheckoutPaymentForm = () => (
    <CheckoutPaymentForm
      loading={verifyPaymentMethodCallState === CallState.InProcess}
      loadingEl={<PaymentAddingElement />}
      onSubmit={(token, last4) => {
        handleOnAddPaymentMethod(token, last4);
      }}
      saveLabel="Save"
      onError={(errors) => {
        setTokenizeErrors(errors);
        setOpenErrorPaymentModal(true);
      }}
      spreedlyEnvironmentKey={config.spreedlyEnvironmentKey}
      isMobile={matchesMobile}
      className="b2b"
      buttonClassName="b2b"
    />
  );

  const isPaymentMethodSelectDisabled =
    selectedRewardsPaymentAccount?.isTiered ||
    !isCreditCardPaymentRequired ||
    !totalCreditCardPaymentRequired ||
    selectedRewardsPaymentAccountId === undefined;

  const getAddPaymentCtaText = () => {
    if (isNonFinancialUser) {
      return CTA_CORP_NON_FINANCIAL_USER_CREDIT_CARD_TEXT;
    }

    return rewardsAccounts.length === 1 ? (
      <Box
        className={clsx("add-payment-cta-container", {
          mobile: matchesMobile,
          disabled: disabled || isPaymentMethodSelectDisabled,
        })}
      >
        <Typography variant="body1">
          {CTA_SINGLE_ACCOUNT_ADD_YOUR_TEXT}
          <b className="card-name">{rewardsAccounts[0].productDisplayName}</b>
          {CTA_SINGLE_ACCOUNT_CREDIT_CARD_TEXT}
        </Typography>
        {rewardsAccounts[0].earn.flightsMultiplier &&
        rewardsAccounts[0].earn.flightsMultiplier > 0 ? (
          <Chip
            label={`Earn ${rewardsAccounts[0].earn.flightsMultiplier}X on flights`}
            className={clsx("earn-chip", {
              enabled: !isPaymentMethodSelectDisabled,
            })}
          />
        ) : null}
      </Box>
    ) : (
      ADD_PAYMENT_METHOD_CTA_MULTIPLE_ACCOUNTS
    );
  };

  const paymentMethodWorkflowTitles = () => ({
    addPaymentCta: getAddPaymentCtaText(),
    addPaymentModalTitle: ADD_PAYMENT_METHOD_MODAL_TITLE,
    addAdditionalPaymentCta: ADD_ADDITIONAL_PAYMENT_METHOD_CTA,
    backToCardSelectionCta: BACK_TO_CARD_SELECTION_CTA,
    paymentFormHeader: (cardName: string) => (
      <Typography
        variant="h4"
        dangerouslySetInnerHTML={{
          __html: ADD_PAYMENT_FORM_HEADER_TEXT(cardName),
        }}
      />
    ),
    paymentFormSubtitle: isVCNEnabled
      ? ADD_PAYMENT_FORM_SUBTITLE_TEXT
      : undefined,
    cardEndingIn: CARD_ENDING_IN_TEXT,
  });

  const getPaymentCardTitle = (): string => {
    if (paymentStepTitle) {
      return paymentStepTitle;
    }
    if (matchesMobile) {
      return MOBILE_PAYMENT_STEP_TITLE(canRedeemRewards);
    }
    return PAYMENT_STEP_TITLE(
      getCheckoutStepNumber(CheckoutSteps.REWARDS_AND_PAYMENT),
      canRedeemRewards
    );
  };
  const getParadiseCardMessage = () =>
    rewardsAccounts.find((acct) =>
      acct.productDisplayName.toLowerCase().includes("paradise")
    ) ? (
      <NotificationBanner
        className={clsx("paradise-banner-notification", {
          mobile: matchesMobile,
        })}
        label="Bookings made with your Paradise card through Capital One Travel cannot be serviced by concierge services. If you’d like concierge to manage your booking, book in the Capital One concierge app."
        severity={BannerSeverity.NOTICE}
      />
    ) : null;

  const rewardsTitleId = createElementId("rewardsTitle");
  const rewardsSubtitleId = createElementId("rewardsSubtitle");

  let travelWalletSelection;
  let rewardsSection;

  if (isPriceFreezePurchase) {
    travelWalletSelection = (
      <FlightFreezeTravelWalletSelection
        disabled={disabled}
        isMobile={matchesMobile}
        isAnnualTravelCreditsExperiment={isAnnualTravelCreditsExperiment}
        isCreditAndOfferStackingExperimentV1={
          isCreditAndOfferStackingExperimentV1
        }
        isTravelSale={isTravelSaleEnabled}
      />
    );
    rewardsSection = (
      <RewardsSelection
        {...{
          disabled:
            disabled ||
            (isCreditAndOfferStackingExperimentV1 &&
              (isTravelCreditPaymentOnly ||
                isStackedTravelWalletPaymentOnly ||
                isTravelWalletOfferPaymentOnly ||
                (!!isPriceFreezePurchase &&
                  isPriceFreezeTravelCreditPaymentOnly))),
          rewardsTitleId,
          rewardsSubtitleId,
        }}
        isPriceFreezePurchase
      />
    );
  } else {
    travelWalletSelection = (
      <TravelWalletSelection
        disabled={disabled}
        isMobile={matchesMobile}
        isAnnualTravelCreditsExperiment={isAnnualTravelCreditsExperiment}
        isCreditAndOfferStackingExperimentV1={
          isCreditAndOfferStackingExperimentV1
        }
        isTravelSale={isTravelSaleEnabled}
      />
    );
    rewardsSection = (
      <RewardsSelection
        {...{
          disabled:
            disabled ||
            (isCreditAndOfferStackingExperimentV1 &&
              (isTravelCreditPaymentOnly ||
                isStackedTravelWalletPaymentOnly ||
                isTravelWalletOfferPaymentOnly ||
                (!!isPriceFreezePurchase &&
                  isPriceFreezeTravelCreditPaymentOnly))),
          rewardsTitleId,
          rewardsSubtitleId,
        }}
      />
    );
  }

  return (
    <>
      {listPaymentMethodCallState === CallState.InProcess ||
      deletePaymentMethodCallState === CallState.InProcess ? (
        <LoadingIndicator
          indicatorSize="small"
          indicator={B2BSpinner}
          message={
            listPaymentMethodCallState === CallState.InProcess
              ? `Fetching`
              : `Deleting`
          }
        />
      ) : (
        <Box
          className={clsx(
            "payment-methods-container",
            {
              mobile: matchesMobile,
              disabled,
            },
            className
          )}
        >
          <Typography className="step-title" variant="h2">
            {getPaymentCardTitle()}
          </Typography>
          <Typography variant="body2" className="payment-step-subtitle">
            {getAddPaymentMethodSubtitle(
              isNonFinancialUser,
              isCreditAndOfferStackingExperimentV1,
              isCorpHideTravelOffers,
              matchesMobile,
              canRedeemRewards
            )}
          </Typography>
          {canRedeemRewards && ineligibleRewardsAccounts.length > 0 && (
            <NotificationBanner
              className="authorized-users-rewards-banner"
              label={INELIGIBLE_ACCOUNTS_NOTICE(rewardsAccounts)}
              severity={BannerSeverity.NOTICE}
              tooltip={
                rewardsAccounts.length === ineligibleRewardsAccounts.length
                  ? {
                      label: INELIGIBLE_ACCOUNTS_TOOLTIP(
                        ineligibleRewardsAccounts
                      ),
                      icon: IconName.InfoCircle,
                    }
                  : undefined
              }
            />
          )}

          {(canRedeemRewards || canUseTravelWallet) && (
            <Divider className="payment-methods-container-divider" />
          )}
          {isCreditAndOfferStackingExperimentV1 &&
            (canUseTravelWallet || !!isPriceFreezePurchase) &&
            travelWalletSelection}
          {getParadiseCardMessage()}
          {canRedeemRewards && (
            <>
              <Typography
                variant="h3"
                className="rewards-account-title"
                id={rewardsTitleId}
              >
                {REWARDS_ACCOUNT_TITLE(config.TENANT)}
              </Typography>
              <Typography
                variant="body2"
                dangerouslySetInnerHTML={{
                  __html: REWARDS_ACCOUNT_SUBTITLE(config.TENANT),
                }}
                id={rewardsSubtitleId}
                className="rewards-accounts-subtitle"
              />
              {rewardsSection}
            </>
          )}
          {!canRedeemRewards && userIsPrimary && (
            <NotificationBanner
              className="out-of-policy-rewards-banner"
              severity={BannerSeverity.NOTICE}
              label={OUT_OF_POLICY_REWARDS_BANNER}
            />
          )}

          <Divider className="payment-methods-container-divider" />

          <Typography className="payment-method-title" variant="h3">
            {PAYMENT_METHOD_TITLE}
          </Typography>

          {getPaymentCardSubtitle(
            rewardsAccounts[0],
            corpSessionInfo?.corporateInfo?.businessName ?? "",
            isNonFinancialUser,
            config.TENANT,
            totalCreditCardPaymentRequired,
            !selectedPaymentMethodId,
            isPaymentMethodSelectDisabled
          )}

          <B2BPaymentMethodSelectWorkflow
            errorModalOpen={openErrorPaymentModal}
            rewardsAccounts={rewardsAccounts ?? []}
            savedPayments={paymentMethods ?? []}
            selectedPaymentHopperId={selectedPaymentMethodId}
            disabled={
              isPaymentMethodSelectDisabled ||
              disabled ||
              hasError ||
              (isCreditAndOfferStackingExperimentV1 &&
                (isTravelCreditPaymentOnly ||
                  isStackedTravelWalletPaymentOnly ||
                  isTravelWalletOfferPaymentOnly))
            }
            selectPaymentMethod={(paymentId, rewardsAccount) => {
              setSelectedPaymentMethodId({
                paymentMethodId: paymentId,
                accountId: rewardsAccount?.accountReferenceId,
                multiTicketType: viewedTripSummaryProperties?.multi_ticket_type,
              });
              fetchProductToEarn();
            }}
            removePaymentMethod={(paymentId: string) => {
              deletePaymentMethod({ paymentId });
            }}
            titles={paymentMethodWorkflowTitles()}
            renderCheckoutPaymentForm={renderCheckoutPaymentForm}
            isMobile={matchesMobile}
            product={TravelProductEnum.Flights}
            loading={verifyPaymentMethodCallState === CallState.InProcess}
            buttonClassName="b2b"
            fullScreenWithBanner={matchesMobile}
            paymentMethodDisabled={(_r) => false}
            isVCNEnabled={isVCNEnabled}
            allowAnyCards={allowAnyCards || isNonFinancialUser}
            tenant={config.TENANT}
            corporateInfo={corpSessionInfo?.corporateInfo}
            showEarnValue={Object.values(earnByRewardsAccountId).some(
              (earn) => earn.amount > 0
            )}
            earnValuesByRewardAcctId={earnByRewardsAccountId}
          />
        </Box>
      )}
      <GenericInfoPopup
        open={openErrorPaymentModal}
        image={
          <Icon
            className="error-icon"
            name={
              isNotCapOneAccount
                ? IconName.ErrorState
                : IconName.UnableToProcess
            }
          />
        }
        title={
          isNotCapOneAccount
            ? CAP_ONE_INVALID_CREDIT_CARD_TITLE
            : UNABLED_TO_ADD_PAYMENT
        }
        subtitle={
          isNotCapOneAccount
            ? CAP_ONE_INVALID_CREDIT_CARD_SUBTITLE
            : tokenizeErrors.length > 0
            ? tokenizeErrors[0].message
            : ADD_PAYMENT_AGAIN
        }
        buttons={[
          {
            buttonText: EDIT_PAYMENT_METHOD,
            onClick: () => {
              handleCloseErrorPopup();
            },
            defaultStyle: "h4r-secondary",
          },
          {
            buttonText: TRY_AGAIN,
            onClick: () => {
              handleCloseErrorPopup();
            },
            defaultStyle: "h4r-primary",
            buttonWrapperClassName: "b2b",
          },
        ]}
        isMobile={matchesMobile}
      />
    </>
  );
};
