import React from "react";

import "./styles.scss";
import { MobileFloatingButton } from "halifax";
import { Box } from "@material-ui/core";
import { FlightBookMobileButtonConnectorProps } from "./container";
import {
  ADD_TRAVELERS,
  ADD_CONTACT_INFO,
  APPLY_REWARDS,
  CONFIRM_AND_BOOK,
} from "./textConstants";

interface IFlightBookMobileButtonProps
  extends FlightBookMobileButtonConnectorProps {
  showPaymentStep: boolean;
  hasContactInfo?: boolean;
  isBookingValid: boolean;
  openContactInfo: () => void;
  onApplyRewards: () => void;
  onConfirmAndBook: () => void;
  paymentCardOpen?: boolean;
}

export const FlightBookMobileButton = (props: IFlightBookMobileButtonProps) => {
  const {
    hasContactInfo,
    selectedTravelersId,
    openContactInfo,
    showPaymentStep,
    onApplyRewards,
    onConfirmAndBook,
    isBookingValid,
    paymentCardOpen,
  } = props;

  const [timeoutPassed, setTimeoutPassed] = React.useState<boolean>(false);

  const getButtonText = () => {
    if (selectedTravelersId.length < 1) {
      return ADD_TRAVELERS;
    }
    if (!hasContactInfo) {
      return ADD_CONTACT_INFO;
    }
    if (!showPaymentStep) {
      return APPLY_REWARDS;
    }

    return CONFIRM_AND_BOOK;
  };
  const isDisabled = showPaymentStep && !isBookingValid;

  React.useEffect(() => {
    // enable button after delay to prevent initial focus state in mobile safari
    if (!paymentCardOpen) {
      setTimeout(() => {
        setTimeoutPassed(true);
      }, 300);
    } else {
      setTimeoutPassed(false);
    }
  }, [paymentCardOpen]);

  if (isDisabled) return null;

  const onClick = () => {
    if (!hasContactInfo) {
      openContactInfo();
    } else if (!showPaymentStep) {
      onApplyRewards();
    } else {
      onConfirmAndBook();
    }
  };

  return (
    <Box className="flight-book-mobile-button-container">
      <MobileFloatingButton
        className="flight-book-mobile-button"
        onClick={onClick}
        wrapperClassName="b2b"
        disabled={!timeoutPassed}
      >
        {getButtonText()}
      </MobileFloatingButton>
    </Box>
  );
};
