import { useGetChfarItineraryByIdQuery } from "../reducers/itineraries/api";
import getChfarDataFromItinerary from "../reducers/itineraries/helpers";

export const useChfarPolicy = (itineraryId: string, skip = false) =>
  useGetChfarItineraryByIdQuery(itineraryId, {
    selectFromResult: (result) => ({
      data:
        result.data != null
          ? getChfarDataFromItinerary(result.data)
          : result.data,
      isLoading: result.isLoading,
      isError: result.isError,
    }),
    skip
  });
