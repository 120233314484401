import React, {
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Box } from "@material-ui/core";
import {
  createGenerateClassName,
  StylesProvider,
  ThemeProvider,
} from "@material-ui/core/styles";
import { AgentBanner, TravelerWorkflowDialog, useDeviceTypes } from "halifax";
import * as H from "history";
import { IntlProvider } from "react-intl";
import { Provider, useDispatch, useSelector } from "react-redux";
import { Route, Router, Switch } from "react-router-dom";
import {
  CustomEvents,
  EditTravelerModalState,
  FlightClientAssetProps,
  FlightModuleProps,
  FlightShopType,
  IPerson,
  SessionInfo,
  installColorConfig,
  SelfServeEvents,
  TripCategory,
  SavedTravelersProperties,
  ViewedSavedTravelersProperties,
  CorpSessionInfo,
  VIEWED_TRAVELER_DETAILS,
  ViewedTravelerDetailsProperties,
  ModalNames,
} from "redmond";
import { isCorpTenant, useIsAutoApprovalEnabled } from "@capone/common";
import {
  ExperimentsHookProvider,
  useExperimentsById,
} from "@capone/experiments";

import { useMedalliaFeedback } from "b2b-base/src/components/MedalliaFeedback";
import { fetchUserPolicies } from "b2b-base/src/api/v1/policies/fetchPolicies";
import fetchUserInfo from "./api/v0/user/fetchUserInfo";
import AxiosInterceptors from "./components/AxiosInterceptors";
import ErrorBoundary from "./components/ErrorBoundary";
import ExperimentsProvider, {
  PRICE_FREEZE_USER_SELECTED_DURATION,
  PRICE_FREEZE_USER_SELECTED_DURATION_COPY,
  PRICE_FREEZE_USER_SELECTED_DURATION_VARIANTS,
  PRICE_FREEZE_USER_SELECTED_DURATION_DISPLAY,
  CFAR,
  REFUNDABLE_FARES,
  DISRUPTION,
  ANCILLARY_MARKETPLACE,
  ANCILLARY_MARKETPLACE_VARIANTS,
  useExperiments,
  getExperimentVariant,
  getExperimentVariantCustomVariants,
  PRICE_FREEZE_USER_SELECTED_DURATION_COPY_VARIANTS,
  PRICE_DROP_CREDIT,
  PRICE_PREDICTION_GRADIENT,
  PRICE_PREDICTION_GRADIENT_VARIANTS,
  THEBES_HACKER_FARES_IN_CAP1,
  THEBES_VIRTUAL_INTERLINING_IN_CAP1,
  VI_EXPERIMENT_VARIANTS,
  PRICE_WATCH_PUSH,
  CFAR_SOCIAL,
  CFAR_SOCIAL_VARIANTS,
  PRICE_FREEZE_USER_SELECTED_DURATION_DISPLAY_VARIANTS,
  PRICE_FREEZE_USER_SELECTED_DURATION_NO_SLIDER,
  PRICE_FREEZE_USER_SELECTED_DURATION_NO_SLIDER_VARIANTS,
  CAP_ONE_DISRUPTION_OPT_IN,
  CAP_ONE_DISRUPTION_OPT_IN_VARIANTS,
  NON_FDA_CAP_ONE_DISRUPTION_OPT_IN,
  CHFAR,
  CFAR_DISCOUNT,
  CFAR_DISCOUNT_VARIANTS,
  AIR_OFFER_REDESIGN,
  AIR_OFFER_REDESIGN_VARIANTS,
  PRICE_WATCH_PUSH_DEFAULT_OPT_IN,
  CFAR_MULTI_CITY,
  PRICE_FREEZE_VOID_WINDOW_VARIANTS,
  PRICE_FREEZE_VOID_WINDOW,
  CFAR_ANCILLARY_COMERCH,
  CFAR_COMERCH_VARIANTS,
  POST_BOOKING_OFFER_EXPERIMENT,
  PRICE_FREEZE_NEW_REVIEW_CTA,
  PRICE_FREEZE_DEFAULT_DURATIONS,
  PRICE_FREEZE_DEFAULT_DURATIONS_VARIANTS,
  AIR_REFUNDABLE_FARE_COPY,
  CORP_HIDE_PRICE_DROP_EXPERIMENT,
  POST_BOOKING_OFFER_EXPERIMENT_REWARDS,
  POST_BOOKING_OFFER_VARIANTS,
  PRICE_FREEZE_SHOW_DURATIONS,
  PRICE_FREEZE_SHOW_DURATIONS_VARIANTS,
  CFAR_ENABLE_F9_NK_AIRLINE,
  UC_DEFAULT_VARIANTS,
  FINTECH_CSAT,
  CFAR_REFUND_COPY_VARIANTS,
  CFAR_OFFER_SHOW_REFUND_COPY_EXPERIMENT,
  PRICE_FREEZE_TRAVEL_CREDITS,
  POST_BOOKING_OFFER_REWARDS_VARIANTS,
  HOTEL_CROSS_SELL_V3_EXPERIMENT,
  HOTEL_CROSS_SELL_V3_VARIANTS,
  AIR_PRICE_FREEZE_ONLY_FREEZE_QUOTED_PRICE,
  AIR_PRICE_FREEZE_ONLY_FREEZE_QUOTED_PRICE_VARIANTS,
  DEFAULT_VARIANTS,
  PRICE_FREEZE_PREDICTION_COPY,
  PRICE_FREEZE_BACK_BUTTON_AIR,
  CHFAR_VARIANTS,
  PRICE_FREEZE_FLIGHTS_LIST_EXPERIMENT,
  PRICE_FREEZE_FLIGHTS_LIST_VARIANTS,
} from "./context/experiments";
import UserSourceProvider from "./context/userSource";
import useWindowEventListener from "./hooks/useWindowEventListener";
import * as frenchTranslations from "./lang/fr.json";
import * as englishTranslations from "./lang/en.json";
import { eventsToListen } from "./utils/events";
import { FlightBook } from "./modules/book";
import {
  deleteUserPassenger,
  fetchCorpUserPassengers,
  fetchUserPassengers,
  updateUserPassenger,
} from "./modules/book/actions/actions";
import { PriceMatchBanner } from "./modules/book/components/PriceMatchBanner";
import { getUserPassengers } from "./modules/book/reducer";
import { FlightFreeze } from "./modules/freeze";
import {
  setIsFirstLaunch,
  setAgentEmail,
  setSelectedAccountReferenceId,
  setCorporateInfo,
} from "./modules/rewards/actions/actions";
import { RewardsBanner } from "./modules/rewards/components";
import { FlightSearch } from "./modules/search";
import { FlightShop } from "./modules/shop";
import {
  listWatches,
  setFlightShopExperiments,
} from "./modules/shop/actions/actions";
import { FlightAncillary } from "./modules/ancillary";
import { setPriceFreezeExperiments } from "./modules/freeze/actions/actions";
import {
  setAncillaryExperiments,
  setAutoApproval,
} from "./modules/ancillary/actions/actions";
import { parseQueryString } from "./modules/shop/utils/parseQueryString";
import { FlightShopV2 } from "./modules/shop/v2";
import { resetState } from "./actions/actions";
import { store } from "./store";
import { PAWTUCKET_FLIGHTS_MODULE_ID } from "./utils/moduleIds";
import {
  PATH_BOOK,
  PATH_SHOP,
  PATH_HOME,
  PATH_HOME_SEARCH,
  PATH_FREEZE,
  PATH_ANCILLARY,
  PATH_DISRUPTION_OFFER,
  PATH_CHANGE_FOR_ANY_REASON,
} from "./utils/urlPaths";

import { trackEvent } from "./api/v0/analytics/trackEvent";
import { MulticityFlightShop } from "./modules/shop/multicity";
import { PostBookingViewOffer } from "./modules/ancillary/components/PostBookingViewOffer/container";
import { config } from "./api/config";
import { getRewardsAccounts } from "./modules/rewards/reducer";
import { updateUserSeenModal } from "../../../cap1-application/b2b-base/src/api/v1/user/updateUserSeenModal";
import "./App.scss";
import { selectIsChFAREnabled } from "./modules/change-for-any-reason/reducers/experiments/selectors";
import { setExperiments as setChfarExperiments } from "./modules/change-for-any-reason/reducers/experiments/slice";

const ChangeFlight = React.lazy(
  () => import("./modules/change-for-any-reason/pages/ChangeFlight")
);

function loadLocaleData(locale: string): any {
  switch (locale) {
    case "fr":
      return frenchTranslations;
    default:
      return englishTranslations;
  }
}

const generateClassName = createGenerateClassName({
  productionPrefix: PAWTUCKET_FLIGHTS_MODULE_ID,
  seed: PAWTUCKET_FLIGHTS_MODULE_ID,
});

interface FlightsClientContextType extends FlightClientAssetProps {
  isAgentPortal: boolean;
  isAutoApprovalEnabled: boolean;
  searchImage: string;
}

export const ClientContext = React.createContext<
  Partial<FlightsClientContextType>
>({});

const App = (props: FlightModuleProps) => {
  const {
    baseHistory,
    clientAssets,
    colors,
    experiments,
    isAgentPortal,
    language,
    theme,
    searchImage,
  } = props;

  // TODO store translations, theme, locale in redux state
  const [activeTheme, setActiveTheme] = useState(theme);
  const [locale, setLocale] = useState(language);
  const [messages, setMessages] = useState(loadLocaleData(language).default);
  const [sessionInfo, setSessionInfo] = useState(clientAssets.sessionInfo);
  const [fetchUserCompleted, setFetchUserCompleted] = useState(false);
  const [policies, setPolicies] = useState(clientAssets.policies);

  useEffect(() => {
    // note: it connects Cypress with the Redux store for testing
    // TODO: Add Cypress to window https://github.com/cypress-io/cypress/issues/3924
    // @ts-ignore
    if (window.Cypress) {
      // @ts-ignore
      window.store = store;
    }

    handleFetchUserInfo();
  }, []);

  const handleFetchPolicies = async () => {
    const policiesResponse = await fetchUserPolicies();
    setPolicies(policiesResponse.policyDetail);
  };

  useEffect(() => {
    if (!policies && isCorpTenant(config.TENANT)) {
      handleFetchPolicies();
    }
  }, [policies]);

  installColorConfig(colors);

  const handleFetchUserInfo = async () => {
    try {
      const userInfoResponse = await fetchUserInfo();
      setSessionInfo(userInfoResponse);
    } catch (error) {
      console.error(error);
    } finally {
      setFetchUserCompleted(true);
    }
  };

  const handleThemeChanged = (e: CustomEvent) => {
    setActiveTheme(e.detail);
    console.log("THEME RECEIVED:", e.detail.palette.type);
  };
  const handleLocaleChanged = (e: CustomEvent) => {
    setLocale(e.detail);
    const localMessages = loadLocaleData(e.detail);
    setMessages(localMessages.default);
  };
  useWindowEventListener(eventsToListen.HOST_THEME_CHANGED, handleThemeChanged);
  useWindowEventListener(
    eventsToListen.HOST_LOCALE_CHANGED,
    handleLocaleChanged
  );
  const { firstName, lastName } = sessionInfo?.userInfo || {
    firstName: "",
    lastName: "",
  };

  const isAutoApprovalEnabled = useIsAutoApprovalEnabled(
    clientAssets.policies || policies
  );

  return (
    <Provider store={store}>
      <ExperimentsProvider initState={experiments}>
        <ExperimentsHookProvider isLoggedIn={Boolean(sessionInfo?.csrfToken)}>
          <UserSourceProvider>
            <Router history={baseHistory}>
              <AxiosInterceptors isAgentPortal={isAgentPortal} />
              <ClientContext.Provider
                value={{
                  ...clientAssets,
                  policies,
                  isAgentPortal,
                  sessionInfo,
                  isAutoApprovalEnabled,
                  searchImage,
                }}
              >
                <div className="App">
                  <StylesProvider generateClassName={generateClassName}>
                    <ThemeProvider theme={activeTheme}>
                      {messages != null ? (
                        <IntlProvider
                          locale={locale}
                          defaultLocale="en"
                          messages={messages}
                        >
                          <Switch>
                            {isAgentPortal ? (
                              <Route path="*">
                                <AgentBanner
                                  firstName={firstName}
                                  lastName={lastName}
                                />
                              </Route>
                            ) : (
                              <>
                                <Route path={PATH_BOOK}>
                                  <PriceMatchBanner />
                                </Route>
                                <Route path="*">
                                  <RewardsBanner />
                                </Route>
                              </>
                            )}
                          </Switch>
                          <Body
                            history={baseHistory}
                            sessionInfo={sessionInfo}
                            fetchUserCompleted={fetchUserCompleted}
                            clientAssets={{ ...clientAssets, policies }}
                          />
                        </IntlProvider>
                      ) : (
                        <div>Loading</div>
                      )}
                    </ThemeProvider>
                  </StylesProvider>
                </div>
              </ClientContext.Provider>
            </Router>
          </UserSourceProvider>
        </ExperimentsHookProvider>
      </ExperimentsProvider>
    </Provider>
  );
};

export const Body = (props: {
  history: H.History;
  sessionInfo?: SessionInfo | CorpSessionInfo;
  fetchUserCompleted: boolean;
  clientAssets: FlightClientAssetProps;
}) => {
  const { sessionInfo, fetchUserCompleted, history } = props;
  const { isAutoApprovalEnabled } = useContext(ClientContext);

  const dispatch = useDispatch();
  const { matchesMobile } = useDeviceTypes();
  const expState = useExperiments();
  const travelers = useSelector(getUserPassengers);
  const [editTravelersOpen, setEditTravelersOpen] = useState(
    EditTravelerModalState.closed
  );

  const rewardsAccounts = useSelector(getRewardsAccounts, (a, b) => {
    if (a.length !== b.length) {
      return false;
    }
    return true;
  });

  const corpHidePriceFreeze =
    useExperimentsById("corp-hide-price-freeze")?.variant === "available";

  const userRoles =
    sessionInfo && "corporateInfo" in sessionInfo
      ? sessionInfo.corporateInfo.role
      : [];

  const defaultTravelerId =
    sessionInfo && "corporateInfo" in sessionInfo
      ? sessionInfo.corporateInfo.defaultTravelerId
      : "";

  const hasSeenNfuInfoModal =
    (sessionInfo as CorpSessionInfo).corporateInfo?.hasSeenModalMap?.[
      ModalNames.NFU_IN_PRODUCT_AWARENESS_PROFILES
    ] ?? false;

  const closeEditTravelersModal = useCallback(() => {
    setEditTravelersOpen(EditTravelerModalState.closed);
  }, []);

  const onEditTravelersStateChange = useCallback<(ev: Event) => void>((ev) => {
    const newEditModalState =
      (ev as CustomEvent).detail ?? EditTravelerModalState.closed;

    setEditTravelersOpen(newEditModalState);
  }, []);

  useEffect(() => {
    if (editTravelersOpen === EditTravelerModalState.flight) {
      if (isCorpTenant(config.TENANT)) {
        trackEvent({
          eventName: VIEWED_TRAVELER_DETAILS,
          properties: {
            num_traveler_profiles: travelers.length,
          } satisfies ViewedTravelerDetailsProperties,
        });
      } else {
        trackEvent({
          eventName: SelfServeEvents.ViewedSavedTravelers,
          properties: {
            url: window.location.pathname,
            num_traveler_profiles: travelers.length,
          } satisfies ViewedSavedTravelersProperties,
        });
      }
    }
  }, [editTravelersOpen, travelers.length]);

  const handleAddPass = useCallback(() => {
    !isCorpTenant(config.TENANT) &&
      trackEvent({
        eventName: SelfServeEvents.ClickSavedTravelersAddNew,
        properties: {
          url: window.location.pathname,
        },
      });
  }, []);

  useEffect(() => {
    if (!isCorpTenant(config.TENANT)) {
      dispatch(fetchUserPassengers());
    }

    document.addEventListener(
      CustomEvents.editTravelersStateChange,
      onEditTravelersStateChange
    );

    return () => {
      // Reset all Flight redux states on unmount
      dispatch(resetState());
      document.removeEventListener(
        CustomEvents.editTravelersStateChange,
        onEditTravelersStateChange
      );
    };
  }, []);

  useEffect(() => {
    if (isCorpTenant(config.TENANT) && rewardsAccounts.length > 0) {
      dispatch(fetchCorpUserPassengers());
    }
  }, [rewardsAccounts]);

  const handleAccountSelected = (event: CustomEvent) => {
    dispatch(setSelectedAccountReferenceId(event.detail));
  };
  useWindowEventListener(
    "mclean_rewards_account_selected",
    handleAccountSelected
  );

  // Gate for Change for Any Reason feature
  const isChFAREnabled = useSelector(selectIsChFAREnabled);

  // note: this useEffect helps to store some experiments in redux
  useEffect(() => {
    dispatch(
      setFlightShopExperiments({
        [PRICE_PREDICTION_GRADIENT]: getExperimentVariantCustomVariants(
          expState.experiments,
          PRICE_PREDICTION_GRADIENT,
          PRICE_PREDICTION_GRADIENT_VARIANTS
        ),
        [PRICE_WATCH_PUSH]: getExperimentVariant(
          expState.experiments,
          PRICE_WATCH_PUSH
        ),
        [PRICE_WATCH_PUSH_DEFAULT_OPT_IN]: getExperimentVariant(
          expState.experiments,
          PRICE_WATCH_PUSH_DEFAULT_OPT_IN
        ),
        [THEBES_HACKER_FARES_IN_CAP1]: getExperimentVariantCustomVariants(
          expState.experiments,
          THEBES_HACKER_FARES_IN_CAP1,
          VI_EXPERIMENT_VARIANTS
        ),
        [THEBES_VIRTUAL_INTERLINING_IN_CAP1]:
          getExperimentVariantCustomVariants(
            expState.experiments,
            THEBES_VIRTUAL_INTERLINING_IN_CAP1,
            VI_EXPERIMENT_VARIANTS
          ),
        [CFAR_MULTI_CITY]: getExperimentVariant(
          expState.experiments,
          CFAR_MULTI_CITY
        ),
        [PRICE_FREEZE_NEW_REVIEW_CTA]: getExperimentVariant(
          expState.experiments,
          PRICE_FREEZE_NEW_REVIEW_CTA
        ),
        [CORP_HIDE_PRICE_DROP_EXPERIMENT]: getExperimentVariant(
          expState.experiments,
          CORP_HIDE_PRICE_DROP_EXPERIMENT
        ),
        [HOTEL_CROSS_SELL_V3_EXPERIMENT]: getExperimentVariantCustomVariants(
          expState.experiments,
          HOTEL_CROSS_SELL_V3_EXPERIMENT,
          HOTEL_CROSS_SELL_V3_VARIANTS
        ),
      })
    );
    dispatch(
      setPriceFreezeExperiments({
        [PRICE_FREEZE_USER_SELECTED_DURATION]:
          getExperimentVariantCustomVariants(
            expState.experiments,
            PRICE_FREEZE_USER_SELECTED_DURATION,
            PRICE_FREEZE_USER_SELECTED_DURATION_VARIANTS
          ),
        [PRICE_FREEZE_USER_SELECTED_DURATION_COPY]:
          getExperimentVariantCustomVariants(
            expState.experiments,
            PRICE_FREEZE_USER_SELECTED_DURATION_COPY,
            PRICE_FREEZE_USER_SELECTED_DURATION_COPY_VARIANTS
          ),
        [PRICE_FREEZE_USER_SELECTED_DURATION_DISPLAY]:
          getExperimentVariantCustomVariants(
            expState.experiments,
            PRICE_FREEZE_USER_SELECTED_DURATION_DISPLAY,
            PRICE_FREEZE_USER_SELECTED_DURATION_DISPLAY_VARIANTS
          ),
        [PRICE_FREEZE_USER_SELECTED_DURATION_NO_SLIDER]:
          getExperimentVariantCustomVariants(
            expState.experiments,
            PRICE_FREEZE_USER_SELECTED_DURATION_NO_SLIDER,
            PRICE_FREEZE_USER_SELECTED_DURATION_NO_SLIDER_VARIANTS
          ),
        [PRICE_FREEZE_VOID_WINDOW]: getExperimentVariantCustomVariants(
          expState.experiments,
          PRICE_FREEZE_VOID_WINDOW,
          PRICE_FREEZE_VOID_WINDOW_VARIANTS
        ),
        [PRICE_FREEZE_DEFAULT_DURATIONS]: getExperimentVariantCustomVariants(
          expState.experiments,
          PRICE_FREEZE_DEFAULT_DURATIONS,
          PRICE_FREEZE_DEFAULT_DURATIONS_VARIANTS
        ),
        [PRICE_FREEZE_SHOW_DURATIONS]: getExperimentVariantCustomVariants(
          expState.experiments,
          PRICE_FREEZE_SHOW_DURATIONS,
          PRICE_FREEZE_SHOW_DURATIONS_VARIANTS
        ),
        [PRICE_FREEZE_TRAVEL_CREDITS]: getExperimentVariantCustomVariants(
          expState.experiments,
          PRICE_FREEZE_TRAVEL_CREDITS,
          DEFAULT_VARIANTS
        ),
        [AIR_PRICE_FREEZE_ONLY_FREEZE_QUOTED_PRICE]:
          getExperimentVariantCustomVariants(
            expState.experiments,
            AIR_PRICE_FREEZE_ONLY_FREEZE_QUOTED_PRICE,
            AIR_PRICE_FREEZE_ONLY_FREEZE_QUOTED_PRICE_VARIANTS
          ),
        [PRICE_FREEZE_PREDICTION_COPY]: getExperimentVariantCustomVariants(
          expState.experiments,
          PRICE_FREEZE_PREDICTION_COPY,
          DEFAULT_VARIANTS
        ),
        [PRICE_FREEZE_BACK_BUTTON_AIR]: getExperimentVariantCustomVariants(
          expState.experiments,
          PRICE_FREEZE_BACK_BUTTON_AIR,
          DEFAULT_VARIANTS
        ),
        [PRICE_FREEZE_FLIGHTS_LIST_EXPERIMENT]:
          getExperimentVariantCustomVariants(
            expState.experiments,
            PRICE_FREEZE_FLIGHTS_LIST_EXPERIMENT,
            PRICE_FREEZE_FLIGHTS_LIST_VARIANTS
          ),
      })
    );
    dispatch(
      setAncillaryExperiments({
        [CFAR]: getExperimentVariant(expState.experiments, CFAR),
        [CFAR_SOCIAL]: getExperimentVariantCustomVariants(
          expState.experiments,
          CFAR_SOCIAL,
          CFAR_SOCIAL_VARIANTS
        ),
        [REFUNDABLE_FARES]: getExperimentVariant(
          expState.experiments,
          REFUNDABLE_FARES
        ),
        [DISRUPTION]: getExperimentVariant(expState.experiments, DISRUPTION),
        [POST_BOOKING_OFFER_EXPERIMENT]: getExperimentVariantCustomVariants(
          expState.experiments,
          POST_BOOKING_OFFER_EXPERIMENT,
          POST_BOOKING_OFFER_VARIANTS
        ),
        [POST_BOOKING_OFFER_EXPERIMENT_REWARDS]:
          getExperimentVariantCustomVariants(
            expState.experiments,
            POST_BOOKING_OFFER_EXPERIMENT_REWARDS,
            POST_BOOKING_OFFER_REWARDS_VARIANTS
          ),
        [ANCILLARY_MARKETPLACE]: getExperimentVariantCustomVariants(
          expState.experiments,
          ANCILLARY_MARKETPLACE,
          ANCILLARY_MARKETPLACE_VARIANTS
        ),
        [PRICE_DROP_CREDIT]: getExperimentVariant(
          expState.experiments,
          PRICE_DROP_CREDIT
        ),
        [CAP_ONE_DISRUPTION_OPT_IN]: getExperimentVariantCustomVariants(
          expState.experiments,
          CAP_ONE_DISRUPTION_OPT_IN,
          CAP_ONE_DISRUPTION_OPT_IN_VARIANTS
        ),
        [NON_FDA_CAP_ONE_DISRUPTION_OPT_IN]: getExperimentVariant(
          expState.experiments,
          NON_FDA_CAP_ONE_DISRUPTION_OPT_IN
        ),
        [CFAR_DISCOUNT]: getExperimentVariantCustomVariants(
          expState.experiments,
          CFAR_DISCOUNT,
          CFAR_DISCOUNT_VARIANTS
        ),
        [AIR_OFFER_REDESIGN]: getExperimentVariantCustomVariants(
          expState.experiments,
          AIR_OFFER_REDESIGN,
          AIR_OFFER_REDESIGN_VARIANTS
        ),
        [CFAR_ANCILLARY_COMERCH]: getExperimentVariantCustomVariants(
          expState.experiments,
          CFAR_ANCILLARY_COMERCH,
          CFAR_COMERCH_VARIANTS
        ),
        [AIR_REFUNDABLE_FARE_COPY]: getExperimentVariant(
          expState.experiments,
          AIR_REFUNDABLE_FARE_COPY
        ),
        [CFAR_ENABLE_F9_NK_AIRLINE]: getExperimentVariantCustomVariants(
          expState.experiments,
          CFAR_ENABLE_F9_NK_AIRLINE,
          UC_DEFAULT_VARIANTS
        ),
        [FINTECH_CSAT]: getExperimentVariant(
          expState.experiments,
          FINTECH_CSAT
        ),
        [CFAR_OFFER_SHOW_REFUND_COPY_EXPERIMENT]:
          getExperimentVariantCustomVariants(
            expState.experiments,
            CFAR_OFFER_SHOW_REFUND_COPY_EXPERIMENT,
            CFAR_REFUND_COPY_VARIANTS
          ),
      })
    );
    dispatch(
      setChfarExperiments({
        [CHFAR]: getExperimentVariantCustomVariants(
          expState.experiments,
          CHFAR,
          CHFAR_VARIANTS
        ),
      })
    );
  }, [expState]);

  useEffect(() => {
    if (sessionInfo) {
      // We should fetch watches only when sessionInfo is populated with the latest response
      // of fetchUserInfo (if available) to avoid fetching the watches twice.
      if (fetchUserCompleted) {
        dispatch(listWatches());
      }

      if ("corporateInfo" in sessionInfo) {
        dispatch(setCorporateInfo(sessionInfo.corporateInfo));
      }
      dispatch(setIsFirstLaunch(sessionInfo.isFirstSession));
      if (sessionInfo.isDelegatedSession) {
        dispatch(setAgentEmail(sessionInfo.isDelegatedSession));
      }
    }
  }, [sessionInfo, dispatch, fetchUserCompleted]);

  useEffect(() => {
    dispatch(
      setAutoApproval({
        isAutoApprovalEnabled: Boolean(isAutoApprovalEnabled),
      })
    );
  }, [isAutoApprovalEnabled]);

  useMedalliaFeedback();

  return (
    <ErrorBoundary history={history}>
      <Box className="main-section">
        <Route path={[PATH_HOME, PATH_HOME_SEARCH]} exact>
          <FlightSearch />
        </Route>
        <Route
          path={PATH_SHOP}
          render={(routeProps) => {
            const { history: routeHistory } = routeProps;
            if (routeHistory === undefined) return null;
            const { flightShopType, tripCategory } =
              parseQueryString(routeHistory);

            /*
                                                  note: always use flightshop v1 for similar flights because similar-flight requires
                                                  further BE work to enable v2; see comment in https://hopper-jira.atlassian.net/browse/CFTC-149
                                                */
            if (flightShopType === FlightShopType.PRICE_FREEZE_EXERCISE) {
              return <FlightShop />;
            }
            if (tripCategory === TripCategory.MULTI_CITY) {
              return <MulticityFlightShop />;
            }
            return <FlightShopV2 />;
          }}
        />
        <Route path={PATH_BOOK}>
          <FlightBook />
        </Route>
        {isChFAREnabled && (
          <Route path={PATH_CHANGE_FOR_ANY_REASON}>
            <Suspense>
              <ChangeFlight />
            </Suspense>
          </Route>
        )}
        {!corpHidePriceFreeze && (
          <Route path={PATH_FREEZE}>
            <FlightFreeze />
          </Route>
        )}

        <Route path={PATH_ANCILLARY}>
          <FlightAncillary />
        </Route>
        <Route path={PATH_DISRUPTION_OFFER}>
          <PostBookingViewOffer />
        </Route>
      </Box>
      <TravelerWorkflowDialog
        tenant={config.TENANT}
        showHotelLoyaltySection
        disableDriversLicenseValidation
        disallowSelect
        displayModalSubtitle
        showAdditionalInfoSection
        showDriverLicenseSection
        showFrequentFlyerSection
        showGenderField
        showNationalityField
        buttonClassName="b2b"
        onAddPass={handleAddPass}
        dialogProps={{
          className: "flight-edit-traveler",
          onBackdropClick: closeEditTravelersModal,
          onClose: closeEditTravelersModal,
          open: editTravelersOpen === EditTravelerModalState.flight,
        }}
        errorMessage="Add or choose one traveler to continue."
        fullScreenWithBanner={matchesMobile}
        handleDeletePassenger={(personId: string) => {
          dispatch(deleteUserPassenger({ personId }));
        }}
        handleUpdatePassenger={(
          person: IPerson,
          hideProfileAction,
          entryPoint?: string,
          updatePassport?: boolean
        ) => {
          dispatch(
            updateUserPassenger(
              { person, hideProfileAction },
              false,
              updatePassport,
              false,
              false,
              defaultTravelerId,
              entryPoint
            )
          );

          const frequentFlyerProgramsTotal = Reflect.ownKeys(
            person.frequentFlyer
          ).length;

          !isCorpTenant(config.TENANT) &&
            trackEvent({
              eventName: SelfServeEvents.ClickSavedTravelersSaveTraveler,
              properties: {
                url: window.location.pathname,
                frequent_flyer_program_added: frequentFlyerProgramsTotal > 0,
                num_frequent_flyer_programs: frequentFlyerProgramsTotal,
              } satisfies SavedTravelersProperties,
            });
        }}
        isMobile={matchesMobile}
        travelers={travelers}
        titles={{}}
        dialogContentProps={{}}
        userRoles={userRoles}
        trackEvent={trackEvent}
        defaultTravelerId={defaultTravelerId}
        hasSeenNfuInfo={hasSeenNfuInfoModal}
        handleSeenNfuInfoModal={updateUserSeenModal}
      />
    </ErrorBoundary>
  );
};

export default App;
