export const REQUIRED_DETAILS_TEXT = "Enter all required details";
export const BOOK_BUTTON_TEXT = "Confirm and Book";
export const CONTACT_INFO_TITLE = "Step 2: Contact Information";
export const CONTACT_INFO_TITLE_NO_STEP = "Your Contact Information";
export const CONTACT_INFO_SUBTITLE =
  "This information is used to notify you about any updates or changes to your trip.";
export const CONTACT_INFO_SAVE = "Save Contact Information";
export const CONTACT_INFO_HELPER_TEXT =
  "You must add at least one traveler and enter your contact information to continue.";
export const REVIEW_MY_TRIP_TEXT = "Review my Trip";
export const SEAT_SELECTION_TITLE = (step: number) =>
  `Step ${step}: Seat Selection`;

export const CHECKOUT_PRICE_BREAKDOWN_HEADER = "Checkout Breakdown";
export const TRAVELER_INFO_TITLE = (step: number) =>
  `Step ${step}: Traveler Information`;

export const TREES_MODAL_TITLE = "How it works";
export const TREES_MODAL_SUBTITLE =
  "<b>Tree Planting Efforts:</b> Capital One, together with its partners, will donate to GlobalGiving’s fund for EarthLungs’ Reforestation Project enough to plant two (2) trees, $0.25, for every completed flight, hotel booking, car rental, or vacation rental booking made through Capital One Travel.  In the event that a booking is made, but later canceled and refunded to the customer, for any reason, such a payment will not be made. No portion of a customer’s Capital One Travel purchase is tax-deductible. <br>GlobalGiving is a 501(c)(3) nonprofit organization whose mission is to transform aid and philanthropy to accelerate community-led change. To learn more about GlobalGiving, visit <a href='www.globalgiving.org' target='_blank'>www.globalgiving.org</a>; to learn more about GlobalGiving’s nonprofit partner, EarthLungs Reforestation Project, visit <a href='http://www.earthlungsreforestation.org.' target='_blank'>www.earthlungsreforestation.org.";
export const TREES_MODAL_HEADER =
  "When you book, <span class='font-regular'>you plant trees.</span>";

export const TREES_MODAL_CTA_TEXT = "will be planted for this booking! ";
export const TREES_BOLDED_MODAL_CTA_TEXT = "2 trees ";

export const OPT_IN_APP_NOTIF_ID = "opt-in-app-notification";
export const OPT_IN_SMS_ID = "opt-in-sms";

export const CHFAR_IMPORTANT_INFORMATION_TEXT = "Important Information";
