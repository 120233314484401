import React, { useState, useCallback, useContext } from "react";
import { useSelector } from "react-redux";
import {
  FlightDetailsCard,
  MixedCabinToolTip,
  FareDetailsCardCtaTitles,
  useDeviceTypes,
  removeTimezone,
} from "halifax";
import {
  FareDetails,
  FiatPrice,
  FlightRatingsEnum,
  TripDetails,
} from "redmond";
import { Airport } from "@b2bportal/air-shopping-api";
import { getMultiCityPolicyLimit, useShowPolicyBanner } from "@capone/common";
import {
  COMBINATION_FLIGHT_TOOLTIP,
  COMBINATION_FLIGHT_WARNING,
  getMulticityReviewHeader,
} from "../../../../../components/FlightShopReviewItinerary/constants";
import * as textConstants from "../../../../../../search/components/FlightSearchFilter/components/FareclassOptionDetails/textConstants";
import {
  getPlusDays,
  isInDisruptionProtectionRebookSelector,
} from "../../../../../reducer";
import { Typography } from "@material-ui/core";
import { MobileFlightDetailsModal } from "halifax";
import clsx from "clsx";
import "./styles.scss";
import { getIsMixedClass } from "../../../../../constants";
import { ClientContext } from "../../../../../../../App";
import dayjs from "dayjs";
import { MulticityFlightShopStep } from "../../../../../reducer";

interface IFlightDetailsProps {
  multicityFlightShopProgress: MulticityFlightShopStep;
  selectedFareId: string;
  onFareClick: (
    sliceId: string,
    fare?: FareDetails,
    limit?: FiatPrice | null
  ) => void;
  onAlgomerchClick?: (label: string) => void;
  tripDetails: TripDetails;
  rewardsKey: string | undefined;
  airports: { [key: string]: Airport };
  outgoingFareRating?: number;
  openMobileFlightDetailsModal: boolean;
  setOpenMobileFlightDetailsModal: (val: boolean) => void;
  ctaTitles?: FareDetailsCardCtaTitles;
  showFareDetailsTitle?: boolean;
  isPriceHidden?: boolean;
  isFlightListOptimizationExperiment?: boolean;
  isSeatsUXOptimizationExperiment?: boolean;
  isSpiritOrFrontierAirlinesSelected: boolean;
  isAirCXV4Experiment: boolean;
}

enum ClickType {
  Purchase,
}

const getEmptyRestrictionsText = (fareRating: number | undefined) => {
  switch (fareRating) {
    case FlightRatingsEnum.basic:
      return textConstants.BASIC_DETAILS_TEXT;
    case FlightRatingsEnum.standard:
      return textConstants.STANDARD_DETAILS_TEXT;
    case FlightRatingsEnum.enhanced:
      return textConstants.ENHANCED_DETAILS_TEXT;
    case FlightRatingsEnum.premium:
      return textConstants.PREMIUM_DETAILS_TEXT;
    case FlightRatingsEnum.luxury:
      return textConstants.LUXURY_DETAILS_TEXT;
    default:
      return "";
  }
};

export const FlightDetails = ({
  tripDetails,
  multicityFlightShopProgress,
  selectedFareId,
  onFareClick,
  onAlgomerchClick,
  rewardsKey,
  airports,
  openMobileFlightDetailsModal,
  setOpenMobileFlightDetailsModal,
  ctaTitles,
  showFareDetailsTitle,
  isPriceHidden = false,
  isFlightListOptimizationExperiment = true,
  isSeatsUXOptimizationExperiment = false,
  isSpiritOrFrontierAirlinesSelected,
  isAirCXV4Experiment = false,
}: IFlightDetailsProps) => {
  const isInDisruptionProtectionRebook = useSelector(
    isInDisruptionProtectionRebookSelector
  );
  const clientContext = useContext(ClientContext);
  const { isAgentPortal, policies } = clientContext;
  const { matchesMobile } = useDeviceTypes();

  const [isMixedCabinClass, setIsMixedCabinClass] = useState(false);
  const [fareDetails, setFareDetails] = useState<FareDetails | undefined>(
    undefined
  );

  const [clickedFareId, setClickedFareId] = useState("");
  const [clickType, setClickType] = useState<ClickType>();

  React.useEffect(() => {
    if (selectedFareId || clickedFareId) {
      setFareDetails(
        tripDetails.fareDetails.find(
          (f) => f.id === selectedFareId || f.id === clickedFareId
        )
      );
    }
  }, [selectedFareId, clickedFareId]);

  React.useEffect(() => {
    if (fareDetails) {
      setIsMixedCabinClass(
        getIsMixedClass(fareDetails.slices[multicityFlightShopProgress])
      );
    }
  }, [fareDetails]);

  if (!tripDetails) return null;
  const slice = tripDetails.slices[multicityFlightShopProgress];

  const hackerFareNotice = tripDetails.fareDetails.map((fare) => {
    if (fare.multiTicket) {
      return {
        id: fare.id,
        message: COMBINATION_FLIGHT_WARNING,
        tooltipCopy: COMBINATION_FLIGHT_TOOLTIP,
      };
    } else {
      return null;
    }
  });

  const handleMobileFareClick = ({
    fareId,
    type,
  }: {
    fareId: string;
    type?: ClickType;
  }) => {
    setClickedFareId(fareId);
    setClickType(type);
    setOpenMobileFlightDetailsModal(true);
  };

  const handleMobileModalContinue = useCallback(() => {
    const sliceId = slice.id;
    const fare: FareDetails | undefined = tripDetails.fareDetails.find(
      (f) => f.id === clickedFareId
    );
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    onFareClick(sliceId, fare, policyLimit);
  }, [slice, tripDetails, clickedFareId, clickType, onFareClick]);

  const getFareClickHandler =
    (
      onClick: (
        sliceId: string,
        fare?: FareDetails,
        limit?: FiatPrice | null
      ) => void,
      type?: ClickType
    ) =>
    (fareId: string) => {
      if (matchesMobile) {
        handleMobileFareClick({ fareId, type });
      } else {
        onClick(
          slice.id,
          tripDetails.fareDetails.find((f) => f.id === fareId),
          policyLimit
        );
      }
    };

  const renderHeader = (header: string) => {
    const [fromHeader, dateHeader] = header.split("-");
    return (
      <Typography variant="body1" className="flight-details-header">
        <span className="from">{fromHeader}</span>
        <span className="date">{dateHeader}</span>
        {isMixedCabinClass && <MixedCabinToolTip />}
      </Typography>
    );
  };

  const getAirportFromCode = (code: string) => {
    return (
      airports[code] ||
      Object.values(airports).find((airport) => airport.servedCity === code)
    );
  };

  const currentSlice = tripDetails.slices[multicityFlightShopProgress];
  const originAirport = getAirportFromCode(currentSlice.originCode);
  const destinationAirport = getAirportFromCode(currentSlice.destinationCode);
  const header = getMulticityReviewHeader(
    multicityFlightShopProgress,
    originAirport,
    destinationAirport,
    dayjs(removeTimezone(currentSlice.departureTime)).toDate(),
    true
  );

  const policyLimit = getMultiCityPolicyLimit(
    policies?.flights,
    tripDetails.slices
  );

  const renderCardHeader = (header: string) => {
    const [fromHeader, dateHeader] = header.split("-");
    return (
      <>
        <span className="from">{fromHeader}</span>
        <span className="date">{dateHeader}</span>
      </>
    );
  };

  const showPolicyBanner = useShowPolicyBanner(policies);

  return (
    <>
      <FlightDetailsCard
        className="b2b"
        sortFares="total"
        isAgentPortal={isAgentPortal}
        selectedFareId={selectedFareId}
        tripDetails={tripDetails}
        isMulticity
        multicityFlightShopProgress={multicityFlightShopProgress}
        isOutgoing={false}
        getEmptyRestrictionsText={getEmptyRestrictionsText}
        onFareClick={getFareClickHandler(onFareClick, ClickType.Purchase)}
        fareNotice={hackerFareNotice}
        onAlgomerchClick={onAlgomerchClick}
        rewardsKey={rewardsKey}
        isMobile={matchesMobile}
        plusDays={getPlusDays(slice!)}
        header={renderHeader(
          getMulticityReviewHeader(
            multicityFlightShopProgress,
            originAirport,
            destinationAirport,
            dayjs(removeTimezone(currentSlice.departureTime)).toDate()
          )
        )}
        isMixedCabinClass={isMixedCabinClass}
        ctaTitles={ctaTitles}
        showFareDetailsTitle={showFareDetailsTitle}
        airlineProps={
          isInDisruptionProtectionRebook
            ? {
                WN: {
                  hideFlightDetailsSummaryBanner: true,
                  disableFareDetailsChanges: true,
                },
              }
            : undefined
        }
        isPriceHidden={isPriceHidden}
        isFlightListOptimizationExperiment={isFlightListOptimizationExperiment}
        isSeatsUXOptimizationExperiment={isSeatsUXOptimizationExperiment}
        isSpiritOrFrontierAirlinesSelected={isSpiritOrFrontierAirlinesSelected}
        showAirCXV4UI={isAirCXV4Experiment}
        policyLimit={policyLimit}
        hideIsInPolicy={!showPolicyBanner}
      />
      {matchesMobile && (
        <MobileFlightDetailsModal
          {...{ openModal: openMobileFlightDetailsModal, tripDetails }}
          isMulticity
          multicityTripSliceIndex={multicityFlightShopProgress}
          title={renderCardHeader(header)}
          fareDetails={tripDetails.fareDetails.find(
            (f) => f.id === clickedFareId
          )}
          className={clsx(
            "b2b",
            "b2b-secondary",
            "flight-shop-flight-details-popover"
          )}
          plusDays={slice ? getPlusDays(slice) : 0}
          onClose={() => {
            setOpenMobileFlightDetailsModal(false);
          }}
          onClick={handleMobileModalContinue}
          // TODO - set departure to appropriate value
          departure={true}
          isMixedCabinClass={isMixedCabinClass}
          airlineProps={
            isInDisruptionProtectionRebook
              ? {
                  WN: {
                    hideFlightDetailsSummaryBanner: true,
                    disableCallToBook: true,
                  },
                }
              : undefined
          }
          showAvailableSeats={isSeatsUXOptimizationExperiment}
        />
      )}
    </>
  );
};
