import { Box, Divider, Typography } from "@material-ui/core";
import {
  ActionButton,
  ActionLink,
  DesktopPopupModal,
  IconName,
  UserPreferencesBanner,
} from "halifax";
import { Link } from "react-router-dom";
import React, { useContext } from "react";
import {
  AirlineCode,
  Airport,
  CallState,
  FlightSortOption,
  IFlightNumberFilter,
  ITimeRange,
  ITripTerminus,
  RegionType,
  SliceStopCountFilter,
  TripCategory,
} from "redmond";
import clsx from "clsx";
import { isCorpTenant, useShowPolicyBanner } from "@capone/common";
import {
  StopsOptionSelection,
  AirlineFilterSelection,
  AirportFilterSelection,
  FlightNumberFilterSelection,
  MaxPriceFilterSelection,
  DepartureArrivalSelectionDropdown,
  FareclassOptionSelection,
  BaggageTransfersFilter,
  DurationSelection,
  PolicyFilterSelection,
} from "../../../../../search/components/FlightShopSearchControlV2/components/FlightShopSearchFilter/components";
import { IAirlineOptions, IShopFilterSelector } from "../../../../reducer";
import {
  AIRLINE_SELECTION_SUBTITLE,
  BAGGAGE_FILTER_LABEL,
  FILTER_MODAL_TITLES,
  FLIGHT_NUMBER_SELECTION_SUBTITLE,
} from "../../textConstants";
import { FareclassOptionFilter } from "../../../../../search/reducer";
import {
  AirCXV3VariantType,
  CONTROL,
} from "../../../../../../context/experiments";
import { PATH_CUSTOMER_PROFILE } from "../../../../../../utils/urlPaths";
import { config } from "../../../../../../api/config";
import "./styles.scss";
import { ClientContext } from "../../../../../../App";

export interface IDesktopFlightShopFilterProps {
  openAllFiltersModal: boolean;
  onCloseModal: () => void;
  sortOption: FlightSortOption;
  setSortOption: (option: FlightSortOption) => void;
  stopsOption: SliceStopCountFilter;
  setStopsOption: (stopsOption: SliceStopCountFilter) => void;
  setRerunPrediction: () => void;
  airlineFilter: AirlineCode[];
  setAirlineFilter: (airlineFilter: AirlineCode[]) => void;
  allAirlines: IAirlineOptions[];
  airportFilter: string[];
  setAirportFilter: (airportFilter: string[]) => void;
  flightNumberFilter: IFlightNumberFilter[];
  setFlightNumberFilter: (flightNumbers: IFlightNumberFilter[]) => void;
  departureDateString: string;
  maxPriceFilter: number;
  setMaxPriceFilter: (maxPrice: number) => void;
  optionsChanged: boolean;
  handleApply: () => void;
  flightShopFilters: IShopFilterSelector;
  isFlightListOptimizationExperiment?: boolean;
  tripCategory: TripCategory;
  outboundDepartureTimeRange: ITimeRange;
  outboundArrivalTimeRange: ITimeRange;
  returnDepartureTimeRange: ITimeRange;
  returnArrivalTimeRange: ITimeRange;
  setOutboundDepartureTimeRange: (
    outboundDepartureTimeRange: ITimeRange
  ) => void;
  setOutboundArrivalTimeRange: (outboundArrivalTimeRange: ITimeRange) => void;
  setReturnDepartureTimeRange: (returnDepartureTimeRange: ITimeRange) => void;
  setReturnArrivalTimeRange: (returnArrivalTimeRange: ITimeRange) => void;
  origin: ITripTerminus | null;
  destination: ITripTerminus | null;
  originAirport?: Airport;
  destinationAirport?: Airport;
  flightNumberAirlineFilter: string;
  setFlightNumberAirlineFilter: (airline: string) => void;
  fareclassOptionFilter: FareclassOptionFilter;
  setFareclassOptionFilter: (
    fareclassOptionFilter: FareclassOptionFilter
  ) => void;
  baggageTransfersFilter: boolean;
  setBaggageTransfersFilter: (value: boolean) => void;
  durationFilter: number;
  setDurationFilter: (duration: number) => void;
  handleReset: () => void;
  readyToReset: boolean;
  isThebesVirtualInterliningInCap1?: boolean;
  airCXV3Variant?: AirCXV3VariantType;
  minDuration: number;
  maxDuration: number;
  policyFilter: boolean;
  setPolicyFilter: (policyFilter: boolean) => void;
  filteredFlightCount: number;
  isCustomerProfileExperiment?: boolean;
  userHasSetFlightPreferences?: boolean;
  userFlightPreferencesCallState?: CallState;
  shouldApplyUserFlightPreferences: boolean;
  setApplyUserFlightPreferences: (applyPreferences: boolean) => void;
}

export const DesktopFlightShopFilter = (
  props: IDesktopFlightShopFilterProps
) => {
  const {
    openAllFiltersModal,
    onCloseModal,
    stopsOption,
    setStopsOption,
    setRerunPrediction,
    airlineFilter,
    setAirlineFilter,
    airportFilter,
    setAirportFilter,
    allAirlines,
    flightNumberFilter,
    setFlightNumberFilter,
    departureDateString,
    maxPriceFilter,
    setMaxPriceFilter,
    optionsChanged,
    handleApply,
    flightShopFilters,
    isFlightListOptimizationExperiment,
    tripCategory,
    outboundDepartureTimeRange,
    outboundArrivalTimeRange,
    returnDepartureTimeRange,
    returnArrivalTimeRange,
    setOutboundDepartureTimeRange,
    setOutboundArrivalTimeRange,
    setReturnDepartureTimeRange,
    setReturnArrivalTimeRange,
    origin,
    destination,
    originAirport,
    destinationAirport,
    flightNumberAirlineFilter,
    setFlightNumberAirlineFilter,
    fareclassOptionFilter,
    setFareclassOptionFilter,
    baggageTransfersFilter,
    setBaggageTransfersFilter,
    handleReset,
    readyToReset,
    isThebesVirtualInterliningInCap1,
    airCXV3Variant,
    durationFilter,
    setDurationFilter,
    minDuration,
    maxDuration,
    policyFilter,
    setPolicyFilter,
    filteredFlightCount,
    isCustomerProfileExperiment,
    userHasSetFlightPreferences = false,
    userFlightPreferencesCallState = CallState.NotCalled,
    shouldApplyUserFlightPreferences,
    setApplyUserFlightPreferences,
  } = props;

  const { airportOptions, flightNumbersByAirline, priceMax, priceMin } =
    flightShopFilters;

  const { policies } = useContext(ClientContext);
  const showPolicyFilter = useShowPolicyBanner(policies);

  return (
    <DesktopPopupModal
      open={openAllFiltersModal}
      onClose={onCloseModal}
      className={"desktop-flight-shop-all-filters-modal"}
      bottomButton={
        <Box
          className={
            "desktop-flight-shop-all-filters-modal-bottom-buttons-container"
          }
        >
          <ActionLink
            className={clsx("reset-filters-link", "filter-button", {
              inactive: !readyToReset,
            })}
            onClick={handleReset}
            content={FILTER_MODAL_TITLES.clearAllFiltersText}
          />
          <ActionButton
            className={clsx("apply-filters-button", "filter-button")}
            onClick={() => {
              handleApply();
              onCloseModal();
            }}
            defaultStyle="h4r-primary"
            disabled={!optionsChanged}
            message={FILTER_MODAL_TITLES.applyFiltersText(0)}
          />
        </Box>
      }
      invisibleBackdrop={false}
    >
      <Box className="desktop-flight-shop-all-filters-content-root">
        <Typography variant="h2" className="filters-title">
          All Filters
        </Typography>
        <Divider className="filter-divider" />
        {isCustomerProfileExperiment && userHasSetFlightPreferences && (
          <UserPreferencesBanner
            variant="modal"
            shouldApplyUserPreferences={shouldApplyUserFlightPreferences}
            setShouldApplyUserPreferences={setApplyUserFlightPreferences}
            userHasSetPreferences={userHasSetFlightPreferences}
            userPreferencesCallState={userFlightPreferencesCallState}
            bannerProfileCTA={
              <Link
                to={`${PATH_CUSTOMER_PROFILE}?section=flight-preferences`}
                className="profile-cta"
              >
                {userHasSetFlightPreferences
                  ? "Manage flight preferences"
                  : "Add flight preferences to your travel profile"}
              </Link>
            }
            type={"flight"}
          />
        )}
        <FareclassOptionSelection
          fareclassOptionFilter={fareclassOptionFilter}
          setFareclassOptionFilter={setFareclassOptionFilter}
          isFlightListOptimizationExperiment={
            isFlightListOptimizationExperiment
          }
          showDropdownContentOnly
          title={FILTER_MODAL_TITLES.fareclassText}
          icon={IconName.FareIcon}
        />
        <Divider className="filter-divider" />
        <StopsOptionSelection
          stopsOption={stopsOption}
          setStopsOption={setStopsOption}
          setRerunPrediction={
            airCXV3Variant === CONTROL ? setRerunPrediction : undefined
          }
          showDropdownContentOnly
          title={FILTER_MODAL_TITLES.numOfStopsText}
          icon={IconName.StopsFilterIcon}
          radioLabelPlacement="end"
          airCXV3Variant={airCXV3Variant}
        />
        <Divider className="filter-divider" />
        <AirlineFilterSelection
          allAirlines={allAirlines}
          airlineFilter={airlineFilter}
          setAirlineFilter={setAirlineFilter}
          showDropdownContentOnly
          title={FILTER_MODAL_TITLES.airlineText}
          icon={IconName.DiagonalBlueAirplane}
          optionLabelPlacement="start"
        />
        <Divider className="filter-divider" />
        {isThebesVirtualInterliningInCap1 && (
          <>
            <BaggageTransfersFilter
              title={FILTER_MODAL_TITLES.baggageTransfers}
              baggageTransfersFilter={baggageTransfersFilter}
              setBaggageTransfersFilter={setBaggageTransfersFilter}
              showDropdownContentOnly
              icon={IconName.BookTravel}
              label={BAGGAGE_FILTER_LABEL}
            />
            <Divider className="filter-divider" />
          </>
        )}
        <DepartureArrivalSelectionDropdown
          tripCategory={tripCategory}
          outboundDepartureTimeRange={outboundDepartureTimeRange}
          outboundArrivalTimeRange={outboundArrivalTimeRange}
          returnDepartureTimeRange={returnDepartureTimeRange}
          returnArrivalTimeRange={returnArrivalTimeRange}
          setOutboundDepartureTimeRange={setOutboundDepartureTimeRange}
          setOutboundArrivalTimeRange={setOutboundArrivalTimeRange}
          setReturnDepartureTimeRange={setReturnDepartureTimeRange}
          setReturnArrivalTimeRange={setReturnArrivalTimeRange}
          isFlightListOptimizationExperiment={
            isFlightListOptimizationExperiment
          }
          showDropdownContentOnly
          icon={IconName.ClockIconThin}
          outboundTitle={FILTER_MODAL_TITLES.outboundTimesText}
          returnTitle={FILTER_MODAL_TITLES.returnTimesText}
          origin={origin}
          destination={destination}
          originAirport={originAirport}
          destinationAirport={destinationAirport}
        />
        {isCorpTenant(config.TENANT) && showPolicyFilter && (
          <>
            <Divider className="filter-divider" />
            <PolicyFilterSelection
              policyFilter={policyFilter}
              setPolicyFilter={setPolicyFilter}
              showDropdownContentOnly
              title={FILTER_MODAL_TITLES.policyFilter}
              icon={IconName.DiagonalBlueAirplane}
            />
          </>
        )}
        <Divider className="filter-divider" />
        <MaxPriceFilterSelection
          maximumPrice={priceMax.value}
          minimumPrice={priceMin.value}
          maxPriceFilter={maxPriceFilter}
          setMaxPriceFilter={setMaxPriceFilter}
          title={FILTER_MODAL_TITLES.priceRangeText}
          subtitle={FILTER_MODAL_TITLES.priceRangeSubtitleText}
          icon={IconName.MoneyOutlineThin}
          showDropdownContentOnly
          isFlightListOptimizationExperiment={
            isFlightListOptimizationExperiment
          }
        />
        {airCXV3Variant && airCXV3Variant !== CONTROL && (
          <>
            <Divider className="filter-divider" />
            <DurationSelection
              value={durationFilter}
              setValue={setDurationFilter}
              minValue={minDuration}
              maxValue={maxDuration}
              flightCount={filteredFlightCount}
              showDropdownContentOnly
            />
          </>
        )}
        {origin?.id.code.regionType !== RegionType.Airport && (
          <>
            <Divider className="filter-divider" />
            <AirportFilterSelection
              allAirports={airportOptions}
              airportFilter={airportFilter}
              setAirportFilter={setAirportFilter}
              showDropdownContentOnly
              title={FILTER_MODAL_TITLES.outboundAirportText}
              icon={IconName.DiagonalBlueAirplane}
              optionLabelPlacement="start"
              displayLabelAndValue
            />
          </>
        )}
        <Divider className="filter-divider" />
        <FlightNumberFilterSelection
          showDropdownContentOnly
          allAirlines={allAirlines}
          flightNumbersByAirline={flightNumbersByAirline}
          flightNumberFilter={flightNumberFilter}
          departureDateString={departureDateString}
          setFlightNumberFilter={setFlightNumberFilter}
          useAirlineCheckbox
          title={FILTER_MODAL_TITLES.flightNumberText}
          icon={IconName.DiagonalBlueAirplane}
          flightNumberAirlineFilter={flightNumberAirlineFilter}
          setFlightNumberAirlineFilter={setFlightNumberAirlineFilter}
          airlineSubtitle={AIRLINE_SELECTION_SUBTITLE}
          flightNumberSubtitle={FLIGHT_NUMBER_SELECTION_SUBTITLE}
        />
      </Box>
    </DesktopPopupModal>
  );
};
