import { connect, ConnectedProps } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import { withRouter } from "react-router";

import { IStoreState } from "../../../../../../reducers/types";
import {
  getHasSetAirlineFilter,
  getHasSetAirportFilter,
  getHasSetFlightNumberFilter,
  getHasSetOutboundTimeRange,
  getHasSetReturnTimeRange,
  getHasSetStopsOption,
  initialFilterOptions,
  MobileFlightSearchStep,
  getHasSetPolicyFilter,
} from "../../../../../search/reducer";
import {
  flightShopTypeSelector,
  hasSetMaxPriceFilterSelectorV2,
  maxFlightPriceSelectorV2,
} from "../../../../../shop/reducer/selectors";
import { actions } from "../../../../../search/actions";
import { FlightFindMoreResults } from "./component";
import { setOpenFlightShopCalendarDesktop } from "../../../../actions/actions";
import { FlightShopType } from "redmond";

interface IStateProps {
  data: { maxFlightPrice: number };
  filters: { [key: string]: boolean };
  flightShopType: FlightShopType;
}

const mapStateToProps = (state: IStoreState): IStateProps => {
  return {
    data: {
      maxFlightPrice: maxFlightPriceSelectorV2(state),
    },
    filters: {
      stops: getHasSetStopsOption(state),
      airline: getHasSetAirlineFilter(state),
      price: hasSetMaxPriceFilterSelectorV2(state),
      departure: getHasSetOutboundTimeRange(state),
      return: getHasSetReturnTimeRange(state),
      airport: getHasSetAirportFilter(state),
      flightNumber: getHasSetFlightNumberFilter(state),
      policy: getHasSetPolicyFilter(state),
    },
    flightShopType: flightShopTypeSelector(state),
  };
};

interface IDispatchProps {
  setMobileSearchProgress: (
    step: MobileFlightSearchStep
  ) => actions.ISetMobileSearchProgress;
  setOpenFlightShopCalendarDesktop: (val: boolean) => void;
  resetFilters: () => void;
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
  return {
    setMobileSearchProgress: actions.setMobileSearchProgress,
    setOpenFlightShopCalendarDesktop: (val: boolean) => {
      dispatch(setOpenFlightShopCalendarDesktop(val));
    },
    resetFilters: () => {
      dispatch(actions.setStopsOption(initialFilterOptions.stopsOption));
      dispatch(actions.setAirlineFilter(initialFilterOptions.airlineFilter));
      dispatch(actions.setMaxPriceFilter(initialFilterOptions.maxPriceFilter));
      dispatch(actions.setAirportFilter(initialFilterOptions.airportFilter));
      dispatch(
        actions.setFlightNumberFilter(initialFilterOptions.flightNumberFilter)
      );
      dispatch(actions.setPolicyFilter(initialFilterOptions.policyFilter));
      dispatch(
        actions.setOutboundArrivalTimeRange(
          initialFilterOptions.outboundArrivalTimeRange
        )
      );
      dispatch(
        actions.setOutboundDepartureTimeRange(
          initialFilterOptions.outboundDepartureTimeRange
        )
      );
      dispatch(
        actions.setReturnDepartureTimeRange(
          initialFilterOptions.returnDepartureTimeRange
        )
      );
      dispatch(
        actions.setReturnArrivalTimeRange(
          initialFilterOptions.returnArrivalTimeRange
        )
      );
      dispatch(
        actions.setFareclassOptionFilter(
          initialFilterOptions.fareclassOptionFilter
        )
      );
      dispatch(actions.setApplyUserFlightPreferences(false));
      dispatch(actions.setUserPreferencesNotAvailable(false));
    },
  };
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
    resetFilters: () => dispatchProps.resetFilters(),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps, mergeProps);

export type FlightFindMoreResultsConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedFlightFindMoreResults = connector(
  withRouter(FlightFindMoreResults)
);
