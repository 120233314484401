import { put, select } from "redux-saga/effects";
import {
  OffersForProductsRequest,
  WalletOffersResponse,
  AvailableProductEnum,
  TripType,
  RegionType,
  WalletSummaryResponse,
  ProductClassEnum,
} from "redmond";
import dayjs from "dayjs";
import { max } from "lodash-es";
import { actions } from "../../actions";
import {
  selectedTripSelector,
  tripDetailsSelector,
} from "../../../shop/reducer/selectors";
import { IStoreState } from "../../../../reducers/types";
import { fetchTravelWalletOffers } from "../../../../api/v0/travel-wallet/fetchTravelWalletOffers";
import { getSliceIndex } from "../../../../utils/flights";
import { fetchTravelWalletSummary } from "../../../../api/v0/travel-wallet/fetchTravelWalletSummary";
import { getPricing } from "../../reducer";

export function* fetchApplicableTravelWalletItemsSaga() {
  try {
    const state: IStoreState = yield select();
    const selectedTrip = selectedTripSelector(state);
    const tripId = selectedTrip.tripId || "";
    const tripDetails = tripDetailsSelector(state, tripId);

    const departureSlice = tripDetails.slices[0];
    const returnSlice = tripDetails.slices[tripDetails.slices.length - 1];

    const hasReturnFlight = getSliceIndex(false, tripDetails) !== -1;

    const departureSegments = departureSlice.segmentDetails;

    const { departureTime } = departureSlice;
    const returnTime = returnSlice.arrivalTime;

    const marketingCarriers = tripDetails.slices
      .flatMap((slice) => slice.segmentDetails)
      .map((segment) => segment.marketingAirline.code);
    const operatingCarriers = tripDetails.slices
      .flatMap((slice) => slice.segmentDetails)
      .map((segment) => segment.operatingAirline.code);

    const pricing = getPricing(state);

    const products: OffersForProductsRequest = {
      products: [
        {
          AvailableProduct: AvailableProductEnum.Air,
          advance: dayjs(departureTime).diff(dayjs(), "days"),
          canBeDiscounted: true,
          currency: pricing?.totalPricing.currency || "USD",
          departureDate: dayjs(departureTime).format("YYYY-MM-DD"),
          includesSaturdayNightStay: false,
          lengthOfStay: dayjs(returnTime).diff(departureTime, "days"),
          marketingCarriers,
          operatingCarriers,
          returnDate: hasReturnFlight
            ? dayjs(returnTime).format("YYYY-MM-DD")
            : undefined,
          route: {
            destination: {
              code: departureSegments[departureSegments.length - 1]
                .destinationCode,
              regionType: RegionType.Airport,
            },
            origin: {
              code: departureSegments[0].originCode,
              regionType: RegionType.Airport,
            },
          },
          shelfRatings: tripDetails.fareDetails
            .flatMap((fare) => fare.slices)
            .map((slice) => slice.fareShelf?.rating ?? 0),
          totalStops: tripDetails.slices.reduce(
            (total, current) => total + current.stops,
            0
          ),
          tripId,
          tripType: hasReturnFlight ? TripType.RoundTrip : TripType.OneWay,
          validatingCarriers: tripDetails.validatingCarrierCode
            ? [tripDetails.validatingCarrierCode]
            : [],
          belongsToPackage: false,
          // reference: https://github.com/hopper-org/flights-common/blob/13234f14ca3f98791cf16edaf292a93e2cc1070b/flights-air/src/main/scala/com/hopper/air/book/OfferHelpers.scala#L92-L116
          productPricing: {
            productClass: (() => {
              // reference: https://github.com/hopper-org/Helpers/blob/059478afcac29ca6546c11f03510fe108bde39b6/helpers-model/src/main/scala/com/hopper/common/model/api/Offers.scala#L1282-L1301
              switch (
                max(
                  tripDetails.fareDetails
                    .flatMap((fare) => fare.slices)
                    .map((slice) => slice.fareShelf?.rating)
                )
              ) {
                case 0:
                  return ProductClassEnum.Basic;
                case 1:
                case 2:
                  return ProductClassEnum.Economy;
                case 3:
                case 4:
                case 5:
                  return ProductClassEnum.Premium;
                default:
                  return ProductClassEnum.Economy;
              }
            })(),
            currency: pricing?.totalPricing.currency || "USD",
            basePrice: pricing?.totalPricing.baseWithoutMargin.fiat.value || 0,
            grossBookValue: pricing?.totalPricing.total.fiat.value || 0,
            ancillaryTotal: 0,
            preDiscountTotal: pricing?.totalPricing.total.fiat.value || 0,
            preDiscountGrandTotal: pricing?.totalPricing.total.fiat.value || 0,
            sellPrice: pricing?.totalPricing.total.fiat.value || 0,
          },
        },
      ],
    };

    const response: WalletOffersResponse = yield fetchTravelWalletOffers(
      products
    );

    const summaryResponse: WalletSummaryResponse =
      yield fetchTravelWalletSummary();

    yield put(actions.setTravelWalletOffers(response.rankedApplicableOffers));
    yield put(actions.setBestOfferOverall(response.rankedApplicableOffers[0]));
    yield put(actions.setTravelWalletCredit(summaryResponse.credit));
    yield put(actions.setFetchApplicableTravelWalletItemsCallStateSuccess());
  } catch (e) {
    yield put(actions.setFetchApplicableTravelWalletItemsCallStateFailure());
  }
}
