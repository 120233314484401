import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  PATH_CHANGE_FOR_ANY_REASON_OVERVIEW,
  PATH_TRIPS,
} from "../../../utils/urlPaths";
import {
  setAirlineFilter,
  setAirportFilter,
  setFareclassOptionFilter,
  setFlightNumberFilter,
  setMaxPriceFilter,
  setOutboundArrivalTimeRange,
  setOutboundDepartureTimeRange,
  setPolicyFilter,
  setReturnArrivalTimeRange,
  setReturnDepartureTimeRange,
  setStopsOption,
} from "../../search/actions/actions";
import { initialFilterOptions } from "../../search/reducer";
import { useItineraryIdParam } from "./useItineraryIdParam";

export const useNavigateToChfar = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const itineraryId = useItineraryIdParam();

  const resetFilters = useCallback(() => {
    dispatch(setStopsOption(initialFilterOptions.stopsOption));
    dispatch(setAirlineFilter(initialFilterOptions.airlineFilter));
    dispatch(
      setFareclassOptionFilter({
        basic: false,
        standard: false,
        enhanced: false,
        premium: false,
        luxury: false,
      })
    );
    dispatch(setMaxPriceFilter(initialFilterOptions.maxPriceFilter));
    dispatch(setAirportFilter(initialFilterOptions.airportFilter));
    dispatch(setFlightNumberFilter(initialFilterOptions.flightNumberFilter));
    dispatch(
      setOutboundArrivalTimeRange(initialFilterOptions.outboundArrivalTimeRange)
    );
    dispatch(
      setOutboundDepartureTimeRange(
        initialFilterOptions.outboundDepartureTimeRange
      )
    );
    dispatch(
      setReturnDepartureTimeRange(initialFilterOptions.returnDepartureTimeRange)
    );
    dispatch(
      setReturnArrivalTimeRange(initialFilterOptions.returnArrivalTimeRange)
    );
    dispatch(setPolicyFilter(initialFilterOptions.policyFilter));
  }, [dispatch]);

  return useCallback(() => {
    resetFilters();
    if (itineraryId) {
      history.push(
        `${PATH_CHANGE_FOR_ANY_REASON_OVERVIEW}?itineraryId=${itineraryId}`
      );
    } else {
      history.push(PATH_TRIPS);
    }
  }, [itineraryId, resetFilters, history]);
};
