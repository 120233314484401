import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Box, Typography } from "@material-ui/core";
import { AirlineIcon, B2BTextField } from "halifax";
import { FrequentFlyerMap, ICorpPerson, IFlyerOption, IPerson } from "redmond";
import "./styles.scss";

export interface FrequentFlyerFormProps {
  isMobile?: boolean;
  onChange: (flyerMap: FrequentFlyerMap) => void;
  disabled?: boolean;
  traveler: IPerson | ICorpPerson;
  frequentFlyerPrograms: IFlyerOption[];
}

export const getFrequentFlyerNumberForProgram = (
  program: string,
  traveler: IPerson | undefined
) => {
  if (!traveler) {
    return;
  }

  return traveler?.frequentFlyer[program]?.value;
};

const getFormStateFromTraveler = (
  frequentFlyerPrograms: IFlyerOption[],
  traveler: IPerson | ICorpPerson
) =>
  frequentFlyerPrograms.reduce((map, program) => {
    map[program.value] = {
      value: getFrequentFlyerNumberForProgram(program.value, traveler) || "",
    };
    return map;
  }, {} as { [airline: string]: { value: string } });

const SUBTITLE = (count: number) =>
  count > 1
    ? "Please enter this frequent flyer number carefully."
    : "Please enter frequent flyer numbers carefully.";

export const FrequentFlyerForm = ({
  isMobile,
  onChange,
  disabled,
  traveler,
  frequentFlyerPrograms,
}: FrequentFlyerFormProps) => {
  const [formState, setFormState] = useState<FrequentFlyerMap>(() =>
    getFormStateFromTraveler(frequentFlyerPrograms, traveler)
  );

  const handleChange = (airline: string, value: string) => {
    const newFormState = {
      ...formState,
      [airline]: {
        value,
      },
    };
    setFormState(newFormState);
    onChange(newFormState);
  };

  useEffect(() => {
    setFormState(getFormStateFromTraveler(frequentFlyerPrograms, traveler));
  }, [traveler]);

  return (
    <>
      <Box
        className={clsx("frequent-flyer-form-container", {
          mobile: isMobile,
        })}
      >
        <Box className="frequent-flyer-form-description">
          <label htmlFor="frequent-flyer-input-field">
            <Typography className="title" component="span">
              Frequent flyer program
            </Typography>
          </label>
          <Typography className="subtitle" variant="body2">
            {SUBTITLE(frequentFlyerPrograms.length)}
          </Typography>
        </Box>
        <div className="frequent-flyer-form">
          {frequentFlyerPrograms.map(
            ({ program, airline, value: airlineCode }) => {
              return (
                <B2BTextField
                  value={formState[airlineCode].value}
                  disabled={disabled}
                  width="100%"
                  className="frequent-flyer-input-field"
                  label={
                    <>
                      <AirlineIcon airlineCode={airlineCode} altText="" />
                      <Typography variant="body2">
                        <strong>{airline}</strong> - {program} (Optional)
                      </Typography>
                    </>
                  }
                  onChange={(v) => handleChange(airlineCode, v)}
                  type="text"
                  id="frequent-flyer-input-field"
                />
              );
            }
          )}
        </div>
      </Box>
    </>
  );
};
