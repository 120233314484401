import React, { useState, useEffect, useMemo, useContext } from "react";
import { Box, Button, Typography } from "@material-ui/core";
import clsx from "clsx";
import {
  Airport,
  Cap1DpExerciseFactsProperties,
  DISRUPTION_REDEEM_CHOICE_REFUND,
  ISortOptions,
  ITripTerminus,
  ModalScreens,
  POLICY_MODAL,
  RegionType,
  VIEWED_POLICY_MODAL,
  TripCategory,
  FlightShopType,
} from "redmond";
import {
  Icon,
  IconName,
  GenericFlightSummary,
  useFocusRef,
  getTimeWithUtcOffset,
  ActionButton,
  TravelSalesEventBanner,
  useDeviceTypes,
  PolicyModalButton,
  PolicyDetailsModal,
} from "halifax";
import dayjs from "dayjs";
import H from "history";
import { useInView } from "react-intersection-observer";
import { useExperimentIsVariant } from "@capone/experiments";
import { isCorpTenant } from "@capone/common";
import "./styles.scss";
import { FlightShopStep, MulticityFlightShopStep } from "../../reducer";
import { FlightShopHeaderConnectorProps } from "./container";
import { FlightShopProgressBar, FlightShopSummaryPanel } from "../index";
import { SortOptionSelection } from "./components/SortOptionSelection";
import { FareClassOptionSelection } from "./components/FareClassOptionSelection";
import { DesktopPricePrediction } from "./components/DesktopPricePrediction";
import { OriginalPriceFreezeInfo } from "../OriginalPriceFreezeInfo";
import {
  RebookBanner,
  RefundableFareOptionsBanner,
} from "../../../ancillary/components";
import { usePrevious } from "../../../../hooks/usePrevious";
import {
  FLIGHT_SHOP_REBOOK_LIST_TITLE_TEXT,
  TRAVEL_SALES_EVENT_ACTIVE_CTA,
  TRAVEL_SALES_EVENT_ACTIVE_SUBTITLE,
} from "../../constants";
import * as constants from "./constants";
import { pushToDisruptionOverview } from "../../../ancillary/utils";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import {
  IFlightShopParsedQuery,
  parseQueryString,
} from "../../utils/parseQueryString";
import { FlightShopSearchFilter } from "../../../search/components/FlightShopSearchControlV2/components";
import { useFilterLabelValues } from "../../../search/components/FlightShopSearchControl/useFilterLabelValues";
import { FlightShopFilter } from "../FlightShopFilter";
import { MulticityFlightShopProgressBar } from "../MulticityFlightShopProgressBar";
import { MulticityRoute } from "../../../search/reducer";
import {
  airCXV3SortOptionsToDisplay,
  defaultSortOptionsToDisplay,
} from "../../../../utils/sortAndFilter";
import { PATH_TRAVEL_SALE } from "../../../../utils/urlPaths";
import {
  IVirtualInterliningVariant,
  VirtualInterliningModal,
} from "../VirtualIinterliningModal";
import { MultipleAirlinesFareModal } from "../MultipleAirlinesFareModal";
import { AirCXV3VariantType, CONTROL } from "../../../../context/experiments";
import { ClientContext } from "../../../../App";
import { config } from "../../../../api/config";
import { FlightShopVisibility } from "../../v2/components/FlightShopVisibility";
import { PreviousFlightBanner } from "../../../change-for-any-reason/components/PreviousFlightBanner";
import { ChfarMissionControlEditLink } from "../../../change-for-any-reason/components/ChfarMissionControlEditLink";

export interface IFlightShopHeaderProps extends FlightShopHeaderConnectorProps {
  isMobile: boolean;
  airports: { [key: string]: Airport };
  history: H.History;
  isMediumDesktop?: boolean;
  isInPriceFreezePurchase?: boolean;
  isInSimilarFlights?: boolean;
  useLockPriceLanguage?: boolean;
  useCustomizeStep?: boolean;
  isFlightListOptimizationExperiment?: boolean;
  sortOptions?: ISortOptions[];
  isTravelSalesEventActive?: boolean;
  airCXV3Variant?: AirCXV3VariantType;
  isAirCXV3Experiment?: boolean;
  showTravelWalletBanner?: boolean;
  isAirCXV4Experiment?: boolean;
  chfarFlightShopSearchControl?: JSX.Element;
}

export const disruptionProtectionRefundEntryPoint = (history: H.History) => {
  const { productRedeemChoice, activeDisruption } = parseQueryString(
    history
  ) as IFlightShopParsedQuery;
  return (
    <Box className="refund-entrypoint">
      <Box className="refund-entrypoint-text">
        <Box className="refund-entrypoint-header">
          {constants.REFUND_ENTRYPOINT_HEADER}
        </Box>
        <p className="refund-entrypoint-subheader">
          {constants.REFUND_ENTRYPOINT_SUBHEADER}
        </p>
      </Box>
      <Button
        onClick={() => {
          pushToDisruptionOverview({
            history,
            page: "refund",
          });
          trackEvent({
            eventName: DISRUPTION_REDEEM_CHOICE_REFUND,
            properties: {
              active_disruption: activeDisruption ?? "none",
              product_redeem_choice: productRedeemChoice,
              page: "rebook-flight-list",
            } as Cap1DpExerciseFactsProperties,
          });
        }}
      >
        {constants.REFUND_ENTRYPOINT_BUTTON}
      </Button>
    </Box>
  );
};

const getScrollbarWidth = function () {
  const outer = document.createElement("div");
  outer.style.visibility = "hidden";
  outer.style.width = "100px";
  document.body.appendChild(outer);

  const widthNoScroll = outer.offsetWidth;
  outer.style.overflow = "scroll";

  const inner = document.createElement("div");
  inner.style.width = "100%";
  outer.appendChild(inner);

  const widthWithScroll = inner.offsetWidth;
  outer.parentNode?.removeChild(outer);

  return widthNoScroll - widthWithScroll;
};

export const FlightShopHeader = (props: IFlightShopHeaderProps) => {
  const {
    history,
    currentStep,
    currentMulticityStep,
    multicityRoutes,
    tripCategory,
    destination,
    origin,
    hasChosenDepartureFlight,
    isMobile,
    airports,
    isMediumDesktop,
    awaitingRefetch,
    tripSummariesLoading,
    isInPriceFreezePurchase,
    isInSimilarFlights,
    useLockPriceLanguage,
    useCustomizeStep,
    isInDisruptionProtectionRebook,
    isPartiallyRebooking,
    isSelectingReturnFlight,
    hasActiveRefundableFare,
    originalSliceFlightSummaryProps,
    isPartiallyRebookingNotificationProps,
    rebookDepartureDateTime,
    isFlightListOptimizationExperiment = false,
    airportMap,
    departureDate,
    returnDate,
    stopsOption,
    airlineFilter,
    hasSetOutboundTimeRange,
    hasSetReturnTimeRange,
    maxPriceFilter,
    airlines,
    sortOption,
    setSortOption,
    fareclassFilter,
    airCXV3Variant,
    sortOptions = airCXV3Variant !== CONTROL
      ? airCXV3SortOptionsToDisplay
      : defaultSortOptionsToDisplay,
    productRedeemChoice,
    isTravelSalesEventActive,
    durationFilter,
    activeFiltersCount,
    showTravelWalletBanner,
    tripDetails,
    policyFilter,
    isAirCXV4Experiment,
    chfarFlightShopSearchControl = null,
  } = props;

  const isAirCXV3Experiment = airCXV3Variant !== CONTROL;

  const initialFocusRef = useFocusRef([]);
  const [openAllFiltersModal, setOpenAllFiltersModal] = useState(false);

  const { matchesDesktop, matchesLargeDesktop } = useDeviceTypes();
  const matchesMediumDesktopOnly = matchesDesktop && !matchesLargeDesktop;

  const fixedShelfOffset = React.useMemo(
    () =>
      (document
        .querySelector(".b2b-portal-banner-root")
        ?.getBoundingClientRect().height || 0) +
      (document.querySelector(".app-header")?.getBoundingClientRect().height ||
        0) +
      (showTravelWalletBanner
        ? document
            .querySelector(".travel-wallet-offer-banner-root")
            ?.getBoundingClientRect().height || 0
        : 0),
    [showTravelWalletBanner]
  );

  const [
    fixedFareClassShelfPlaceholderRef,
    fixedFareClassShelfPlaceholderInView,
  ] = useInView({
    threshold: 1,
    rootMargin: `-${fixedShelfOffset}px 0px 0px 0px`,
  });

  const [fixedFareClassShelfRef, fixedFareClassShelfInView] = useInView();

  const showFixedShelf =
    !!isAirCXV3Experiment &&
    !matchesMediumDesktopOnly &&
    fixedFareClassShelfInView &&
    !fixedFareClassShelfPlaceholderInView;

  const isOneWay = useMemo(
    () => tripCategory === TripCategory.ONE_WAY,
    [tripCategory]
  );
  const isMulticity = useMemo(
    () => tripCategory === TripCategory.MULTI_CITY,
    [tripCategory]
  );

  const isInChooseMulticityDeparturesSteps =
    isMulticity &&
    [
      MulticityFlightShopStep.ChooseDeparture0,
      MulticityFlightShopStep.ChooseDeparture1,
      MulticityFlightShopStep.ChooseDeparture2,
      MulticityFlightShopStep.ChooseDeparture3,
      MulticityFlightShopStep.ChooseDeparture4,
    ].includes(currentMulticityStep);

  const isInMulticityReviewStep =
    isMulticity &&
    currentMulticityStep === MulticityFlightShopStep.ReviewItinerary;

  const isInPriceFreezeVariant = isInPriceFreezePurchase || isInSimilarFlights;

  // Virtual Interline Flights will offer only one type of fare the most basic is safe to only check the first index
  const isVITripSelected = tripDetails?.fareDetails[0].slices.some((slice) =>
    slice.fareDetails.segments.some((segment) => segment.isSelfTransferLayover)
  );

  // Follows patterns established elsewhere, but the flag is not (yet) needed in this context.
  const [openVIVariantModal, setOpenVIVariantModal] = useState<
    IVirtualInterliningVariant | "reminder" | false
  >(false);

  // Follows patterns established elsewhere, but the flag is not (yet) needed in this context.
  const [openMultipleAirlinesFares, setOpenMultipleAirlinesFares] =
    useState(false);

  const getCurrentStepFlightListTitle = () => {
    if (!airportMap) return "";
    let originAirport, destinationAirport;

    if (isMulticity) {
      const currentRoute = multicityRoutes[currentMulticityStep];
      const routeOrigin = currentRoute.origin;
      const routeDestination = currentRoute.destination;
      if (!routeOrigin || !routeDestination) return "";
      const originCode = routeOrigin.id.code.code;
      const destinationCode = routeDestination.id.code.code;
      originAirport = originCode ? airportMap[originCode] : undefined;
      destinationAirport = destinationCode
        ? airportMap[destinationCode]
        : undefined;
      return constants.FLIGHT_LIST_TITLE(
        originAirport,
        destinationAirport,
        routeOrigin,
        routeDestination
      );
    } else {
      const isInDepartureStep = currentStep === FlightShopStep.ChooseDeparture;
      originAirport = origin ? airportMap[origin?.id.code.code] : undefined;
      destinationAirport = destination
        ? airportMap[destination?.id.code.code]
        : undefined;

      return constants.FLIGHT_LIST_TITLE(
        isInDepartureStep ? originAirport : destinationAirport,
        isInDepartureStep ? destinationAirport : originAirport,
        (isInDepartureStep ? origin : destination) || undefined,
        (isInDepartureStep ? destination : origin) || undefined
      );
    }
  };

  const getCurrentStepFlightDate = () => {
    if (isMulticity) {
      return dayjs(multicityRoutes[currentMulticityStep].departureDate).format(
        "ddd, MMM D"
      );
    } else {
      return currentStep === FlightShopStep.ChooseDeparture
        ? dayjs(departureDate).format("ddd, MMM D")
        : dayjs(returnDate).format("ddd, MMM D");
    }
  };

  const filterLabelValues = useFilterLabelValues({
    stopsOption,
    airlineFilter,
    hasSetOutboundTimeRange,
    hasSetReturnTimeRange,
    maxPriceFilter,
    airlines,
    fareclassFilter,
    durationFilter,
    policyFilter,
  });

  const HeaderText = () => {
    let headerText = "";
    let subheaderText = "";

    const prevDestination = usePrevious(destination);
    const prevOrigin = usePrevious(origin);

    const [destinationLabel, setDestinationLabel] = useState("");
    const [originLabel, setOriginLabel] = useState("");

    useEffect(() => {
      setDestinationLabel(
        awaitingRefetch && prevDestination
          ? prevDestination.label
          : destination?.label || ""
      );
      setOriginLabel(
        awaitingRefetch && prevOrigin ? prevOrigin.label : origin?.label || ""
      );
    }, [awaitingRefetch, destination, origin]);

    // note: FlightShopHeader is only supposed to be rendered on outgoing / returning flight and review itinerary steps.
    if (
      isMobile &&
      (currentStep === FlightShopStep.FareDetails ||
        currentStep === FlightShopStep.Customize)
    ) {
      return null;
    }

    if (isMulticity) {
      const currentRoute = multicityRoutes[
        currentMulticityStep
      ] as MulticityRoute;
      const originCode = currentRoute.origin?.id.code.code;
      const destinationCode = currentRoute.destination?.id.code.code;
      const originAirport = originCode
        ? airportMap && airportMap[originCode]
        : undefined;
      const destinationAirport = destinationCode
        ? airportMap && airportMap[destinationCode]
        : undefined;
      headerText = constants.MOBILE_MULTICITY_FLIGHT_LIST_TITLE(
        originAirport,
        destinationAirport,
        currentRoute.origin as ITripTerminus,
        currentRoute.destination as ITripTerminus
      );
      subheaderText = constants.MOBILE_MULTICITY_FLIGHT_LIST_DATE(
        currentRoute.departureDate as Date
      );
    } else {
      switch (currentStep) {
        case FlightShopStep.ChooseDeparture:
          headerText = constants.getChooseFlightHeaderText(
            true,
            destinationLabel
          );
          subheaderText = constants.getChooseFlightSubheader(isOneWay);
          break;

        case FlightShopStep.ChooseReturn:
          headerText = constants.getChooseFlightHeaderText(false, originLabel);
          subheaderText = constants.getChooseFlightSubheader(isOneWay);
          break;

        default:
          break;
      }
    }

    return (
      <Box className={clsx("header-root", { multicity: isMulticity })}>
        <Box className="header-container">
          <Box className="header-text-wrapper">
            <Typography
              variant="body1"
              dangerouslySetInnerHTML={{ __html: headerText }}
            />
          </Box>
          <Box className="subheader-text-wrapper">
            <span>{subheaderText}</span>
          </Box>
        </Box>
        {isMulticity && (
          <Box className="multicity-subtitle-container">
            <span>
              {isCorpTenant(config.TENANT)
                ? constants.CORP_MOBILE_MULTICITY_FLIGHT_LIST_SUBTITLE
                : constants.MOBILE_MULTICITY_FLIGHT_LIST_SUBTITLE}
            </span>
          </Box>
        )}
      </Box>
    );
  };

  const renderRebookFlightListTitle = () => {
    const listDate = rebookDepartureDateTime
      ? getTimeWithUtcOffset(rebookDepartureDateTime)
      : null;

    return (
      <Typography
        tabIndex={0}
        innerRef={initialFocusRef}
        className="rebook-flight-list-title-text"
        variant="body1"
        dangerouslySetInnerHTML={{
          __html: FLIGHT_SHOP_REBOOK_LIST_TITLE_TEXT({
            isSelectingReturnFlightForRebook: isSelectingReturnFlight,
            departureDate: listDate,
          }),
        }}
      />
    );
  };

  const renderDesktopFlightShopHeader = () => {
    const [isPolicyModalOpen, setIsPolicyModalOpen] = React.useState(false);

    const { policies } = useContext(ClientContext);

    const isPolicyDescriptorsEnabled = useExperimentIsVariant(
      "corp-admin-policy-descriptors",
      "available"
    );

    const onShowPolicyDetailsModal = () => {
      setIsPolicyModalOpen(true);
      trackEvent({
        eventName: VIEWED_POLICY_MODAL,
        properties: {
          type: POLICY_MODAL,
          entry_point: ModalScreens.FLIGHTS_AVAILABILITY,
          funnel: "flights",
        },
      });
    };

    const renderLeftSection = (): JSX.Element => {
      return (
        <>
          {/* TODO: add mini summary */}
          {hasChosenDepartureFlight &&
            ((isMulticity &&
              ![MulticityFlightShopStep.Customize].includes(
                currentMulticityStep
              )) ||
              currentStep === FlightShopStep.ChooseReturn) && (
              <Box
                className={clsx("flight-summary-wrapper", {
                  "horizontal-layout":
                    isInChooseMulticityDeparturesSteps ||
                    isInMulticityReviewStep,
                })}
              >
                <FlightShopSummaryPanel
                  showDepartureReviewOnly={true}
                  isVITripSelected={isVITripSelected}
                  setOpenVIVariantModal={setOpenVIVariantModal}
                  setOpenMultipleAirlinesFares={setOpenMultipleAirlinesFares}
                />
              </Box>
            )}
        </>
      );
    };

    return (
      <>
        <Box
          className={clsx("progress-bar-container", { multicity: isMulticity })}
        >
          {isMulticity ? (
            <MulticityFlightShopProgressBar />
          ) : (
            <FlightShopProgressBar
              isReviewItineraryHidden={isInPriceFreezeVariant}
              useBackToChooseDeparture={false}
              // note: the customize step is only added to the regular flight shop flow
              useCustomizeStep={
                useCustomizeStep &&
                !isInPriceFreezeVariant &&
                !isInDisruptionProtectionRebook
              }
              useDisruptionProtectionStep={isInDisruptionProtectionRebook}
            />
          )}
          {isPolicyDescriptorsEnabled && (
            <>
              <PolicyModalButton
                underline={false}
                policies={policies}
                onClick={onShowPolicyDetailsModal}
              />
              <PolicyDetailsModal
                policies={policies}
                isOpen={isPolicyModalOpen}
                setIsOpen={setIsPolicyModalOpen}
                productType="flight"
              />
            </>
          )}
        </Box>
        {isTravelSalesEventActive &&
          currentStep === FlightShopStep.ChooseDeparture && (
            <TravelSalesEventBanner
              variant="default"
              onClick={() => {
                const path = `${PATH_TRAVEL_SALE}?entryType=air_list_component`;
                isMobile ? history.push(path) : window.open(path, "_blank");
              }}
              subtitle={TRAVEL_SALES_EVENT_ACTIVE_SUBTITLE}
              buttonText={TRAVEL_SALES_EVENT_ACTIVE_CTA}
            />
          )}
        {!isMulticity && isInSimilarFlights ? (
          <Box className="original-price-freeze-info-wrapper">
            <OriginalPriceFreezeInfo />
          </Box>
        ) : (
          <FlightShopVisibility
            hideOnShopType={[FlightShopType.CHANGE_FOR_ANY_REASON_EXERCISE]}
          >
            <Box
              className={clsx("header-text-and-flight-summary-container", {
                "horizonal-summary-cards": isInChooseMulticityDeparturesSteps,
              })}
            >
              <Box className="left-section">{renderLeftSection()}</Box>
            </Box>
          </FlightShopVisibility>
        )}
        <FlightShopVisibility
          showOnShopType={[FlightShopType.CHANGE_FOR_ANY_REASON_EXERCISE]}
          showOnShopStep={[
            FlightShopStep.ChooseDeparture,
            FlightShopStep.ChooseReturn,
          ]}
        >
          <PreviousFlightBanner />
        </FlightShopVisibility>
        {currentStep === FlightShopStep.ChooseDeparture &&
          !tripSummariesLoading &&
          !isMulticity && (
            <FlightShopVisibility
              hideOnShopType={[
                FlightShopType.DISRUPTION_PROTECTION_EXERCISE,
                FlightShopType.CHANGE_FOR_ANY_REASON_EXERCISE,
              ]}
            >
              <DesktopPricePrediction
                hidden={isInPriceFreezeVariant}
                highlightedPriceFreezeBox={false}
                highlightedPriceFreezeButton={false}
                showPriceFreezeIcon={false}
                highlightedRewards={false}
                useLockPriceLanguage={useLockPriceLanguage}
                airCXV3Variant={airCXV3Variant}
              />
            </FlightShopVisibility>
          )}
        {currentStep === FlightShopStep.ChooseDeparture &&
          isInDisruptionProtectionRebook &&
          productRedeemChoice &&
          productRedeemChoice !== "missed_connection_vi" && (
            <Box className="disruption-protection-rebook-content-wrapper">
              {isPartiallyRebooking && (
                <Box
                  className={clsx(
                    "disruption-protection-rebook-content",
                    "rebook-banner"
                  )}
                >
                  <RebookBanner />
                </Box>
              )}
              {originalSliceFlightSummaryProps.tripSlice.segments.length >
                0 && (
                <Box
                  className={clsx(
                    "disruption-protection-rebook-content",
                    "rebook-flight-summary"
                  )}
                >
                  <GenericFlightSummary
                    {...originalSliceFlightSummaryProps}
                    headerType={
                      isSelectingReturnFlight
                        ? "original-return"
                        : "original-outbound"
                    }
                    isMobile={false}
                    notification={isPartiallyRebookingNotificationProps}
                  />
                  {disruptionProtectionRefundEntryPoint(history)}
                </Box>
              )}
            </Box>
          )}
        {![FlightShopStep.ReviewItinerary, FlightShopStep.Customize].includes(
          currentStep
        ) &&
          ![
            MulticityFlightShopStep.BookTrip,
            MulticityFlightShopStep.ReviewItinerary,
            MulticityFlightShopStep.Customize,
          ].includes(currentMulticityStep) && (
            <>
              {!isInDisruptionProtectionRebook &&
                isFlightListOptimizationExperiment && (
                  <Box className="title-date">
                    <Typography variant="h3" className="title">
                      {getCurrentStepFlightListTitle()}
                    </Typography>
                    <Typography className="date">
                      {getCurrentStepFlightDate()}
                    </Typography>
                  </Box>
                )}
              <FlightShopVisibility
                showOnShopType={[FlightShopType.CHANGE_FOR_ANY_REASON_EXERCISE]}
              >
                <ChfarMissionControlEditLink />
              </FlightShopVisibility>
              {chfarFlightShopSearchControl}
              {isAirCXV3Experiment && !matchesMediumDesktopOnly && (
                <div
                  ref={fixedFareClassShelfPlaceholderRef}
                  className={clsx({
                    "fixed-shelf-placeholder":
                      fixedFareClassShelfInView &&
                      !fixedFareClassShelfPlaceholderInView,
                  })}
                />
              )}
              <div
                className={clsx("filters-sorting", {
                  fixed: showFixedShelf,
                  "air-cx-v3": isAirCXV3Experiment && !matchesMediumDesktopOnly,
                  "air-cx-v4": isAirCXV4Experiment && !matchesMediumDesktopOnly,
                })}
                style={
                  isAirCXV3Experiment
                    ? {
                        top: fixedShelfOffset,
                        marginLeft:
                          window.innerWidth > 1460 && showFixedShelf
                            ? (getScrollbarWidth() / 2) * -1
                            : undefined,
                      }
                    : undefined
                }
                ref={fixedFareClassShelfRef}
              >
                {!isAirCXV3Experiment &&
                  ((!isInDisruptionProtectionRebook &&
                    isFlightListOptimizationExperiment) ||
                    isMulticity) && (
                    <>
                      <Box className="filter-modal-entry-points">
                        <FlightShopFilter
                          openAllFiltersModal={openAllFiltersModal}
                          setOpenAllFiltersModal={setOpenAllFiltersModal}
                          isFlightListOptimizationExperiment={
                            isFlightListOptimizationExperiment
                          }
                          airCXV3Variant={airCXV3Variant}
                        />
                        <ActionButton
                          className={clsx("filters-modal-action-button")}
                          defaultStyle="h4r-secondary"
                          message={constants.FILTERS_MODAL_CTA_TEXT(0)}
                          icon={<Icon name={IconName.Settings} />}
                          onClick={() => setOpenAllFiltersModal(true)}
                        />

                        <Box className="filters">
                          <FlightShopSearchFilter
                            hideAirportFilter={
                              origin?.id.code.regionType === RegionType.Airport
                            }
                            isFlightListOptimizationExperiment={
                              isFlightListOptimizationExperiment
                            }
                            filterLabelValues={filterLabelValues}
                            airCXV3Variant={airCXV3Variant}
                          />
                        </Box>
                      </Box>
                      <hr />
                    </>
                  )}
                <Box
                  className={clsx("sorting-fares", {
                    "filter-experiment": isFlightListOptimizationExperiment,
                  })}
                >
                  <div className="sort-and-filter-wrapper">
                    <SortOptionSelection
                      isFlightListOptimizationExperiment={
                        isFlightListOptimizationExperiment
                      }
                      sortOption={sortOption}
                      setSortOption={setSortOption}
                      sortOptions={sortOptions}
                    />
                    {showFixedShelf && (
                      <>
                        <FlightShopFilter
                          openAllFiltersModal={openAllFiltersModal}
                          setOpenAllFiltersModal={setOpenAllFiltersModal}
                          isFlightListOptimizationExperiment={
                            isFlightListOptimizationExperiment
                          }
                          airCXV3Variant={airCXV3Variant}
                        />
                        <ActionButton
                          className={clsx("filters-modal-action-button")}
                          defaultStyle="h4r-secondary"
                          message={constants.FILTERS_MODAL_CTA_TEXT(
                            activeFiltersCount
                          )}
                          icon={<Icon name={IconName.Settings} />}
                          onClick={() => setOpenAllFiltersModal(true)}
                        />
                      </>
                    )}
                  </div>
                  {!isInDisruptionProtectionRebook && (
                    <FareClassOptionSelection
                      isFlightListOptimizationExperiment={
                        isFlightListOptimizationExperiment
                      }
                      isAirCXV4Experiment={isAirCXV4Experiment}
                    />
                  )}
                </Box>
              </div>
              {isInDisruptionProtectionRebook ? (
                <Box className="rebook-flight-list-title">
                  {renderRebookFlightListTitle()}
                </Box>
              ) : null}
            </>
          )}
        {currentStep === FlightShopStep.ChooseReturn &&
          hasActiveRefundableFare && <RefundableFareOptionsBanner />}
      </>
    );
  };

  const renderMobileFlightShopHeader = () => {
    const showSummaryPanel =
      hasChosenDepartureFlight &&
      ((isMulticity &&
        ![
          MulticityFlightShopStep.ReviewItinerary,
          MulticityFlightShopStep.FareDetails,
          MulticityFlightShopStep.Customize,
        ].includes(currentMulticityStep)) ||
        currentStep === FlightShopStep.ChooseReturn);

    const renderHeaderAndFlightSummary = (): JSX.Element => {
      return (
        <>
          <FlightShopVisibility
            hideOnShopType={[FlightShopType.CHANGE_FOR_ANY_REASON_EXERCISE]}
          >
            {showSummaryPanel && (
              <Box className="flight-summary-wrapper">
                <FlightShopSummaryPanel
                  showDepartureReviewOnly={true}
                  isVITripSelected={isVITripSelected}
                  setOpenVIVariantModal={setOpenVIVariantModal}
                  setOpenMultipleAirlinesFares={setOpenMultipleAirlinesFares}
                />
              </Box>
            )}
          </FlightShopVisibility>
          <FlightShopVisibility
            showOnShopType={[FlightShopType.CHANGE_FOR_ANY_REASON_EXERCISE]}
            showOnShopStep={[
              FlightShopStep.ChooseDeparture,
              FlightShopStep.ChooseReturn,
            ]}
          >
            {(!isMulticity || isInChooseMulticityDeparturesSteps) && (
              <Box className="mobile-chfar-header-content-wrapper">
                <PreviousFlightBanner />
                <Box className="title-date">
                  <Typography variant="h3" className="title">
                    {getCurrentStepFlightListTitle()}
                  </Typography>
                  <Typography className="date">
                    {getCurrentStepFlightDate()}
                  </Typography>
                </Box>
                <ChfarMissionControlEditLink />
              </Box>
            )}
          </FlightShopVisibility>
          <FlightShopVisibility
            hideOnShopType={[FlightShopType.CHANGE_FOR_ANY_REASON_EXERCISE]}
            hideOnShopStep={[FlightShopStep.ReviewItinerary]}
          >
            {currentMulticityStep !==
              MulticityFlightShopStep.ReviewItinerary && (
              <Box className="header-text-wrapper">
                <HeaderText />
              </Box>
            )}
          </FlightShopVisibility>
        </>
      );
    };

    return (
      <>
        {isInSimilarFlights && (
          <Box className="mobile-original-price-freeze-info-wrapper">
            <OriginalPriceFreezeInfo />
          </Box>
        )}
        <Box className="mobile-progress-header-and-flight-summary-container">
          {renderHeaderAndFlightSummary()}
        </Box>
      </>
    );
  };

  return (
    <Box
      className={clsx("flight-shop-header-root", {
        mobile: isMobile,
        "medium-desktop": isMediumDesktop,
        "flight-list-optimization-experiment":
          isFlightListOptimizationExperiment,
      })}
    >
      <Box className="flight-shop-header-container">
        {!isMobile && renderDesktopFlightShopHeader()}
        {isMobile && renderMobileFlightShopHeader()}

        {openVIVariantModal && openVIVariantModal !== "reminder" && (
          <VirtualInterliningModal
            isMobile={isMobile}
            variant={openVIVariantModal}
            fareDetails={tripDetails.fareDetails}
            airports={airports}
            isOpen
            onClose={() => setOpenVIVariantModal(false)}
          />
        )}

        {/*openVIVariantModal === "reminder" && (
          <SelfTransferReminderModal
              isOpen
              fareDetails={tripDetails.fareDetails}
              tripDetails={tripDetails}
              onClickContinue={() => {
                trackEvent({
                  eventName: CONFIRMED_VI_BAGGAGE_WARNING,
                  properties: {},
                });
                populateFlightBookQueryParams({ history });
              }}
              onClickDifferentFlight={() => {
                trackEvent({
                  eventName: DECLINED_VI_BAGGAGE_WARNING,
                  properties: {},
                });
                setFlightShopProgress(FlightShopStep.ChooseDeparture);
              }}
              onClose={() => setOpenVIVariantModal(false)}
              slices={tripDetails.slices}
              airports={airports}
          />
      )*/}

        {openMultipleAirlinesFares && (
          <MultipleAirlinesFareModal
            isOpen={openMultipleAirlinesFares}
            tripDetails={tripDetails}
            airports={airports}
            onClose={() => setOpenMultipleAirlinesFares(false)}
          />
        )}
      </Box>
    </Box>
  );
};
