import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";
import axios from "axios";
import {
  airShoppingApi,
  LocationQueryEnum,
  type Suggestion,
} from "@b2bportal/air-shopping-api";
import { ITripTerminus } from "redmond";

type FlightSuggestion = Suggestion & { id: { Id: "Flight" } };

type LocationQueryArgs = {
  location: string;
  type: string;
};
export const chfarLocationApi = createApi({
  reducerPath: "chfarLocationApi",
  baseQuery: fakeBaseQuery(),
  tagTypes: ["chfarLocation"],
  endpoints: (builder) => ({
    getLocation: builder.query<ITripTerminus, LocationQueryArgs>({
      queryFn: async ({ location, type }) => {
        const body = {
          LocationQuery: LocationQueryEnum.Label,
          l: location,
        };

        try {
          const res = await airShoppingApi(axios).apiV0AutocompleteLocationPost(
            body
          );

          const matchedLocation = res.data.categories
            .flatMap((category) => category.results)
            .filter(
              (result): result is FlightSuggestion => result.id.Id === "Flight"
            )
            .find(
              (result) =>
                result.id.code.code === location &&
                result.id.code.regionType === type
            );

          if (!matchedLocation) {
            throw new Error("Location not found");
          }

          return { data: matchedLocation as ITripTerminus };
        } catch (error: unknown) {
          if (axios.isAxiosError(error)) {
            return {
              error: {
                status: error.response?.status,
                message: error.message,
              },
            };
          }

          return {
            error: { error: (error as Error).message ?? "Unknown error" },
          };
        }
      },
      providesTags: ["chfarLocation"],
    }),
  }),
});

export const { useGetLocationQuery } = chfarLocationApi;
export const chfarLocationApiReducerPath = chfarLocationApi.reducerPath;
export const chfarLocationApiReducer = chfarLocationApi.reducer;
export const chfarLocationApiMiddleware = chfarLocationApi.middleware;

export type IChFARLocationApiState = ReturnType<
  typeof chfarLocationApi.reducer
>;
