import axios, { AxiosResponse } from "axios";
import { put, select, delay } from "redux-saga/effects";
import dayjs from "dayjs";
import {
  AIR_PRICE_QUOTE_TO_BOOK_REQUEST_RUNTIME,
  ConnectionResultEnum,
  InvalidEnum,
  CallState,
  ErrorModalType,
  TravelWalletOffer,
} from "redmond";
import {
  CipherText,
  Payment,
  PaymentOpaqueValue,
  purchaseApi,
} from "@b2bportal/purchase-api";
import { actions } from "../../actions";
import { actions as freezeActions } from "../../../freeze/actions";
import {
  getPriceQuoteAirProperties,
  getPriceQuoteSuccessTime,
  getIsWaitingPriceQuote,
  getPollPriceQuoteCallState,
  getPriceDifference,
  getPaymentsV2,
  getSession,
  getApprovalRequestReason,
  getOffers,
} from "../../reducer";
import { isFlightBookWithAncillariesActiveSelector } from "../../../shop/reducer";
import {
  priceFreezeFareQuoteErrorTitlesTypeSelector,
  priceFreezeFareQuoteAcknowledgedSelector,
  priceFreezeFareQuoteCallStateSelector,
} from "../../../freeze/reducer";
import { delayTimes } from "./pollQuoteSaga";
import { IStoreState } from "../../../../reducers/types";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import { getHSPEarnOfferDescription } from "halifax";
import { authorizeEarnOffer } from "../../../../api/v0/travel-wallet/authorizeEarnOffer";

export function* schedulePaymentSaga({
  session,
  payments,
  ancillaries,
  agentFee,
  isPriceFreezePurchase,
}: actions.ISetSchedulePayment) {
  try {
    const isFlightBookWithAncillariesActive: boolean = yield select(
      isFlightBookWithAncillariesActiveSelector
    );
    /*
      note: in the flight-book variant of https://app.launchdarkly.com/capital-one/test/features/c1-fintech-ancillary-marketplace/targeting
      and the PF purchase flow, a price quote could still be in progress at this step; therefore, schedulePayment needs to be postponed until the new price quote returns
    */
    let result: WaitResult | undefined = undefined;
    if (isPriceFreezePurchase) {
      result = yield waitForPriceFreezeFareQuoteAndPriceQuote();
    } else if (isFlightBookWithAncillariesActive) {
      result = yield waitForPriceQuote();
    }
    if (result === WaitResult.Terminate) {
      return;
    }

    /*
      note: allowing the consumer to avoid providing the session & payments at the call time; also, session & payments passed through
      the action call will have the highest priority, so it's not affecting any existing code path where session & payments are provided
    */
    const currentSession: CipherText | undefined =
      session ?? (yield select(getSession));
    const currentPayments: PaymentOpaqueValue[] | undefined | null =
      payments ?? (yield select(getPaymentsV2));
    const corporateOutOfPolicyReason: string = yield select(
      getApprovalRequestReason
    );
    if (!currentSession || !currentPayments || currentPayments.length === 0) {
      // note: this code path is only hit by the PF purchase flow; until there is an appropriate error modal to show, setting the call state to NotCalled is good enough
      yield put(actions.setSchedulePaymentNotCalled());
      return;
    }

    const offers: TravelWalletOffer[] | undefined = yield select(getOffers);
    const appliedOfferPayment = payments?.find(
      (payment) => payment.type === Payment.TravelWalletOffer
    );

    const appliedOffer = offers?.find(
      (offer) => offer.id === appliedOfferPayment?.value.offerId
    );

    const hspEarnDescription = getHSPEarnOfferDescription(appliedOffer);

    // authorize hsp earn offer before fulfillment
    if (hspEarnDescription && appliedOffer?.id) {
      yield authorizeEarnOffer({
        earnOfferId: appliedOffer.id,
      });
    }

    const request = () =>
      purchaseApi(axios as any).apiV0PurchaseFulfillSchedulePost({
        quoteToken: currentSession,
        payments: hspEarnDescription
          ? currentPayments.filter(
              (payment) => payment.type !== Payment.TravelWalletOffer // don't include HSP earn offer in payments since it will fail
            )
          : currentPayments,
        ancillaries,
        corporateOutOfPolicyReason: corporateOutOfPolicyReason,
      });
    const response: AxiosResponse<CipherText> = yield request();

    const state: IStoreState = yield select();
    const priceQuoteSuccessTime = dayjs(getPriceQuoteSuccessTime(state));
    const priceQuoteAirProps = getPriceQuoteAirProperties(state);
    trackEvent({
      eventName: AIR_PRICE_QUOTE_TO_BOOK_REQUEST_RUNTIME,
      properties: {
        runtime: dayjs().diff(priceQuoteSuccessTime, "seconds", true),
        provider: priceQuoteAirProps?.provider || "",
      },
    });

    yield put(actions.setSession(response.data));
    yield put(actions.setSchedulePaymentSuccess());
    yield put(actions.pollFinalized(agentFee, isPriceFreezePurchase));
  } catch (e) {
    yield put(
      actions.setSchedulePaymentFailed({
        Invalid: InvalidEnum.Missing,
        ConnectionResult: ConnectionResultEnum.Invalid,
      })
    );
  }
}

enum WaitResult {
  Proceed,
  Terminate,
}

function* waitForPriceFreezeFareQuoteAndPriceQuote() {
  let pollTerminated = false;
  const pfQuoteDelayTimes = [
    2000, 4000, 6000, 8000, 10000, 20000, 30000, 30000,
  ];
  let totalDelayTime = pfQuoteDelayTimes.reduce<number>(
    (sum, current) => sum + current,
    0
  );

  while (!pollTerminated) {
    const state: IStoreState = yield select();
    const isWaitingPriceQuote = getIsWaitingPriceQuote(state);
    const pollPriceQuoteCallState = getPollPriceQuoteCallState(state);
    const priceFreezeFareQuoteErrorTitlesType =
      priceFreezeFareQuoteErrorTitlesTypeSelector(state);
    const priceFreezeFareQuoteAcknowledged =
      priceFreezeFareQuoteAcknowledgedSelector(state);
    const priceFreezeFareQuoteCallState =
      priceFreezeFareQuoteCallStateSelector(state);

    const isFareQuoteAcknowledgedAndReady = (() => {
      switch (priceFreezeFareQuoteCallState) {
        case CallState.Success:
          switch (priceFreezeFareQuoteErrorTitlesType) {
            case ErrorModalType.PRICE_FREEZE_PURCHASE_FROZEN_PRICE_HAS_INCREASED:
            case ErrorModalType.PRICE_FREEZE_PURCHASE_FROZEN_PRICE_HAS_INCREASED_DURING_PAYMENT:
            case ErrorModalType.PRICE_FREEZE_PURCHASE_FROZEN_PRICE_HAS_DECREASED:
            case ErrorModalType.PRICE_FREEZE_PURCHASE_FROZEN_PRICE_HAS_DECREASED_DURING_PAYMENT:
              return priceFreezeFareQuoteAcknowledged;
            // note: when it's showing the no-availability modal, the user should be stopped from proceeding payment
            case ErrorModalType.PRICE_FREEZE_PURCHASE_HAS_NO_AVAILABILITY:
              return false;
            default:
              // note: when the error type is null, it means that things are ready to go
              return true;
          }
        default:
          return false;
      }
    })();

    // note: attempt to terminate this saga when it's not currently fetching any new fareQuote, and the current fareQuote isn't ready
    if (
      priceFreezeFareQuoteCallState !== CallState.InProcess &&
      !isFareQuoteAcknowledgedAndReady &&
      !isWaitingPriceQuote
    ) {
      pollTerminated = true;
      yield put(actions.setSchedulePaymentNotCalled());
      yield put(freezeActions.acknowledgePriceFreezeFareQuoteDetails(false));
      return WaitResult.Terminate;
    }

    // note: when freeze-this-price isn't ready by the end, it will terminate this saga
    if (!isWaitingPriceQuote || totalDelayTime <= 0) {
      pollTerminated = true;
      if (pollPriceQuoteCallState === CallState.Failed) {
        yield put(
          actions.setSchedulePaymentFailed({
            Invalid: InvalidEnum.Missing,
            ConnectionResult: ConnectionResultEnum.Invalid,
          })
        );
        return WaitResult.Terminate;
      }
    } else {
      // checks the progress of poll quote every second
      totalDelayTime -= 1000;
      yield delay(1000);
    }
  }

  return WaitResult.Proceed;
}

function* waitForPriceQuote() {
  let pollTerminated = false;
  let totalDelayTime = delayTimes.reduce<number>(
    (sum, current) => sum + current,
    0
  );

  while (!pollTerminated) {
    const state: IStoreState = yield select();
    const isWaitingPriceQuote = getIsWaitingPriceQuote(state);
    const pollPriceQuoteCallState = getPollPriceQuoteCallState(state);
    const priceDifference = getPriceDifference(state);

    if (!isWaitingPriceQuote || totalDelayTime <= 0) {
      pollTerminated = true;

      // note: when confirm-and-book isn't ready by the end, it will terminate this saga
      if (
        pollPriceQuoteCallState === CallState.Failed ||
        priceDifference.hasDifference
      ) {
        yield put(
          actions.setSchedulePaymentFailed({
            Invalid: InvalidEnum.Missing,
            ConnectionResult: ConnectionResultEnum.Invalid,
          })
        );
        return WaitResult.Terminate;
      }
    } else {
      // checks the progress of poll quote every second
      totalDelayTime -= 1000;
      yield delay(1000);
    }
  }

  return WaitResult.Proceed;
}
