// Getters
export const FETCH_USER_PASSENGERS = "flightBook/FETCH_USER_PASSENGERS";
export type FETCH_USER_PASSENGERS = typeof FETCH_USER_PASSENGERS;

export const LIST_PAYMENT_METHODS = "flightBook/LIST_PAYMENT_METHODS";
export type LIST_PAYMENT_METHODS = typeof LIST_PAYMENT_METHODS;

export const FETCH_PAYMENT_METHOD = "flightBook/FETCH_PAYMENT_METHOD";
export type FETCH_PAYMENT_METHOD = typeof FETCH_PAYMENT_METHOD;

export const FETCH_SEAT_MAP = "flightBook/FETCH_SEAT_MAP";
export type FETCH_SEAT_MAP = typeof FETCH_SEAT_MAP;

export const FETCH_SEAT_MAP_FAILED = "flightBook/FETCH_SEAT_MAP_FAILED";
export type FETCH_SEAT_MAP_FAILED = typeof FETCH_SEAT_MAP_FAILED;

export const FETCH_PRICE_DROP_ELIGIBILITY =
  "flightBook/FETCH_PRICE_DROP_ELIGIBILITY";
export type FETCH_PRICE_DROP_ELIGIBILITY = typeof FETCH_PRICE_DROP_ELIGIBILITY;

// Setters

export const SET_SEAT_MAP = "flightBook/SET_SEAT_MAP";
export type SET_SEAT_MAP = typeof SET_SEAT_MAP;

export const SET_CHEAPEST_SEAT = "flightBook/SET_CHEAPEST_SEAT";
export type SET_CHEAPEST_SEAT = typeof SET_CHEAPEST_SEAT;

export const SET_SKIP_SEAT_SELECTION = "flightBook/SET_SKIP_SEAT_SELECTION";
export type SET_SKIP_SEAT_SELECTION = typeof SET_SKIP_SEAT_SELECTION;

export const SET_SELECTED_SEATS = "flightBook/SET_SELECTED_SEATS";
export type SET_SELECTED_SEATS = typeof SET_SELECTED_SEATS;

export const UPDATE_USER_PASSENGER = "flightBook/UPDATE_USER_PASSENGER";
export type UPDATE_USER_PASSENGER = typeof UPDATE_USER_PASSENGER;

export const UPDATE_USER_PASSENGERS_MULTIPLE =
  "flightBook/UPDATE_USER_PASSENGERS_MULTIPLE";
export type UPDATE_USER_PASSENGERS_MULTIPLE =
  typeof UPDATE_USER_PASSENGERS_MULTIPLE;

export const DELETE_USER_PASSENGER = "flightBook/DELETE_USER_PASSENGER";
export type DELETE_USER_PASSENGER = typeof DELETE_USER_PASSENGER;

export const SET_INFANT_ONLY_ERROR = "flightBook/SET_INFANT_ONLY_ERROR";
export type SET_INFANT_ONLY_ERROR = typeof SET_INFANT_ONLY_ERROR;

export const SET_USER_PASSENGERS = "flightBook/SET_USER_PASSENGERS";
export type SET_USER_PASSENGERS = typeof SET_USER_PASSENGERS;

export const SET_USER_SELECTED_PASSENGER_IDS =
  "flightBook/SET_USER_SELECTED_PASSENGER_IDS";
export type SET_USER_SELECTED_PASSENGER_IDS =
  typeof SET_USER_SELECTED_PASSENGER_IDS;

export const SET_USER_SELECTED_LAP_INFANT_IDS =
  "flightBook/SET_USER_SELECTED_LAP_INFANT_IDS";
export type SET_USER_SELECTED_LAP_INFANT_IDS =
  typeof SET_USER_SELECTED_LAP_INFANT_IDS;

export const SET_USER_SELECTED_TRAVELERS_LIST =
  "flightBook/SET_USER_SELECTED_TRAVELERS_LIST";
export type SET_USER_SELECTED_TRAVELERS_LIST =
  typeof SET_USER_SELECTED_TRAVELERS_LIST;

export const SET_USER_PASSENGERS_CALL_STATE_FAILED =
  "flightBook/SET_USER_PASSENGERS_CALL_STATE_FAILED";
export type SET_USER_PASSENGERS_CALL_STATE_FAILED =
  typeof SET_USER_PASSENGERS_CALL_STATE_FAILED;

export const ACKNOWLEDGE_UPDATE_USER_PASSENGER_FAILURE =
  "flightBook/ACKNOWLEDGE_UPDATE_USER_PASSENGER_FAILURE";
export type ACKNOWLEDGE_UPDATE_USER_PASSENGER_FAILURE =
  typeof ACKNOWLEDGE_UPDATE_USER_PASSENGER_FAILURE;

export const SET_CONTACT_INFO = "flightBook/SET_CONTACT_INFO";
export type SET_CONTACT_INFO = typeof SET_CONTACT_INFO;

export const SET_USER_TCPA_CONSENT_PHONE_NUMBER =
  "flightBook/SET_USER_TCPA_CONSENT_PHONE_NUMBER";
export type SET_USER_TCPA_CONSENT_PHONE_NUMBER =
  typeof SET_USER_TCPA_CONSENT_PHONE_NUMBER;

export const TOGGLE_SMS_OPT_IN = "flightBook/TOGGLE_SMS_OPT_IN";
export type TOGGLE_SMS_OPT_IN = typeof TOGGLE_SMS_OPT_IN;

export const TOGGLE_APP_NOTIF_OPT_IN = "flightBook/TOGGLE_APP_NOTIF_OPT_IN";
export type TOGGLE_APP_NOTIF_OPT_IN = "flightBook/TOGGLE_APP_NOTIF_OPT_IN";

export const SET_PAYMENT_METHODS = "flightBook/SET_PAYMENT_METHODS";
export type SET_PAYMENT_METHODS = typeof SET_PAYMENT_METHODS;

export const SET_PAYMENT_METHOD = "flightBook/SET_PAYMENT_METHOD";
export type SET_PAYMENT_METHOD = typeof SET_PAYMENT_METHOD;

export const SET_SELECTED_PAYMENT_METHOD_ID =
  "flightBook/SET_SELECTED_PAYMENT_METHOD_ID";
export type SET_SELECTED_PAYMENT_METHOD_ID =
  typeof SET_SELECTED_PAYMENT_METHOD_ID;

export const VERIFY_PAYMENT_METHOD = "flightBook/VERIFY_PAYMENT_METHOD";
export type VERIFY_PAYMENT_METHOD = typeof VERIFY_PAYMENT_METHOD;

export const VERIFY_PAYMENT_METHOD_CALL_STATE_FAILED =
  "flightBook/VERIFY_PAYMENT_METHOD_CALL_STATE_FAILED";
export type VERIFY_PAYMENT_METHOD_CALL_STATE_FAILED =
  typeof VERIFY_PAYMENT_METHOD_CALL_STATE_FAILED;

export const VERIFY_PAYMENT_METHOD_CALL_STATE_SUCCESS =
  "flightBook/VERIFY_PAYMENT_METHOD_CALL_STATE_SUCCESS";
export type VERIFY_PAYMENT_METHOD_CALL_STATE_SUCCESS =
  typeof VERIFY_PAYMENT_METHOD_CALL_STATE_SUCCESS;

export const DELETE_PAYMENT_METHOD = "flightBook/DELETE_PAYMENT_METHOD";
export type DELETE_PAYMENT_METHOD = typeof DELETE_PAYMENT_METHOD;

export const DELETE_PAYMENT_METHOD_CALL_STATE_FAILED =
  "flightBook/SET_PAYMENT_METHOD_CALL_STATE_FAILED";
export type DELETE_PAYMENT_METHOD_CALL_STATE_FAILED =
  typeof DELETE_PAYMENT_METHOD_CALL_STATE_FAILED;

export const DELETE_PAYMENT_METHOD_CALL_STATE_SUCCESS =
  "flightBook/DELETE_PAYMENT_METHOD_CALL_STATE_SUCCESS";
export type DELETE_PAYMENT_METHOD_CALL_STATE_SUCCESS =
  typeof DELETE_PAYMENT_METHOD_CALL_STATE_SUCCESS;

export const SET_PAYMENT_METHOD_CALL_STATE_FAILED =
  "flightBook/SET_PAYMENT_METHOD_CALL_STATE_FAILED";
export type SET_PAYMENT_METHOD_CALL_STATE_FAILED =
  typeof SET_PAYMENT_METHOD_CALL_STATE_FAILED;

export const SET_PAYMENT_METHODS_CALL_STATE_FAILED =
  "flightBook/SET_PAYMENT_METHODS_CALL_STATE_FAILED";
export type SET_PAYMENT_METHODS_CALL_STATE_FAILED =
  typeof SET_PAYMENT_METHODS_CALL_STATE_FAILED;

export const OPEN_SESSION = "flightBook/OPEN_SESSION";
export type OPEN_SESSION = typeof OPEN_SESSION;

export const REOPEN_SESSION = "flightBook/REOPEN_SESSION";
export type REOPEN_SESSION = typeof REOPEN_SESSION;

export const CHECK_SESSION = "flightBook/CHECK_SESSION";
export type CHECK_SESSION = typeof CHECK_SESSION;

export const CLOSE_SESSION = "flightBook/CLOSE_SESSION";
export type CLOSE_SESSION = typeof CLOSE_SESSION;

export const OPEN_SESSION_CALL_STATE_FAILED =
  "flightBook/OPEN_SESSION_CALL_STATE_FAILED";
export type OPEN_SESSION_CALL_STATE_FAILED =
  typeof OPEN_SESSION_CALL_STATE_FAILED;

export const CHECK_SESSION_CALL_STATE_SUCCESS =
  "flightBook/CHECK_SESSION_CALL_STATE_SUCCESS";
export type CHECK_SESSION_CALL_STATE_SUCCESS =
  typeof CHECK_SESSION_CALL_STATE_SUCCESS;

export const CHECK_SESSION_CALL_STATE_FAILED =
  "flightBook/CHECK_SESSION_CALL_STATE_FAILED";
export type CHECK_SESSION_CALL_STATE_FAILED =
  typeof CHECK_SESSION_CALL_STATE_FAILED;

export const CLOSE_SESSION_CALL_STATE_SUCCESS =
  "flightBook/CLOSE_SESSION_CALL_STATE_SUCCESS";
export type CLOSE_SESSION_CALL_STATE_SUCCESS =
  typeof CLOSE_SESSION_CALL_STATE_SUCCESS;

export const CLOSE_SESSION_CALL_STATE_FAILED =
  "flightBook/CLOSE_SESSION_CALL_STATE_FAILED";

export type CLOSE_SESSION_CALL_STATE_FAILED =
  typeof CLOSE_SESSION_CALL_STATE_FAILED;

export const SET_SESSION = "flightBook/SET_SESSION";
export type SET_SESSION = typeof SET_SESSION;

export const CLEAR_SESSION = "flightBook/CLEAR_SESSION";
export type CLEAR_SESSION = typeof CLEAR_SESSION;

export const SET_PRICE_QUOTE = "flightBook/SET_PRICE_QUOTE";
export type SET_PRICE_QUOTE = typeof SET_PRICE_QUOTE;

export const SET_QUOTE_REWARDS_TOTAL = "flightBook/SET_QUOTE_REWARDS_TOTAL";
export type SET_QUOTE_REWARDS_TOTAL = typeof SET_QUOTE_REWARDS_TOTAL;

export const SET_PRICE_QUOTE_SUCCESS_TIME =
  "flightBook/SET_PRICE_QUOTE_SUCCESS_TIME";
export type SET_PRICE_QUOTE_SUCCESS_TIME = typeof SET_PRICE_QUOTE_SUCCESS_TIME;

export const SCHEDULE_QUOTE = "flightBook/SCHEDULE_QUOTE";
export type SCHEDULE_QUOTE = typeof SCHEDULE_QUOTE;

export const SCHEDULED_QUOTE_CANCELLED = "flightBook/SCHEDULED_QUOTE_CANCELLED";
export type SCHEDULED_QUOTE_CANCELLED = typeof SCHEDULED_QUOTE_CANCELLED;

export const SCHEDULE_QUOTE_CALL_STATE_SUCCESS =
  "flightBook/SCHEDULE_QUOTE_CALL_STATE_SUCCESS";
export type SCHEDULE_QUOTE_CALL_STATE_SUCCESS =
  typeof SCHEDULE_QUOTE_CALL_STATE_SUCCESS;

export const SCHEDULE_QUOTE_CALL_STATE_FAILED =
  "flightBook/SCHEDULE_QUOTE_CALL_STATE_FAILED";
export type SCHEDULE_QUOTE_CALL_STATE_FAILED =
  typeof SCHEDULE_QUOTE_CALL_STATE_FAILED;

export const POLL_QUOTE = "flightBook/POLL_QUOTE";
export type POLL_QUOTE = typeof POLL_QUOTE;

export const SET_POLL_QUOTE_CALL_STATE_SUCCESS =
  "flightBook/SET_POLL_QUOTE_CALL_STATE_SUCCESS";
export type SET_POLL_QUOTE_CALL_STATE_SUCCESS =
  typeof SET_POLL_QUOTE_CALL_STATE_SUCCESS;

export const SET_POLL_QUOTE_CALL_STATE_FAILED =
  "flightBook/SET_POLL_QUOTE_CALL_STATE_FAILED";
export type SET_POLL_QUOTE_CALL_STATE_FAILED =
  typeof SET_POLL_QUOTE_CALL_STATE_FAILED;

export const SET_QUOTE = "flightBook/SET_QUOTE";
export type SET_QUOTE = typeof SET_QUOTE;

export const VALIDATE_PASSENGERS = "flightBook/VALIDATE_PASSENGERS";
export type VALIDATE_PASSENGERS = typeof VALIDATE_PASSENGERS;

export const PASSENGER_VALIDATION_CALL_STATE_SUCCESS =
  "flightBook/PASSENGER_VALIDATION_CALL_STATE_SUCCESS";
export type PASSENGER_VALIDATION_CALL_STATE_SUCCESS =
  typeof PASSENGER_VALIDATION_CALL_STATE_SUCCESS;

export const PASSENGER_VALIDATION_CALL_STATE_FAILED =
  "flightBook/PASSENGER_VALIDATION_CALL_STATE_FAILED";
export type PASSENGER_VALIDATION_CALL_STATE_FAILED =
  typeof PASSENGER_VALIDATION_CALL_STATE_FAILED;

export const FETCH_TRIP_PRICING = "flightBook/FETCH_TRIP_PRICING";
export type FETCH_TRIP_PRICING = typeof FETCH_TRIP_PRICING;

export const FETCH_TRIP_PRICING_CALL_STATE_SUCCESS =
  "flightBook/FETCH_TRIP_PRICING_CALL_STATE_SUCCESS";
export type FETCH_TRIP_PRICING_CALL_STATE_SUCCESS =
  typeof FETCH_TRIP_PRICING_CALL_STATE_SUCCESS;

export const FETCH_TRIP_PRICING_CALL_STATE_FAILURE =
  "flightBook/FETCH_TRIP_PRICING_CALL_STATE_FAILURE";
export type FETCH_TRIP_PRICING_CALL_STATE_FAILURE =
  typeof FETCH_TRIP_PRICING_CALL_STATE_FAILURE;

export const SET_TRIP_PRICING = "flightBook/SET_TRIP_PRICING";
export type SET_TRIP_PRICING = typeof SET_TRIP_PRICING;

export const SET_PASSENGERS_VALID = "flightBook/SET_PASSENGERS_VALID";
export type SET_PASSENGERS_VALID = typeof SET_PASSENGERS_VALID;

export const SET_PASSENGERS_INVALID = "flightBook/SET_PASSENGERS_INVALID";
export type SET_PASSENGERS_INVALID = typeof SET_PASSENGERS_INVALID;

export const SCHEDULE_PURCHASE = "flightBook/SCHEDULE_PURCHASE";
export type SCHEDULE_PURCHASE = typeof SCHEDULE_PURCHASE;

export const SET_SCHEDULE_PURCHASE_SUCCESS =
  "flightBook/SET_SCHEDULE_PURCHASE_SUCCESS";
export type SET_SCHEDULE_PURCHASE_SUCCESS =
  typeof SET_SCHEDULE_PURCHASE_SUCCESS;

export const SET_SCHEDULE_PURCHASE_FAILED =
  "flightBook/SET_SCHEDULE_PURCHASE_FAILED";
export type SET_SCHEDULE_PURCHASE_FAILED = typeof SET_SCHEDULE_PURCHASE_FAILED;

export const SCHEDULE_PAYMENT = "flightBook/SCHEDULE_PAYMENT";
export type SCHEDULE_PAYMENT = typeof SCHEDULE_PAYMENT;

export const SET_SCHEDULE_PAYMENT_SUCCESS =
  "flightBook/SET_SCHEDULE_PAYMENT_SUCCESS";
export type SET_SCHEDULE_PAYMENT_SUCCESS = typeof SET_SCHEDULE_PAYMENT_SUCCESS;

export const SET_SCHEDULE_PAYMENT_FAILED =
  "flightBook/SET_SCHEDULE_PAYMENT_FAILED";
export type SET_SCHEDULE_PAYMENT_FAILED = typeof SET_SCHEDULE_PAYMENT_FAILED;

export const SET_SCHEDULE_PAYMENT_NOT_CALLED =
  "flightBook/SET_SCHEDULE_PAYMENT_NOT_CALLED";
export type SET_SCHEDULE_PAYMENT_NOT_CALLED =
  typeof SET_SCHEDULE_PAYMENT_NOT_CALLED;

export const POLL_CONFIRMATION_DETAILS = "flightBook/POLL_CONFIRMATION_DETAILS";
export type POLL_CONFIRMATION_DETAILS = typeof POLL_CONFIRMATION_DETAILS;

export const POLL_CONFIRMATION_DETAILS_CALL_STATE_SUCCESS =
  "flightBook/POLL_CONFIRMATION_DETAILS_CALL_STATE_SUCCESS";
export type POLL_CONFIRMATION_DETAILS_CALL_STATE_SUCCESS =
  typeof POLL_CONFIRMATION_DETAILS_CALL_STATE_SUCCESS;

export const POLL_CONFIRMATION_DETAILS_CALL_STATE_FAILED =
  "flightBook/POLL_CONFIRMATION_DETAILS_CALL_STATE_FAILED";
export type POLL_CONFIRMATION_DETAILS_CALL_STATE_FAILED =
  typeof POLL_CONFIRMATION_DETAILS_CALL_STATE_FAILED;

export const POLL_CONFIRMATION_DETAILS_CALL_STATE_PENDING =
  "flightBook/POLL_CONFIRMATION_DETAILS_CALL_STATE_PENDING";
export type POLL_CONFIRMATION_DETAILS_CALL_STATE_PENDING =
  typeof POLL_CONFIRMATION_DETAILS_CALL_STATE_PENDING;

export const SET_CONFIRMATION_DETAILS = "flightBook/SET_CONFIRMATION_DETAILS";
export type SET_CONFIRMATION_DETAILS = typeof SET_CONFIRMATION_DETAILS;

export const POLL_FINALIZED = "flightBook/POLL_FINALIZED";
export type POLL_FINALIZED = typeof POLL_FINALIZED;

export const POLL_FINALIZED_CALL_STATE_SUCCESS =
  "flightBook/POLL_FINALIZED_CALL_STATE_SUCCESS";
export type POLL_FINALIZED_CALL_STATE_SUCCESS =
  typeof POLL_FINALIZED_CALL_STATE_SUCCESS;

export const POLL_FINALIZED_CALL_STATE_FAILED =
  "flightBook/POLL_FINALIZED_CALL_STATE_FAILED";
export type POLL_FINALIZED_CALL_STATE_FAILED =
  typeof POLL_FINALIZED_CALL_STATE_FAILED;

export const POLL_FINALIZED_CALL_STATE_PENDING =
  "flightBook/POLL_FINALIZED_CALL_STATE_PENDING";
export type POLL_FINALIZED_CALL_STATE_PENDING =
  typeof POLL_FINALIZED_CALL_STATE_PENDING;

export const SET_FINALIZED = "flightBook/SET_FINALIZED";
export type SET_FINALIZED = typeof SET_FINALIZED;

export const RESET_FINALIZED = "flightBook/RESET_FINALIZED";
export type RESET_FINALIZED = typeof RESET_FINALIZED;

export const REDO_SEARCH = "flightBook/REDO_SEARCH";
export type REDO_SEARCH = typeof REDO_SEARCH;

export const ACKNOWLEDGE_PRICE_DIFFERENCE =
  "flightBook/ACKNOWLEDGE_PRICE_DIFFERENCE";
export type ACKNOWLEDGE_PRICE_DIFFERENCE = typeof ACKNOWLEDGE_PRICE_DIFFERENCE;

export const POPULATE_FLIGHT_BOOK_QUERY_PARAMS =
  "flightBook/POPULATE_FLIGHT_BOOK_QUERY_PARAMS";
export type POPULATE_FLIGHT_BOOK_QUERY_PARAMS =
  typeof POPULATE_FLIGHT_BOOK_QUERY_PARAMS;

export const SET_FLIGHT_BOOK_QUERY_PARAMS =
  "flightBook/SET_FLIGHT_BOOK_QUERY_PARAMS";
export type SET_FLIGHT_BOOK_QUERY_PARAMS = typeof SET_FLIGHT_BOOK_QUERY_PARAMS;

export const SET_SELECTED_REWARDS_ACCOUNT_REFERENCE_ID =
  "flightBook/SET_SELECTED_REWARDS_ACCOUNT_REFERENCE_ID";
export type SET_SELECTED_REWARDS_ACCOUNT_REFERENCE_ID =
  typeof SET_SELECTED_REWARDS_ACCOUNT_REFERENCE_ID;

export const SET_SELECTED_REWARDS_PAYMENT_TOTAL =
  "flightBook/SET_SELECTED_REWARDS_PAYMENT_TOTAL";
export type SET_SELECTED_REWARDS_PAYMENT_TOTAL =
  typeof SET_SELECTED_REWARDS_PAYMENT_TOTAL;

export const FETCH_PRODUCT_TO_EARN = "flightBook/FETCH_PRODUCT_TO_EARN";
export type FETCH_PRODUCT_TO_EARN = typeof FETCH_PRODUCT_TO_EARN;

export const SET_PRODUCT_EARN_VALUE = "flightBook/SET_PRODUCT_EARN_VALUE";
export type SET_PRODUCT_EARN_VALUE = typeof SET_PRODUCT_EARN_VALUE;

export const FETCH_ALL_EARN_FOR_PRODUCT =
  "hotelBook/FETCH_ALL_EARN_FOR_PRODUCT";
export type FETCH_ALL_EARN_FOR_PRODUCT = typeof FETCH_ALL_EARN_FOR_PRODUCT;

export const SET_ALL_EARN_VALUES = "flightBook/SET_ALL_EARN_VALUES";
export type SET_ALL_EARN_VALUES = typeof SET_ALL_EARN_VALUES;

export const SET_UP_FLIGHT_BOOK_PARAMS = "flightBook/SET_UP_FLIGHT_BOOK_PARAMS";
export type SET_UP_FLIGHT_BOOK_PARAMS = typeof SET_UP_FLIGHT_BOOK_PARAMS;

export const SET_UP_FLIGHT_BOOK_PARAMS_FAILED =
  "flightBook/SET_UP_FLIGHT_BOOK_PARAMS_FAILED";
export type SET_UP_FLIGHT_BOOK_PARAMS_FAILED =
  typeof SET_UP_FLIGHT_BOOK_PARAMS_FAILED;

export const SET_UP_FLIGHT_BOOK_PARAMS_SUCCESS =
  "flightBook/SET_UP_FLIGHT_BOOK_PARAMS_SUCCESS";
export type SET_UP_FLIGHT_BOOK_PARAMS_SUCCESS =
  typeof SET_UP_FLIGHT_BOOK_PARAMS_SUCCESS;

export const RESET_BOOKING_ERRORS = "flightBook/RESET_BOOKING_ERRORS";
export type RESET_BOOKING_ERRORS = typeof RESET_BOOKING_ERRORS;

export const REWARDS_CONVERSION_FAILED = "flightBook/REWARDS_CONVERSION_FAILED";
export type REWARDS_CONVERSION_FAILED = typeof REWARDS_CONVERSION_FAILED;

export const CHARGE_AGENT_BOOKING_FEE = "flightBook/CHARGE_AGENT_BOOKING_FEE";
export type CHARGE_AGENT_BOOKING_FEE = typeof CHARGE_AGENT_BOOKING_FEE;

export const CHARGE_AGENT_BOOKING_FEE_FAILED =
  "flightBook/CHARGE_AGENT_BOOKING_FEE_FAILED";
export type CHARGE_AGENT_BOOKING_FEE_FAILED =
  typeof CHARGE_AGENT_BOOKING_FEE_FAILED;

export const CHARGE_AGENT_BOOKING_FEE_SUCCESS =
  "flightBook/CHARGE_AGENT_BOOKING_FEE_SUCCESS";
export type CHARGE_AGENT_BOOKING_FEE_SUCCESS =
  typeof CHARGE_AGENT_BOOKING_FEE_SUCCESS;

export const SET_FLIGHT_BOOK_TYPE = "flightBook/SET_FLIGHT_BOOK_TYPE";
export type SET_FLIGHT_BOOK_TYPE = typeof SET_FLIGHT_BOOK_TYPE;

export const RESET_PAYMENT_CARD_SELECTED_ACCOUNTS =
  "flightBook/RESET_PAYMENT_CARD_SELECTED_ACCOUNTS";
export type RESET_PAYMENT_CARD_SELECTED_ACCOUNTS =
  typeof RESET_PAYMENT_CARD_SELECTED_ACCOUNTS;

export const SET_PRICE_DROP_PROTECTION = "flightBook/SET_PRICE_DROP_PROTECTION";
export type SET_PRICE_DROP_PROTECTION = typeof SET_PRICE_DROP_PROTECTION;

export const SET_IS_SIMILAR_FLIGHTS_ENABLED =
  "flightBook/SET_IS_SIMILAR_FLIGHTS_ENABLED";
export type SET_IS_SIMILAR_FLIGHTS_ENABLED =
  typeof SET_IS_SIMILAR_FLIGHTS_ENABLED;

export const SET_OFFER_TO_APPLY = "flightBook/SET_OFFER_TO_APPLY";
export type SET_OFFER_TO_APPLY = typeof SET_OFFER_TO_APPLY;

export const SET_BEST_OFFER_OVERALL = "flightBook/SET_BEST_OFFER_OVERALL";
export type SET_BEST_OFFER_OVERALL = typeof SET_BEST_OFFER_OVERALL;

export const SET_TRAVEL_WALLET_OFFERS = "flightBook/SET_TRAVEL_WALLET_OFFERS";
export type SET_TRAVEL_WALLET_OFFERS = typeof SET_TRAVEL_WALLET_OFFERS;

export const SET_TRAVEL_WALLET_CREDIT = "flightBook/SET_TRAVEL_WALLET_CREDIT";
export type SET_TRAVEL_WALLET_CREDIT = typeof SET_TRAVEL_WALLET_CREDIT;

export const SET_CREDIT_TO_APPLY = "flightBook/SET_CREDIT_TO_APPLY";
export type SET_CREDIT_TO_APPLY = typeof SET_CREDIT_TO_APPLY;

export const FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS =
  "flightBook/FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS";
export type FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS =
  typeof FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS;

export const FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS_CALL_STATE_SUCCESS =
  "flightBook/FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS_CALL_STATE_SUCCESS";
export type FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS_CALL_STATE_SUCCESS =
  typeof FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS_CALL_STATE_SUCCESS;

export const FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS_CALL_STATE_FAILURE =
  "flightBook/FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS_CALL_STATE_FAILURE";
export type FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS_CALL_STATE_FAILURE =
  typeof FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS_CALL_STATE_FAILURE;

// Corporate Travel
export const FETCH_CORP_USER_PASSENGERS =
  "flightBook/FETCH_CORP_USER_PASSENGERS";
export type FETCH_CORP_USER_PASSENGERS = typeof FETCH_CORP_USER_PASSENGERS;

export const SET_TRIP_PURPOSE = "flightBook/SET_TRIP_PURPOSE";
export type SET_TRIP_PURPOSE = typeof SET_TRIP_PURPOSE;

export const SET_CHFAR_EXERCISE_DISCOUNT_AMOUNT = "flightBook/SET_CHFAR_EXERCISE_DISCOUNT_AMOUNT";
export type SET_CHFAR_EXERCISE_DISCOUNT_AMOUNT = typeof SET_CHFAR_EXERCISE_DISCOUNT_AMOUNT;
