import React from "react";
import {
  getTotalPriceText,
  getCurrencySymbol,
  getPriceString,
  getRewardsString,
  tripTypeText,
} from "halifax";
import {
  Dealness,
  PredictionCopy,
  Prices,
  PriceDropProtectionEnum,
  Prediction,
  IsEligible,
  FiatPrice,
  RewardsPrice,
  TimeToLive,
  Cap,
  FareSliceDetails,
  getIsMixedClassByProps,
  PRICE_DROP_PROTECTION_MODAL_SUBTITLE,
  TripCategory,
  RecommendationV2Enum,
} from "redmond";
import { isCorpTenant } from "@capone/common";
import { getPriceFreezeRewardsString } from "./utils/helpers";
import {
  FlightShopStep,
  MulticityFlightShopChooseDepartureSteps,
} from "./reducer";
import { Dayjs } from "dayjs";
import { MulticityRoute } from "../search/reducer";
import { config } from "../../api/config";

export const VIEW_FLIGHTS = "Continue to Flights";
export const PRICE_PREDICTION = "Price Prediction";
export const PRICE_WATCH_COPY =
  "If you're still not ready to buy, watch this trip for deals.";
export const SEARCHING_FOR_FLIGHTS = "for flights";
export const SEARCHING_FOR_FLIGHTS_SECONDARY_MESSAGE = isCorpTenant(
  config.TENANT
)
  ? "Travel with confidence. Don't forget to look for our flexible booking options at checkout."
  : "If we recommend to book now, you'll get free price drop protection.";

export const PARADISE_SUBTITLE =
  "Book with flexibility and start your next journey here.";

export const PRICE_PREDICTION_CURRENT_PRICE_TITLE = "Current lowest price:";
export const getPricePredictionCurrentPrice = (isOneWay: boolean) =>
  `Current lowest price, ${
    isOneWay ? "one way, per traveler" : "round trip, per traveler"
  }`;
export const VIEW_PRICE_PREDICTION = "View price prediction";
export const PREDICTION_UNAVAILABLE = "Price prediction data is unavailable.";
export const ADJUST_OR_RESET_SUBTITLE =
  "Adjust or reset filters to see a price prediction for this trip.";
export const NO_DATA_ERROR_TITLE = "Not enough data.";
export const NO_DATA_ERROR_SUBTITLE =
  "We are unable to make an accurate prediction but you can still view flights.";
export const NO_FLIGHTS_ERROR_TITLE = "No flights found";
export const NO_FLIGHTS_FILTER_ERROR_SUBTITLE =
  "There are no flights on these dates that match your preferences.";
export const NO_FLIGHTS_ERROR_SUBTITLE =
  "There are no flights available for your selected dates.";
export const getPricePredictionCurrentPriceSubtitle = (
  tripType: TripCategory
) => `${tripTypeText[tripType]}, per traveler`;
export const CHANGE_DATES = "Change Dates";
export const RESET_FILTERS = "Reset Filters";
export const CLEAR_FILTERS_CTA_TEXT = "Clear all filters";
export const CHANGE_DATES_CTA_TEXT = "Change dates";
export const ADJUST_FILTERS = "Adjust Filters";
export const WAIT = "Wait";
export const BOOK_NOW = "Book Now";
export const WATCH_THIS_TRIP = "Watch this trip";
export const WATCH_TRIP = "Watch Trip";
export const WATCHING = "Stop Watching";
export const FORECASTED_HIGHEST = "Forecasted highest price:";
export const FORECASTED_LOWEST = "Forecasted lowest price:";
export const UNSUPPORTED_FILTERS_TITLE = "Unsupported Filters";
export const UNSUPPORTED_FILTERS =
  "Price prediction data can only be viewed if your filter is set to Nonstop or Any Number of Stops.";
export const UNSUPPORTED_FILTER_COPY =
  "The filters you've applied will not be taken into account when you watch this price.";
export const PRICE_WATCH_UNSUPPORTED_FILTER_COPY =
  "Watch this trip for deals. You will be notified when deals are available or prices start to rise.";
export const PRICE_FORECAST_TITLES = [
  { title: "Great price", dealness: Dealness.Great },
  { title: "Good price", dealness: Dealness.Good },
  { title: "Fair price", dealness: Dealness.Fair },
  { title: "Wait", dealness: Dealness.Wait },
];
export const PRICE_WATCH_TITLES = {
  header: "<strong>Sign up to</strong> watch this trip",
  title: "How it works",
  subtitle:
    "<strong>Not ready to buy?</strong> We'll watch your flight 24/7 and notify you when it's the best time to book.",
  watchedHeader: "You are now watching this trip",
  watchedTitle: "You're all set!",
  watchedSubtitle:
    "We'll notify you when there's a change to the price of this trip. To manage your watched trips, visit My Trips.",
  description:
    "By watching this trip you are agreeing to receive email updates and/or app notifications when the price of this trip changes. To manage your watched trips, visit My Trips.",
  buttonCopy: "Watch this trip",
};

export const PDP_TITLE = "How it works";
export const READ_TERMS_CONDITION_TEXT = "Read terms and conditions";
export const PRICE_FREEZE_DETAILS_READ_TERMS_LABEL =
  "Price freeze terms and conditions";
export const PDP_HEADER =
  "Book now with <span class='font-regular'>free price drop protection</span>";
export const PRICE_PREDICTION_HEADER =
  "<span class='font-regular'>Price prediction</span> allows you to book with confidence.";
export const PRICE_PREDICTION_SUBTITLE =
  "With help from our price predictions and alerts, you can save an average of 15% on flights.";
export const OR_SEPARATOR = "or";
export const SLASH_SEPARATOR = "/";

export const NOT_READY_TO_BOOK = "Not ready to book?";
export const WAITING_TO_BOOK = "Waiting to book?";

const getMonitoringDaysCount = (prediction: Prediction) => {
  if (prediction) {
    const days =
      prediction &&
      prediction.priceDropProtection &&
      prediction.priceDropProtection.PriceDropProtection ===
        PriceDropProtectionEnum.IsEligible
        ? (prediction.priceDropProtection as IsEligible).monitoringDuration
            .inSeconds / 86400
        : 0;
    return days;
  } else {
    return "";
  }
};

export const getMaxRefund = (prediction: Prediction) => {
  if (prediction) {
    const maxRefund =
      prediction.priceDropProtection &&
      prediction.priceDropProtection.PriceDropProtection ===
        PriceDropProtectionEnum.IsEligible &&
      prediction.priceDropProtection.maximumRefund.display;
    return maxRefund;
  } else {
    return "";
  }
};

export const getSubtitle = (
  prediction: Prediction,
  isPriceDropCreditEnabled: boolean
) => {
  const monitoringDays = getMonitoringDaysCount(prediction);
  const maxRefund = getMaxRefund(prediction);
  return PRICE_DROP_PROTECTION_MODAL_SUBTITLE(
    monitoringDays,
    maxRefund,
    isPriceDropCreditEnabled
  );
};

export enum WatchState {
  JustWatched,
  Watched,
  NotWatching,
}

export const getLowestPriceInRewards = (
  lowestPrice: Prices,
  selectedRewardsAccountId: string | undefined,
  separator: string
) => {
  if (
    !selectedRewardsAccountId ||
    !lowestPrice.rewards[selectedRewardsAccountId]
  ) {
    return "";
  }

  return ` ${separator} ${getRewardsString(
    lowestPrice.rewards[selectedRewardsAccountId || ""],
    true
  )}`;
};

export const getLowestPriceInFiat = (lowestPrice: Prices) => {
  return `${lowestPrice.fiat.currencySymbol}${Math.round(
    lowestPrice.fiat.value
  )}`;
};

export const getPriceRecommendationTitle = (
  watchState: WatchState,
  copy: PredictionCopy
) => {
  switch (watchState) {
    case WatchState.JustWatched:
      return copy?.recommendationTitleJustWatched.join(" ");
    case WatchState.Watched:
      return copy?.recommendationTitleWatching.join(" ");
    default:
      return copy?.recommendationTitle.join(" ");
  }
};

// TODO move logic to determine appropriate copy to the BE

export const getPriceRecommendationSubtitle = (
  watchState: WatchState,
  copy: PredictionCopy,
  dealness: Dealness,
  isMobile: boolean,
  recommendationV2: RecommendationV2Enum
) => {
  let subtitle = "";
  const showSubtitle = dealness === Dealness.Wait && isMobile;
  switch (watchState) {
    case WatchState.JustWatched:
      if (copy?.recommendationBodyJustWatched.length > 1) {
        subtitle = copy?.recommendationBodyJustWatched[0];
      } else if (showSubtitle) {
        subtitle = copy?.recommendationBodyJustWatched[0];
      }
      break;
    case WatchState.Watched:
      if (copy?.recommendationBodyWatching.length > 1) {
        subtitle = copy?.recommendationBodyWatching[0];
      } else if (showSubtitle) {
        subtitle = copy?.recommendationBodyWatching[0];
      }
      break;
    default:
      if (
        copy?.recommendationBody.length > 1 ||
        recommendationV2 === RecommendationV2Enum.BuyNeutral ||
        recommendationV2 === RecommendationV2Enum.WaitNeutral
      ) {
        subtitle = copy?.recommendationBody[0];
      } else if (showSubtitle) {
        subtitle = copy?.recommendationBody[0];
      }
      break;
  }
  return subtitle;
};

export const getPriceWatchCopy = (
  watchState: WatchState,
  hasUnsupportedPredictionFilters: boolean,
  copy?: PredictionCopy
) => {
  if (copy) {
    if (hasUnsupportedPredictionFilters)
      return PRICE_WATCH_UNSUPPORTED_FILTER_COPY;
    switch (watchState) {
      case WatchState.JustWatched:
        if (copy.recommendationBodyJustWatched.length > 1) {
          return copy.recommendationBodyJustWatched[
            copy.recommendationBodyJustWatched.length - 1
          ];
        }
        return copy.recommendationBodyJustWatched[0];
      case WatchState.Watched:
        if (copy?.recommendationBodyWatching.length > 1) {
          return copy.recommendationBodyWatching[
            copy.recommendationBodyWatching.length - 1
          ];
        }
        return copy.recommendationBodyWatching[0];
      case WatchState.NotWatching:
        if (copy?.recommendationBody.length > 1) {
          return copy.recommendationBody[copy?.recommendationBody.length - 1];
        }
        return copy.recommendationBody[0];
    }
  }
  return PRICE_WATCH_COPY;
};

export const getPriceWatchTitles = (copy?: PredictionCopy) => {
  if (copy) {
    return {
      header: "<strong>Sign up to</strong> watch this trip",
      title: "How it works",
      subtitle:
        "<strong>Not ready to buy?</strong> We'll watch your flight 24/7 and notify you when it's the best time to book.",
      watchedHeader: "<strong>You are now watching</strong> this trip",
      watchedTitle: "You're all set!",
      watchedSubtitle:
        "We'll notify you when there's a change to the price of this trip. To manage your watched trips, visit My Trips.",
      description:
        "By watching this trip you are agreeing to receive email updates and/or app notifications when the price of this trip changes. To manage your watched trips, visit My Trips.",
      buttonCopy: "Watch this trip",
    };
  } else {
    return PRICE_WATCH_TITLES;
  }
};

export const getPriceFreezeTitles = ({
  priceFreezeFiat,
  priceFreezeRewards,
  selectedRewardsAccountId,
  priceFreezeCap,
  duration,
  useLockPriceLanguage,
}: {
  priceFreezeFiat?: FiatPrice;
  priceFreezeRewards?: { [key: string]: RewardsPrice } | null;
  selectedRewardsAccountId?: string | null;
  priceFreezeCap?: Cap;
  duration?: TimeToLive;
  useLockPriceLanguage?: boolean;
}) => {
  const amount =
    priceFreezeFiat && getTotalPriceText({ price: priceFreezeFiat });
  const rewards = getPriceFreezeRewardsString(
    priceFreezeRewards,
    selectedRewardsAccountId
  );
  const currency = priceFreezeCap?.value.currency;

  return {
    header: `<span class='font-regular'>${
      useLockPriceLanguage ? "Lock" : "Freeze"
    } the price,</span> <span>book later.</span>`,
    title: `${
      useLockPriceLanguage ? "Lock" : "Freeze"
    } the current price for <strong>${
      duration ? getDurationText(duration) : ""
    }</strong> for <strong>${amount}</strong>${
      rewards ? ` or <strong>${rewards}</strong>` : ""
    } per traveler.`,
    increaseText: `<strong>If the price goes up after you ${
      useLockPriceLanguage ? "lock it" : "freeze"
    },</strong> we'll cover the difference up to ${getPriceString({
      price: priceFreezeCap?.value.amount || 0,
      currencySymbol: currency ? getCurrencySymbol(currency) : undefined,
    })} per traveler when you book with us.`,
    decreaseText: `<strong>If the price goes down after you ${
      useLockPriceLanguage ? "lock it" : "freeze"
    },</strong> you pay the lower price when you book with us!`,
    similarFlightText:
      "<strong>If there aren't enough available seats when you try to book,</strong> you can get a full refund.",
    ctaText: "Read terms and conditions",
  };
};

export const getDurationText = (timeToLive: TimeToLive) => {
  const inDays = timeToLive.inDays;
  const inHours = timeToLive.inHours;
  const inSeconds = timeToLive.inSeconds;

  if (inDays) {
    return `${inDays} ${inDays > 1 ? "days" : "day"}`;
  } else if (inHours) {
    return `${inHours} ${inHours > 1 ? "hours" : "hour"}`;
  }
  // note: we are currently only offering price freezes as little as 12 hours, so it will never return "X second(s)"
  return `${inSeconds} ${inSeconds > 1 ? "seconds" : "second"}`;
};

export const getDurationTextFromSeconds = (timeToLive: number) => {
  const oneDayInSeconds = 86400;

  if (timeToLive >= oneDayInSeconds) {
    const numberOfDays = timeToLive / oneDayInSeconds;
    return `${numberOfDays} ${numberOfDays > 1 ? "days" : "day"}`;
  }

  const secondsInAHour = 3600;
  const timeToLiveInHours = timeToLive / secondsInAHour;

  return `${timeToLiveInHours} ${timeToLiveInHours > 1 ? "hours" : "hour"}`;
};

export const getPriceFreezeDefaultDurationEndText = (
  numberOfOffers: number,
  useNewText: boolean
) => {
  if (numberOfOffers === 1) {
    return ".";
  }
  return `${
    useNewText
      ? ", or choose another duration and fee"
      : ", or choose your own duration and price"
  }.`;
};

// TODO: we should get the destination/label without state/airport code from BE since we cannot guarantee split method to work 100%
const getShortLabel = (label: string) => {
  const segments = label.split(",");
  return segments.length > 0 ? segments[0] : label;
};
export const CHOOSING_FLIGHT_TEXT = (
  currentStep: FlightShopStep,
  origin: string,
  destination: string
) => {
  const isOutgoing = currentStep === FlightShopStep.ChooseDeparture;

  return `Choose ${isOutgoing ? "departure" : "return"} flight to ${
    isOutgoing ? getShortLabel(destination) : getShortLabel(origin)
  }`;
};

export const CHOOSING_MULTICITY_FLIGHT_TEXT = (
  flightShopProgress: MulticityFlightShopChooseDepartureSteps,
  multicityRoutes: MulticityRoute[]
) => {
  const destination = multicityRoutes[flightShopProgress]?.destination;
  if (destination) return `Choose flight to ${destination?.label}`;
  return "Choose flight";
};

export const FLIGHT_PRICES_TEXT = (tripCategory: TripCategory) => {
  const getTripCategoryText = () => {
    switch (tripCategory) {
      case TripCategory.ONE_WAY:
        return "single trip";
      case TripCategory.ROUND_TRIP:
        return "round trip";
      case TripCategory.MULTI_CITY:
        return isCorpTenant(config.TENANT)
          ? "for entire multi-city trip"
          : "per one-way flight";
    }
  };
  return `Prices are ${getTripCategoryText()}, per traveler`;
};

export const REVIEW_ITINERARY_TITLE_TEXT = "Review itinerary";

export const REVIEW_ITINERARY_SUBTITLE_TEXT =
  "Review the details of your trip before you continue";

export const CUSTOMIZE_TITLE_TEXT = "Customize";

export const CUSTOMIZE_SUBTITLE_TEXT =
  "Add or decline these options to continue";

export const REVIEW_ITINERARY_REBOOK_TITLE_TEXT =
  "Flight disruption assistance";

export const REVIEW_ITINERARY_REBOOK_SUBTITLE_TEXT = "Get help with your trip.";

// mixed cabin is true if the following conditions are met:
// - fareShelf rating of 3 or above AND one of the below:
//  - the shortBrandName is PREMIUM ECONOMY AND one of the segment has a cabinClassName of ECONOMY
//  - the shortBrandName is FIRST or BUSINESS (not premium economy) AND one of the segment includes the word "Economy" (PREMIUM ECONOMY/ECONOMY)
// note: checking using the shortBrandName instead of the cabinClassName because the cabinClassName mapping is currently incorrect in same cases (we were getting back a cabinClassName of FIRST instead of PREMIUM ECONOMY)

export const getIsMixedClass = (slice: FareSliceDetails): boolean => {
  return getIsMixedClassByProps({
    fareShelfRating: slice.fareShelf?.rating,
    fareShelfBrandName: slice.fareShelf?.brandName,
    fareShelfShortBrandName: slice.fareShelf?.shortBrandName,
    cabinClassNames: slice.fareDetails.segments.map(
      (segment) => segment.cabinClassName
    ),
  });
};

export const FLIGHT_SHOP_REBOOK_TITLE_TEXT = ({
  origin,
  destination,
  isSelectingReturnFlightForRebook,
}: {
  origin?: string;
  destination?: string;
  isSelectingReturnFlightForRebook: boolean;
}) => {
  return `Choose a new ${
    isSelectingReturnFlightForRebook ? "return" : "outbound"
  } flight ${
    origin && destination
      ? `from ${getShortLabel(origin)} to ${getShortLabel(destination)}`
      : "..."
  }`;
};

export const FLIGHT_SHOP_REBOOK_SUBTITLE_TEXT = `Rebook a new flight now at no additional cost.`;

export const FLIGHT_SHOP_REBOOK_LIST_TITLE_TEXT = ({
  isSelectingReturnFlightForRebook,
  departureDate,
}: {
  isSelectingReturnFlightForRebook: boolean;
  departureDate: Dayjs | null;
}) => {
  return `Choose a new ${
    isSelectingReturnFlightForRebook ? "return" : "outbound"
  } flight${
    departureDate
      ? ` on <strong>${departureDate.format("ddd, MMM DD")}</strong>`
      : ""
  }`;
};

export const EARN_ENHANCEMENT_SUBTITLE = (
  earnRate: string | number,
  productDisplayName: string
) => (
  <>
    <span className="font-bold">Earn {earnRate}X miles</span> on flights with
    your {productDisplayName} account.
  </>
);

export const CREDIT_AVAILABLE_WITH_NO_FEES_BANNER = `This option is non-refundable, but you're still eligible for a 100% travel credit from your airline.`;
export const CREDIT_AVAILABLE_WITH_FEES_BANNER = `This option is non-refundable, but you're still eligible for a 100% travel credit from your airline. Any cancellation penalties or re-booking fees will apply.`;

export const CREDIT_NOT_AVAILABLE_BANNER = "This option is non-refundable.";

export const TRAVEL_SALES_EVENT_ACTIVE_SUBTITLE =
  "Save up to $200 on select hotels with our first-ever travel sale.";
export const TRAVEL_SALES_EVENT_ACTIVE_CTA = "Explore destinations on sale";
