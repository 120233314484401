import { createSelector } from "@reduxjs/toolkit";
import {
  RewardsAccount,
  cardsToShowWithPriorityOnRewardsBanner,
} from "redmond";
import { IStoreState } from "../../../../reducers/types";

export const getIsFirstLaunch = (state: IStoreState) =>
  state.flightRewards.isFirstLaunch;

export const getAgentEmail = (state: IStoreState) =>
  state.flightRewards.agentEmail;

export const getRewardsAccounts = (state: IStoreState) =>
  state.flightRewards.rewardsAccounts;

export const getUserSelectedAccountReferenceId = (state: IStoreState) =>
  state.flightRewards.selectedAccountReferenceId;

export const getFetchRewardsAccountsCallState = (state: IStoreState) =>
  state.flightRewards.fetchRewardsAccountsCallState;

// sorted by rewards earn
export const getSortedRewardsAccounts = createSelector(
  getRewardsAccounts,
  (rewardsAccounts): RewardsAccount[] => {
    return rewardsAccounts.sort((prev, current) => {
      const earnMultiplier = (account: RewardsAccount) =>
        prev.earn.flightsMultiplier && current.earn.flightsMultiplier // should only use flightsMultiplier if prev/current has it or else diff values are compared
          ? account.earn.flightsMultiplier
          : account.earn.hotelsMultiplier ?? -1;
      return earnMultiplier(current) - earnMultiplier(prev);
    });
  }
);

export const getRewardsAccountWithLargestValue = createSelector(
  getRewardsAccounts,
  (rewardsAccounts): RewardsAccount | undefined => {
    const sortedAccts = [...rewardsAccounts].sort((prev, current) => {
      return (
        current.rewardsCashEquivalent.value - prev.rewardsCashEquivalent.value
      );
    });
    return sortedAccts[0];
  }
);

export const getEligibleRewardsAccountWithLargestValue = createSelector(
  getRewardsAccounts,
  (rewardsAccounts): RewardsAccount | undefined => {
    const sortedAccts = [...rewardsAccounts].sort((prev, current) => {
      // sorting [...rewardsAccounts] so it does not mutate the original rewardsAccounts array as that was sorted in getSortedRewardsAccounts by earn
      return (
        current.rewardsCashEquivalent.value - prev.rewardsCashEquivalent.value
      );
    });
    return sortedAccts.filter(
      (account) => account.allowRewardsRedemption ?? true
    )[0];
  }
);

// the rewards account selection doesn't have a default state where none of the available options is selected
export const getDefaultSelectedAccountReferenceId = createSelector(
  getSortedRewardsAccounts,
  (rewardsAccounts): string | undefined => {
    // return the id with largest earn
    if (rewardsAccounts.length > 0) {
      return rewardsAccounts[0].accountReferenceId;
    }

    return undefined;
  }
);

// this is the primary selector used outside the component
// either returns the user selected account id, or the default from above if not present
export const getSelectedAccountReferenceId = createSelector(
  getUserSelectedAccountReferenceId,
  getDefaultSelectedAccountReferenceId,
  (
    userSelectedAccountReferenceId,
    defaultSelectedAccountReferenceId
  ): string | undefined => {
    return userSelectedAccountReferenceId ?? defaultSelectedAccountReferenceId;
  }
);

export const getSelectedAccount = createSelector(
  getRewardsAccounts,
  getSelectedAccountReferenceId,
  (rewardsAccounts, referenceId): RewardsAccount | undefined => {
    return rewardsAccounts.find(
      (account) => account.accountReferenceId === referenceId
    );
  }
);

export const getSelectedAccountIndex = createSelector(
  getRewardsAccounts,
  getSelectedAccountReferenceId,
  (accounts, id) => {
    return accounts.findIndex((account) => account.accountReferenceId === id);
  }
);

export const getSelectedAccountReferenceIdIfRedemptionEnabled = createSelector(
  getSelectedAccountReferenceId,
  getSelectedAccount,
  (referenceId, selectedAccount) =>
    selectedAccount?.allowRewardsRedemption ?? true
      ? referenceId || undefined
      : undefined
);

export const getSortedRewardsAccountWithLargestEarnForBanner = createSelector(
  getRewardsAccounts,
  (rewardsAccounts): RewardsAccount[] => {
    return [...rewardsAccounts].sort((prev, current) => {
      const earnMultiplier = (account: RewardsAccount) =>
        prev.earn.flightsMultiplier && current.earn.flightsMultiplier // should only use flightsMultiplier if prev/current has it or else diff values are compared
          ? account.earn.flightsMultiplier
          : account.earn.hotelsMultiplier ?? -1;
      if (earnMultiplier(current) === earnMultiplier(prev)) {
        return cardsToShowWithPriorityOnRewardsBanner.includes(
          current.productDisplayName.toLowerCase()
        )
          ? 1
          : -1;
      }
      return earnMultiplier(current) - earnMultiplier(prev);
    });
  }
);

export const getRewardsAccountWithLargestEarnForBanner = createSelector(
  getSortedRewardsAccountWithLargestEarnForBanner,
  (sortedRewardsAccount): RewardsAccount => {
    return sortedRewardsAccount[0];
  }
);

export const getRewardsAccountsReferenceIds = createSelector(
  getRewardsAccounts,
  (rewardsAccounts): string[] => {
    return rewardsAccounts.map((account) => account.accountReferenceId);
  }
);

// Corporate Travel
export const getUserIsPrimaryCardHolder = createSelector(
  getRewardsAccounts,
  (rewardsAccounts) => {
    return rewardsAccounts.some(
      (account) => account.customerAccountRole === "Primary"
    );
  }
);

export const getAllowRewardsRedemptionInAnyAccount = createSelector(
  getRewardsAccounts,
  (rewardsAccounts): boolean => {
    return rewardsAccounts.some(
      (account) => account.allowRewardsRedemption ?? true
    );
  }
);
