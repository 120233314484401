import React from "react";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";

import styles from "./styles.module.scss";
import { usePreviousFlight } from "../../hooks/usePreviousFlight";
import { AirlineDisplay } from "./AirlineDisplay";
import { useDeviceTypes } from "halifax";

export const PreviousFlightBanner = () => {
  const previousFlight = usePreviousFlight();
  const { matchesMobile } = useDeviceTypes();

  if (!previousFlight) {
    return null;
  }

  const {
    formattedDate,
    departureTime,
    destinationName,
    destinationCode,
    arrivalTime,
    flightDuration,
    flightStops,
    airlines,
  } = previousFlight;

  return (
    <Box
      className={clsx(styles["previous-flight-banner"], {
        mobile: matchesMobile,
      })}
    >
      <Box className={styles["previous-flight-banner__card"]}>
        <Typography
          variant="subtitle2"
          component="p"
          className={styles["previous-flight-banner__card-title"]}
        >
          <strong>Select a new flight</strong>
        </Typography>
        <Typography
          className={styles["previous-flight-banner__card-copy"]}
          variant="body2"
        >
          We've applied the base fare and taxes from your previous flight, along
          with any applicable airline change policies, to the new flights below.{" "}
          <strong>Only pay the fare difference shown (if any).</strong>
        </Typography>
      </Box>
      <Box className={styles["previous-flight-banner__card"]}>
        <Typography
          variant="subtitle2"
          component="p"
          className={styles["previous-flight-banner__card-title"]}
        >
          <strong>{`Your previous flight to ${destinationName} (${destinationCode})`}</strong>
          {` on ${formattedDate}`}
        </Typography>
        <Box className={styles["flight-itinerary-details"]}>
          <Box>
            <Typography
              variant="body1"
              className={styles["flight-itinerary-details__title"]}
            >{`${departureTime} \u2013 ${arrivalTime}`}</Typography>
            <Box>
              {airlines.map(({ code, name }) => (
                <AirlineDisplay key={code} name={name} code={code} />
              ))}
            </Box>
          </Box>
          <Box>
            <Typography
              variant="body1"
              className={styles["flight-itinerary-details__title"]}
            >
              {flightDuration}
            </Typography>
            <Typography variant="body2">{flightStops}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
