// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bvr8WpBQcxN8UquGaW-FOw\\=\\={margin-top:10px;width:fit-content}.bvr8WpBQcxN8UquGaW-FOw\\=\\= .ISb0nBJNnlT0mQZ1DyepdA\\=\\={display:flex;column-gap:4px;align-items:center}.bvr8WpBQcxN8UquGaW-FOw\\=\\= .FXYteKQUmURyGGrTzIfJEw\\=\\={font-weight:600;color:var(--blue-9, #013d58);text-decoration:underline}.bvr8WpBQcxN8UquGaW-FOw\\=\\=:hover .FXYteKQUmURyGGrTzIfJEw\\=\\=,.bvr8WpBQcxN8UquGaW-FOw\\=\\=:focus .FXYteKQUmURyGGrTzIfJEw\\=\\={text-decoration:none}", "",{"version":3,"sources":["webpack://./../../cap1-modules/flights-module/src/modules/change-for-any-reason/components/ChfarMissionControlEditLink/styles.module.scss"],"names":[],"mappings":"AAAA,4BACE,eAAA,CACA,iBAAA,CACA,wDACE,YAAA,CACA,cAAA,CACA,kBAAA,CAEF,wDACE,eAAA,CACA,4BAAA,CACA,yBAAA,CAIA,4HACE,oBAAA","sourcesContent":[".chfar-mission-control-edit-link {\n  margin-top: 10px;\n  width: fit-content;\n  .chfar-mission-control-edit-link__content {\n    display: flex;\n    column-gap: 4px;\n    align-items: center;\n  }\n  .chfar-mission-control-edit-link__copy {\n    font-weight: 600;\n    color: var(--blue-9, #013d58);\n    text-decoration: underline;\n  }\n  &:hover,\n  &:focus {\n    .chfar-mission-control-edit-link__copy {\n      text-decoration: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chfar-mission-control-edit-link": "bvr8WpBQcxN8UquGaW-FOw==",
	"chfar-mission-control-edit-link__content": "ISb0nBJNnlT0mQZ1DyepdA==",
	"chfar-mission-control-edit-link__copy": "FXYteKQUmURyGGrTzIfJEw=="
};
export default ___CSS_LOADER_EXPORT___;
