import axios from "axios";
import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  airBookingApi,
  type SingleFlightItineraryResponse,
} from "@b2bportal/air-booking-api";

export const chfarItinerariesApi = createApi({
  reducerPath: "chfarItinerariesApi",
  baseQuery: fakeBaseQuery(),
  tagTypes: ["ChfarItineraries"],
  endpoints: (builder) => ({
    getChfarItineraryById: builder.query<SingleFlightItineraryResponse, string>(
      {
        queryFn: async (itineraryId) => {
          const request = {
            itineraryId,
            referenceDateTime: new Date().toISOString(),
          };
          try {
            const res = await airBookingApi(
              axios
            ).apiV0ItineraryFlightSinglePut(request);
            return { data: res.data };
          } catch (error: unknown) {
            if (axios.isAxiosError(error)) {
              return {
                error: {
                  status: error.response?.status,
                  message: error.message,
                },
              };
            }

            return {
              error: { error: (error as Error).message ?? "Unknown error" },
            };
          }
        },
        providesTags: ["ChfarItineraries"],
      }
    ),
  }),
});

export const { useGetChfarItineraryByIdQuery } = chfarItinerariesApi;
export const chfarItinerariesApiReducerPath = chfarItinerariesApi.reducerPath;
export const chfarItinerariesApiReducer = chfarItinerariesApi.reducer;
export const chfarItinerariesApiMiddleware = chfarItinerariesApi.middleware;

export type ChfarItinerariesApiState = ReturnType<
  typeof chfarItinerariesApi.reducer
>;
