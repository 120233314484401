import React, { useEffect, useState, useMemo, useContext } from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import {
  ExpandableCard,
  ExpandableCardContent,
  FlightSummaryRow,
  ActionButton,
  formatInterval,
  FlightCombinationBanner,
  getTotalPriceText,
  getRewardText,
  AirlineIcon,
  removeTimezone,
  getPricesWithComma,
  MixedCabinToolTip,
  ButtonWrap,
  Icon,
  IconName,
  CorpPolicyBanner,
  OutOfPolicyModal,
  CorpPolicyPill,
  HotelCrossSellAwarenessCard,
  B2BLoadingPopup,
} from "halifax";
import {
  Airport,
  FareDetails,
  TripDetails,
  PriceDropProtectionEnum,
  VIEWED_TRIP_SUMMARY,
  VIEWED_MULTICITY_TRIP_SUMMARY,
  PRICE_DROP_VIEWED,
  VIEWED_PRICE_DROP_DETAILS,
  FiatPrice,
  CallState,
  SelectedTravelOfferScreen,
  FlightShopType,
  TripCategory,
  VIEWED_MISSED_CONNECTION_GUARANTEE_MODAL,
  VirtualInterlineEntryPoint,
  VirtualInterlineModalProperties,
  VIEWED_VI_BAGGAGE_WARNING,
  DECLINED_VI_BAGGAGE_WARNING,
  CONFIRMED_VI_BAGGAGE_WARNING,
  VIEWED_SELF_TRANSFER_MODAL,
  EXPANDED_FLIGHT_DETAILS,
  ExpandedFlightDetailsProperties,
  ModalScreens,
  CustomerAccountRole,
  AncillaryKindEnum,
} from "redmond";
import dayjs from "dayjs";
import clsx from "clsx";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getClickCancelOOPModalEvent,
  getClickContinueOOPModalEvent,
  getShowOOPModalEvent,
  isCorpTenant,
  useShowPolicyBanner,
} from "@capone/common";

import "./styles.scss";
import { useSelector } from "react-redux";
import { Airport as b2bAirport } from "@b2bportal/air-shopping-api";
import { createStopoverString } from "../../reducer/utils";
import {
  FlightShopStep,
  ISelectedMulticityTrip,
  ISelectedTrip,
  MulticityFlightShopStep,
} from "../../reducer";
import { getSliceIndex } from "../../../../utils/flights";
import { FlightShopReviewDetails } from "..";
import { PATH_FREEZE, PATH_HOME } from "../../../../utils/urlPaths";
import { MobileItineraryDetailsModal } from "./components/MobileItineraryDetailsModal";
import { FlightShopReviewItineraryConnectorProps } from "./container";
import * as constants from "./constants";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import { getPriceFreezeTitles, getIsMixedClass } from "../../constants";
import { PriceFreezeEntry } from "../priceFreezeComponents/PriceFreezeEntry";
import { AddOnPricingBreakdown } from "../addOnComponents";
import {
  updateFlightShopStep,
  updateMulticityFlightShopStep,
} from "../../utils/parseQueryString";
import { getPriceFreezeRewardsString } from "../../utils/helpers";
import {
  AVAILABLE,
  PRICE_FREEZE,
  getExperimentVariant,
  useExperiments,
  TRAVEL_WALLET_OFFER_EXPERIMENT,
  FLIGHT_LIST_OPTIMIZATION_V1_EXPERIMENT,
  SEATS_UX_OPTIMIZATION,
  FARE_DETAILS_MOBILE,
  CONTROL,
  getExperimentVariantCustomVariants,
  HOTEL_CROSS_SELL_V3_EXPERIMENT,
  HOTEL_CROSS_SELL_V3_VARIANTS,
} from "../../../../context/experiments";
import {
  IPriceFreezeFlightDetailsModalOwnPropsForButton,
  IPriceFreezeEntryButtonOwnProps,
} from "../priceFreezeComponents/PriceFreezeEntryButton";
import {
  IPriceFreezeFlightDetailsModalOwnPropsForInfo,
  IPriceFreezeExplanationModalOwnPropsForInfo,
  IPriceFreezeEntryInfoOwnProps,
} from "../priceFreezeComponents/PriceFreezeEntryInfo";
import { TravelWalletDetailsBanner } from "../../../travel-wallet/components/TravelWalletDetailsBanner";
import { RebookReviewItinerary } from "../../../ancillary/components";
import { PriceDropProtection as PriceDropProtectionDetails } from "../../../book/components";
import { getPriceDropRefundTypeSelector } from "../../../ancillary/reducer";
import {
  IVirtualInterliningVariant,
  VirtualInterliningModal,
} from "../VirtualIinterliningModal";
import { SelfTransferReminderModal } from "../SelfTransferReminderModal";
import { airlinesCountTripSegment } from "../../v2/components/FlightList/components/FlightDetails/component";
import { MultipleAirlinesFareModal } from "../MultipleAirlinesFareModal";
import { FlightVICombinationBanner } from "../FlightVICombinationBanner";
import {
  FlightMissedConnectionGuarantee,
  SelfTransferBanner,
} from "../FlightMissedConnectionGuarantee";
import { ClientContext } from "../../../../App";
import { config } from "../../../../api/config";
import { IPriceFreezeDurationRadioGroupOwnProps } from "../priceFreezeComponents/PriceFreezeDurationRadioGroup";

export interface IFlightShopReviewItineraryProps
  extends FlightShopReviewItineraryConnectorProps,
    RouteComponentProps {
  isMobile: boolean;
  useLockPriceLanguage?: boolean;
  useDetailsPrefix?: boolean;
}

enum OpenModalEnum {
  MULTICITY0,
  MULTICITY1,
  MULTICITY2,
  MULTICITY3,
  MULTICITY4,
  DEPARTURE = "DEPARTURE",
  RETURN = "RETURN",
}

export type IOpenModal = OpenModalEnum | false;

export const FlightShopReviewItinerary = (
  props: IFlightShopReviewItineraryProps
) => {
  const {
    tripDetailsLoading,
    tripCategory,
    setMulticityFlightShopProgress,
    departureDate,
    returnDate,
    tripDetails,
    selectedTrip,
    fareDetails,
    perPaxPrices,
    perPaxRefundableFarePrices,
    history,
    isMultiTicket,
    isTripMarketedByNoPriceFreezeAirline,
    rewardsKey,
    fetchAncillaryOffer,
    setFlightShopProgress,
    populateFlightBookQueryParams,
    batchFetchCfarOffers,
    isMobile,
    airports,
    prediction,
    viewedTripSummaryProperties,
    priceDropViewedProperties,
    priceFreezeFiat,
    priceFreezeRewards,
    priceFreezeCap,
    currentPriceFreezeOffer,
    priceFreezeDuration,
    isAddOnOptionAvailable,
    fetchAncillaryOfferCallState,
    batchCfarOffersCallState,
    viewedPriceFreezeProperties,
    useLockPriceLanguage,
    useDetailsPrefix,
    offersByTripId,
    travelOfferProperties,
    isCfarEnabled,
    isPriceFreezeDurationEnabled,
    isCustomizePageMarketplaceEnabled,
    isInDisruptionProtectionRebook,
    getPriceFreezeOfferDataCallState,
    priceFreezeUserSelectedDurationProperties,
    flightShopType,
    hasActiveRefundableFare,
    refundableFaresProperties,
    credit,
    isCfarMulticityEnabled,
    isAirPriceFreezeNewReviewCTAEnabled,
    corporateTravel,
    productRedeemChoice,
    setPriceFreezeEntryPointStep,
    potentialCrossSellOffers,
    fetchCorpFintechEligibility,
    selectedAccount,
    hasTravelFusionFareBrand,
  } = props;

  const [openModal, setOpenModal] = useState<IOpenModal>(false);
  const [openVIVariantModal, setOpenVIVariantModal] = useState<
    IVirtualInterliningVariant | "reminder" | false
  >(false);
  const [openMultipleAirlinesFares, setOpenMultipleAirlinesFares] =
    useState(false);
  const [expandedCardKey, setExpandedCardKey] = useState<string>("");
  const [isHackerFare, setIsHackerFare] = useState(isMultiTicket);
  const [isOutgoingMixedClass, setIsOutgoingMixedClass] = useState(false);
  const [isReturnMixedClass, setIsReturnMixedClass] = useState(false);
  const [showPolicyModal, setShowPolicyModal] = useState(false);
  const [tryClickCta, setTryClickCta] = useState(false);

  const { isAutoApprovalEnabled, policies, sessionInfo, searchImage } =
    useContext(ClientContext);

  const modalType = isAutoApprovalEnabled
    ? "out_of_policy_auto"
    : "out_of_policy_24hr_review";

  const isInPolicy = Boolean(corporateTravel?.policyCompliance.isInPolicy);

  const expState = useExperiments();
  const priceFreezeGroup = getExperimentVariant(
    expState.experiments,
    PRICE_FREEZE
  );

  const isPriceFreezeEnabled = useMemo(
    () => priceFreezeGroup === AVAILABLE,
    [priceFreezeGroup]
  );

  const travelWalletOffer = getExperimentVariant(
    expState.experiments,
    TRAVEL_WALLET_OFFER_EXPERIMENT
  );

  const isTravelWalletOfferExperiment = useMemo(
    () => travelWalletOffer === AVAILABLE,
    [travelWalletOffer]
  );

  const bestOffer = useMemo(
    () => offersByTripId?.[tripDetails.id],
    [offersByTripId]
  );

  const isFlightListOptimizationExperiment = useMemo(
    () =>
      !isMobile &&
      getExperimentVariant(
        expState.experiments,
        FLIGHT_LIST_OPTIMIZATION_V1_EXPERIMENT
      ) === AVAILABLE,
    [expState]
  );

  const isSeatsUXOptimizationExperiment = useMemo(
    () =>
      getExperimentVariant(expState.experiments, SEATS_UX_OPTIMIZATION) ===
      AVAILABLE,
    [expState]
  );

  const isFareDetailsMobileExperiment = useMemo(
    () =>
      getExperimentVariant(expState.experiments, FARE_DETAILS_MOBILE) ===
      AVAILABLE,
    [expState]
  );
  const hotelCrossSellV3Variant = useMemo(
    () =>
      getExperimentVariantCustomVariants(
        expState.experiments,
        HOTEL_CROSS_SELL_V3_EXPERIMENT,
        HOTEL_CROSS_SELL_V3_VARIANTS
      ),
    [expState.experiments]
  );

  const isHotelCrossSellV3Experiment = hotelCrossSellV3Variant !== CONTROL;

  const priceDropRefundType = useSelector(getPriceDropRefundTypeSelector);

  const businessId =
    sessionInfo && "corporateInfo" in sessionInfo
      ? sessionInfo.corporateInfo.businessId
      : "";

  const isUserPrimary =
    selectedAccount?.customerAccountRole === CustomerAccountRole.Primary;

  useEffect(() => {
    // note: upon calling a shopSummary request, fetchAncillaryOfferCallState gets reset by the reducer, therefore allowing it to fetchAncillaryOffer again
    if (
      fetchAncillaryOfferCallState === CallState.NotCalled &&
      !isInDisruptionProtectionRebook
    ) {
      const canAddCfar =
        (!isMulticity || isCfarMulticityEnabled) && isCfarEnabled;
      fetchAncillaryOffer({
        preserveCfarId: canAddCfar && hasActiveRefundableFare,
        skipProducts: canAddCfar
          ? []
          : [AncillaryKindEnum.Cfar, AncillaryKindEnum.Chfar],
      });
      if (businessId && !isMobile && isUserPrimary) {
        fetchCorpFintechEligibility(businessId);
      }
    }
  }, [
    isCfarEnabled,
    fetchAncillaryOfferCallState,
    isInDisruptionProtectionRebook,
    hasActiveRefundableFare,
  ]);

  useEffect(() => {
    if (!isMulticity || isCfarMulticityEnabled) {
      const selectedTripId = selectedTrip.tripId;
      const selectedOWRTrip = selectedTrip as ISelectedTrip;
      const selectedMulticityTrip = selectedTrip as ISelectedMulticityTrip;
      const selectedFareId =
        selectedOWRTrip.returnFareId ??
        selectedOWRTrip.outgoingFareId ??
        selectedMulticityTrip.departureFareId;
      /*
              note: this condition is true when the user has reached the checkout page, done a page refresh, and then come back to this page;
              batchFetchCfarOffers needs to be called again to populate the RF
            */
      if (
        !!selectedTripId &&
        !!selectedFareId &&
        hasActiveRefundableFare &&
        batchCfarOffersCallState === CallState.NotCalled
      ) {
        batchFetchCfarOffers([
          {
            tripId: selectedTripId,
            fareId: selectedFareId,
          },
        ]);
      }
    }
  }, [selectedTrip, hasActiveRefundableFare, batchCfarOffersCallState]);

  useEffect(() => {
    setIsHackerFare(isMultiTicket);
  }, [isMultiTicket]);

  useEffect(() => {
    const priceFreezeTrackingProps = {
      ...viewedPriceFreezeProperties,
      frozen_price_total_usd: totalCost?.value,
    };

    /*
          note: when the PF-duration experiment is enabled, its tracking properties need to be sent through viewed_trip_summary;
          this gating logic helps to delay when viewed_trip_summary gets fired (it will wait until getPriceFreezeOfferData has finished)
        */
    if (
      !isPriceFreezeDurationEnabled ||
      getPriceFreezeOfferDataCallState === CallState.Success ||
      getPriceFreezeOfferDataCallState === CallState.Failed ||
      (isCorpTenant(config.TENANT) && isMulticity)
    ) {
      trackEvent({
        eventName: isMulticity
          ? VIEWED_MULTICITY_TRIP_SUMMARY
          : VIEWED_TRIP_SUMMARY,
        properties: {
          ...viewedTripSummaryProperties,
          ...travelOfferProperties,
          ...(isPriceFreezeEnabled ? priceFreezeTrackingProps : {}),
          ...priceFreezeUserSelectedDurationProperties,
          cfar_choice: refundableFaresProperties.cfar_choice,
          cfar_fare_choice: refundableFaresProperties.cfar_fare_choice,
          cfar_fare_offered: refundableFaresProperties.cfar_fare_offered,
          rf_adult_price_total_usd: hasActiveRefundableFare
            ? perPaxRefundableFarePrices?.fiat.value
            : undefined,
        },
      });
    }
  }, [getPriceFreezeOfferDataCallState]);

  const handleCardKeyChange = (cardKey: string) => {
    if (["departure", "return"].includes(cardKey)) {
      trackEvent({
        eventName: EXPANDED_FLIGHT_DETAILS,
        properties: {
          slice_direction:
            cardKey === constants.DEPARTURE_KEY ? "outbound" : "return",
          system_local_provider: "multiprovider",
          multi_ticket_type: fareDetails?.multiTicketType,
        } as ExpandedFlightDetailsProperties,
      });
    }
    setExpandedCardKey(cardKey === expandedCardKey ? "" : cardKey);
  };

  const isPDPEligible =
    prediction?.priceDropProtection?.PriceDropProtection ===
    PriceDropProtectionEnum.IsEligible;

  useEffect(() => {
    if (isPDPEligible) {
      trackEvent({
        eventName: PRICE_DROP_VIEWED,
        properties: {
          ...priceDropViewedProperties,
          page: "trip_summary",
        },
      });
    }
  }, [isPDPEligible]);

  const isRoundTrip = useMemo(
    () => tripCategory === TripCategory.ROUND_TRIP,
    [tripCategory]
  );

  const isMulticity = useMemo(
    () => tripCategory === TripCategory.MULTI_CITY,
    [tripCategory]
  );

  // Virtual Interline Flights will offer only one type of fare the most basic is safe to only check the first index
  const isVITripSelected = tripDetails.fareDetails[0]?.slices.some((slice) =>
    slice.fareDetails.segments.some((segment) => segment.isSelfTransferLayover)
  );

  useEffect(() => {
    if (!isMulticity && fareDetails) {
      setIsOutgoingMixedClass(getIsMixedClass(fareDetails.slices[0]));
      isRoundTrip &&
        setIsReturnMixedClass(getIsMixedClass(fareDetails.slices[1]));
    }
  }, [fareDetails]);

  if (
    (!tripDetails ||
      !fareDetails ||
      (!fareDetails.paxPricings && !isInDisruptionProtectionRebook)) &&
    !tripDetailsLoading
  ) {
    history.push(PATH_HOME);
    return null;
  }

  const totalCost = perPaxPrices?.fiat;
  const totalCostText = totalCost
    ? getTotalPriceText({
        price: totalCost,
      })
    : "";

  const rewardText = perPaxPrices?.rewards
    ? getRewardText({
        reward: perPaxPrices.rewards,
        round: true,
      })
    : "";

  const [showOfferBanner, setShowOfferBanner] = useState(false);

  useEffect(() => {
    setShowOfferBanner(
      isTravelWalletOfferExperiment &&
        !!bestOffer &&
        (credit &&
        Math.abs(credit.amount.amount) === Math.abs(bestOffer.amount.amount)
          ? true
          : !!bestOffer.shopPageBanner)
    );
  }, [credit, bestOffer]);

  const onClickCta = () => {
    if (isMobile && !isFareDetailsMobileExperiment) {
      if (isMulticity) {
        setMulticityFlightShopProgress(MulticityFlightShopStep.FareDetails);
      } else if (isVITripSelected) {
        trackEvent({
          eventName: VIEWED_VI_BAGGAGE_WARNING,
          properties: {},
        });
        setOpenVIVariantModal("reminder");
      } else {
        setFlightShopProgress(FlightShopStep.FareDetails);
      }
    } else if (
      isCustomizePageMarketplaceEnabled &&
      fetchAncillaryOfferCallState === CallState.InProcess
    ) {
      setTryClickCta(true);
    } else if (isAddOnOptionAvailable && isCustomizePageMarketplaceEnabled) {
      const isInChfarExercise = history.location.search.includes(
        "flightShopType=chfar-exercise"
      );
      if (isInChfarExercise) {
        populateFlightBookQueryParams({
          history,
        });
      } else if (isMulticity) {
        setMulticityFlightShopProgress(MulticityFlightShopStep.Customize);
        updateMulticityFlightShopStep(
          MulticityFlightShopStep.Customize,
          false,
          history
        );
      } else {
        setFlightShopProgress(FlightShopStep.Customize);
        updateFlightShopStep(FlightShopStep.Customize, false, history);
      }
    }
    // note: it catches the case where isFlightBookWithAncillariesActive is true
    // also catches MC case; we don't offer ancillaries with MC itineraries
    else if (isMulticity) {
      setMulticityFlightShopProgress(MulticityFlightShopStep.BookTrip);
      populateFlightBookQueryParams({ history });
    } else if (isVITripSelected) {
      trackEvent({
        eventName: VIEWED_VI_BAGGAGE_WARNING,
        properties: {},
      });
      setOpenVIVariantModal("reminder");
    } else {
      populateFlightBookQueryParams({ history });
    }
  };

  useEffect(() => {
    if (fetchAncillaryOfferCallState === CallState.InProcess) return;
    if (tryClickCta) {
      setTryClickCta(false);
      onClickCta();
    }
  }, [tryClickCta, fetchAncillaryOfferCallState]);

  const renderReviewItineraryCards = () => {
    if (isMulticity) {
      return (
        <>
          {fareDetails &&
            tripDetails &&
            tripDetails.slices.map((tripSlice, idx) => (
              <Box
                key={`multicity-review-card-${idx}`}
                className={clsx(
                  "flight-shop-review-itinerary-card",
                  "departure"
                )}
              >
                {expandedCardKey !==
                  constants.EXPANDED_MC_DEPARTURE_KEY(idx) && (
                  <Typography className="card-header">
                    {renderCardHeader(
                      constants.getMulticityReviewHeader(
                        idx,
                        airports[tripSlice.originCode] as b2bAirport,
                        airports[tripSlice.destinationCode] as b2bAirport,
                        dayjs(removeTimezone(tripSlice.departureTime)).toDate()
                      ),
                      isMulticity
                    )}
                  </Typography>
                )}
                <ExpandableCard
                  className="review-itinerary-card"
                  isMobile={isMobile}
                  expandedCardKey={expandedCardKey}
                  cardKey={constants.EXPANDED_MC_DEPARTURE_KEY(idx)}
                  handleCardKeyChange={() => {
                    handleCardKeyChange(
                      constants.EXPANDED_MC_DEPARTURE_KEY(idx)
                    );
                  }}
                  content={getExpandableCardContent({
                    sliceIndex: idx,
                    tripDetails,
                    fareDetails,
                    isMobile,
                    sliceDate: dayjs(tripSlice.departureTime).toDate(),
                    setFlightShopProgress: setMulticityFlightShopProgress,
                    airports,
                    isHackerFare,
                    isMixedCabinClass: isOutgoingMixedClass,
                    stacked: false,
                    nonHackerFareTitle: undefined,
                    hasActiveRefundableFare,
                    isMulticity,
                    isSeatsUXOptimizationExperiment,
                    isVITripSelected,
                    setOpenVIVariantModal,
                    setOpenMultipleAirlinesFares,
                    hasTravelFusionFareBrand,
                  })}
                />
              </Box>
            ))}
        </>
      );
    }
    return (
      <>
        {departureDate && fareDetails && (
          <Box
            className={clsx("flight-shop-review-itinerary-card", "departure")}
          >
            {expandedCardKey !== constants.DEPARTURE_KEY && (
              <Typography className="card-header">
                {renderCardHeader(
                  constants.getReviewCardHeader(
                    true,
                    airports[tripDetails.slices[0].destinationCode]
                      ? airports[tripDetails.slices[0].destinationCode].cityName
                      : tripDetails.slices[0].destinationName,
                    dayjs(
                      removeTimezone(tripDetails.slices[0].departureTime)
                    ).toDate(),
                    true
                  )
                )}
                {isOutgoingMixedClass && <MixedCabinToolTip />}
              </Typography>
            )}
            <ExpandableCard
              className="review-itinerary-card"
              isMobile={isMobile}
              expandedCardKey={expandedCardKey}
              cardKey={constants.DEPARTURE_KEY}
              handleCardKeyChange={() => {
                handleCardKeyChange(constants.DEPARTURE_KEY);
              }}
              content={getExpandableCardContent({
                sliceIndex: 0,
                tripDetails,
                fareDetails,
                isMobile,
                sliceDate: departureDate,
                setFlightShopProgress,
                airports,
                isHackerFare,
                isMixedCabinClass: isOutgoingMixedClass,
                stacked: false,
                nonHackerFareTitle: undefined,
                hasActiveRefundableFare,
                isSeatsUXOptimizationExperiment,
                isVITripSelected,
                setOpenVIVariantModal,
                setOpenMultipleAirlinesFares,
                hasTravelFusionFareBrand,
              })}
            />
          </Box>
        )}
        {isRoundTrip && returnDate && fareDetails && (
          <Box className={clsx("flight-shop-review-itinerary-card", "return")}>
            {expandedCardKey !== constants.RETURN_KEY && (
              <Typography className="card-header">
                {renderCardHeader(
                  constants.getReviewCardHeader(
                    false,
                    airports[tripDetails.slices[1].destinationCode]
                      ? airports[tripDetails.slices[1].destinationCode].cityName
                      : tripDetails.slices[1].destinationName,
                    dayjs(
                      removeTimezone(tripDetails.slices[1].departureTime)
                    ).toDate(),
                    true
                  )
                )}
                {isReturnMixedClass && <MixedCabinToolTip />}
              </Typography>
            )}
            <ExpandableCard
              className="review-itinerary-card"
              isMobile={isMobile}
              expandedCardKey={expandedCardKey}
              cardKey={constants.RETURN_KEY}
              handleCardKeyChange={() => {
                handleCardKeyChange(constants.RETURN_KEY);
              }}
              content={getExpandableCardContent({
                sliceIndex: 1,
                tripDetails,
                fareDetails,
                isMobile,
                sliceDate: returnDate,
                setFlightShopProgress,
                airports,
                isHackerFare,
                isMixedCabinClass: isReturnMixedClass,
                stacked: false,
                nonHackerFareTitle: undefined,
                hasActiveRefundableFare,
                isSeatsUXOptimizationExperiment,
                isVITripSelected,
                setOpenVIVariantModal,
                setOpenMultipleAirlinesFares,
                hasTravelFusionFareBrand,
              })}
            />
          </Box>
        )}
      </>
    );
  };

  const renderDesktopReviewContent = () => (
    <Box className="itinerary-cards-section">
      {isHotelCrossSellV3Experiment && potentialCrossSellOffers.length > 0 ? (
        <HotelCrossSellAwarenessCard
          offerText={potentialCrossSellOffers[0].mainDescription}
          offerPillText={potentialCrossSellOffers[0].subDescription}
        />
      ) : undefined}
      {isHackerFare && !isVITripSelected && (
        <FlightCombinationBanner
          className={clsx("review-itinerary-flight-combo-banner", "b2b")}
          description={constants.COMBINATION_FLIGHT_WARNING}
          toolTipCopy={constants.COMBINATION_FLIGHT_TOOLTIP}
        />
      )}
      {renderReviewItineraryCards()}
    </Box>
  );

  const renderMobileReviewContent = () => {
    if (isMulticity) {
      return (
        <Box className="mobile-itinerary-cards-section">
          {tripDetails.slices.map((tripSlice, idx) => (
            <Box key={idx} className="mobile-trip-card">
              {renderMobileFlightSummaryRow(
                true,
                tripDetails,
                dayjs(tripSlice.departureTime).toDate(),
                () => setOpenModal(OpenModalEnum[`MULTICITY${idx}`]),
                airports,
                isOutgoingMixedClass,
                true,
                idx
              )}
            </Box>
          ))}
        </Box>
      );
    }
    return (
      <Box className="mobile-itinerary-cards-section">
        {departureDate && fareDetails && (
          <Box className="mobile-trip-card">
            {renderMobileFlightSummaryRow(
              true,
              tripDetails,
              departureDate,
              () => {
                trackEvent({
                  eventName: EXPANDED_FLIGHT_DETAILS,
                  properties: {
                    slice_direction: "outbound",
                    system_local_provider: "multiprovider",
                    multi_ticket_type: fareDetails.multiTicketType,
                  } as ExpandedFlightDetailsProperties,
                });
                setOpenModal(OpenModalEnum.DEPARTURE);
              },
              airports,
              isOutgoingMixedClass
            )}
          </Box>
        )}
        {isRoundTrip && returnDate && fareDetails && (
          <Box className="mobile-trip-card">
            {renderMobileFlightSummaryRow(
              false,
              tripDetails,
              returnDate,
              () => {
                trackEvent({
                  eventName: EXPANDED_FLIGHT_DETAILS,
                  properties: {
                    slice_direction: "return",
                    system_local_provider: "multiprovider",
                    multi_ticket_type: fareDetails.multiTicketType,
                  } as ExpandedFlightDetailsProperties,
                });
                setOpenModal(OpenModalEnum.RETURN);
              },
              airports,
              isReturnMixedClass
            )}
          </Box>
        )}
      </Box>
    );
  };

  const getFlightShopReviewItineraryButtonCopy = () =>
    constants.getContinueText(getPricesWithComma(totalCostText));

  const priceFreezeFlightDetailsModalPropsForButton: IPriceFreezeFlightDetailsModalOwnPropsForButton =
    {
      handleGoToPriceFreezeCheckout: () => {
        populateFlightBookQueryParams({
          history,
          pathname: PATH_FREEZE,
          preserveQuery: true,
        });
      },
    };

  const priceFreezeFlightDetailsModalPropsForInfo: IPriceFreezeFlightDetailsModalOwnPropsForInfo =
    priceFreezeFlightDetailsModalPropsForButton;

  const PriceFreezeExplanationModalPropsForInfo: IPriceFreezeExplanationModalOwnPropsForInfo =
    {
      priceFreezeTitles: getPriceFreezeTitles({
        priceFreezeFiat,
        priceFreezeRewards,
        selectedRewardsAccountId: rewardsKey,
        priceFreezeCap,
        duration: priceFreezeDuration,
        useLockPriceLanguage,
      }),
      useLockPriceLanguage,
    };

  const priceFreezeEntryInfoProps: IPriceFreezeEntryInfoOwnProps = {
    useLowestPriceText: false,
    fiatPrice: getTotalPriceText({
      price: priceFreezeFiat as FiatPrice,
    }),
    rewards: getPriceFreezeRewardsString(priceFreezeRewards, rewardsKey),
    duration: priceFreezeDuration,
    showFreezeIcon: true,
    highlightedRewards: false,
    useLockPriceLanguage,
    priceFreezeFlightDetailsModalProps:
      priceFreezeFlightDetailsModalPropsForInfo,
    priceFreezeExplanationModalProps: PriceFreezeExplanationModalPropsForInfo,
    withSmallBanner: false,
    useDetailsPrefix,
    titleCaseHeader: false,
    largeInfoDetailsInMobile: true,
    isAirPriceFreezeNewReviewCTAEnabled,
    isFromFlightShopReviewItinerary: true,
    prediction: null,
  };

  const priceFreezeEntryButtonProps: IPriceFreezeEntryButtonOwnProps = {
    longerTextInButton: true,
    useLockPriceLanguage,
    priceFreezeFlightDetailsModalProps:
      priceFreezeFlightDetailsModalPropsForButton,
    largeButton: true,
    handleGoToPriceFreezeCheckout: () => {
      setPriceFreezeEntryPointStep(FlightShopStep.ReviewItinerary);
      populateFlightBookQueryParams({
        history,
        pathname: PATH_FREEZE,
        preserveQuery: true,
      });
    },
    flightShopStep: FlightShopStep.ReviewItinerary,
    setCheapestEligibleOfferData: false,
  };

  const priceFreezeDurationRadioProps: IPriceFreezeDurationRadioGroupOwnProps =
    {
      isFromFlightsReviewItineraryScreen: true,
    };

  const showPolicyBanner = useShowPolicyBanner(policies);

  const priceFreezeEntryElement =
    !isPriceFreezeEnabled ||
    !currentPriceFreezeOffer ||
    isTripMarketedByNoPriceFreezeAirline ||
    hasActiveRefundableFare ||
    isVITripSelected ? (
      <></>
    ) : (
      <Box
        className={clsx(
          "flight-shop-review-itinerary-price-freeze-wrapper",
          "between-price-and-checkout"
        )}
      >
        <PriceFreezeEntry
          priceFreezeEntryButtonProps={priceFreezeEntryButtonProps}
          priceFreezeEntryInfoProps={priceFreezeEntryInfoProps}
          priceFreezeDurationRadioGroupProps={priceFreezeDurationRadioProps}
        />
      </Box>
    );

  const ReviewItineraryContainer = () => {
    switch (flightShopType) {
      case FlightShopType.DISRUPTION_PROTECTION_EXERCISE: {
        return (
          <Box
            className={clsx(
              "flight-shop-review-itinerary-container",
              "centered"
            )}
          >
            <RebookReviewItinerary
              isMobile={isMobile}
              isViProductRedeemChoice={
                productRedeemChoice === "missed_connection_vi"
              }
            />
          </Box>
        );
      }
      default: {
        const onClickContinue = (
          continueAnyway = false,
          isInPolicy?: boolean
        ) => {
          // note: if is capone tenant or flight is in policy, continue.
          if (isInPolicy || !isCorpTenant(config.TENANT)) {
            onClickCta();
          } else {
            // otherwise, flight is out of policy and show OOP modal
            if (!continueAnyway) {
              trackEvent(
                getShowOOPModalEvent(
                  ModalScreens.FLIGHTS_SHOP,
                  "flights",
                  modalType
                )
              );
              return setShowPolicyModal(true);
            }
            trackEvent(
              getClickContinueOOPModalEvent(
                ModalScreens.FLIGHTS_SHOP,
                "flights",
                modalType
              )
            );
            // if onClickContinue, user has acknowledged OOP modal and wishes to continue
            onClickCta();
          }
        };

        return (
          <Box
            className={clsx(
              "flight-shop-review-itinerary-container",
              "side-by-side",
              {
                "flight-list-optimization-experiment":
                  isFlightListOptimizationExperiment,
                "virtual-interline-review-itinerary": isVITripSelected,
              }
            )}
          >
            {tryClickCta &&
              fetchAncillaryOfferCallState === CallState.InProcess && (
                <B2BLoadingPopup
                  open
                  message="for offers"
                  image={searchImage ?? ""}
                  popupSize="desktop"
                />
              )}
            {isMobile
              ? renderMobileReviewContent()
              : renderDesktopReviewContent()}
            <Box
              className={clsx("flight-shop-review-itinerary-bottom-container", {
                mobile: isMobile,
              })}
            >
              <Box className="flight-shop-review-itinerary-bottom-wrapper">
                {!isMobile && (
                  <CorpPolicyBanner
                    variant="base"
                    corporateTravel={
                      showPolicyBanner ? corporateTravel : undefined
                    }
                    productType="flight"
                  />
                )}
                {isHotelCrossSellV3Experiment &&
                isMobile &&
                potentialCrossSellOffers.length > 0 ? (
                  <HotelCrossSellAwarenessCard
                    isMobile
                    offerText={potentialCrossSellOffers[0].mainDescription}
                    offerPillText={potentialCrossSellOffers[0].subDescription}
                  />
                ) : undefined}
                <Box className="flight-shop-review-itinerary-price-summary-wrapper">
                  {hasActiveRefundableFare ? (
                    <AddOnPricingBreakdown
                      actionButtonProps={{
                        message: getFlightShopReviewItineraryButtonCopy(),
                        onContinue: () => onClickContinue(false, isInPolicy),
                      }}
                      isMobile={isMobile}
                    />
                  ) : (
                    <>
                      <Box className="flight-shop-review-itinerary-summary-section">
                        {!isMulticity &&
                          prediction &&
                          isPDPEligible &&
                          !isVITripSelected && (
                            <PriceDropProtectionDetails
                              className="flight-shop-review-itinerary-pdp-details"
                              priceDropProtection={
                                prediction.priceDropProtection
                              }
                              onClick={() =>
                                trackEvent({
                                  eventName: VIEWED_PRICE_DROP_DETAILS,
                                  properties: {
                                    page: "trip_summary",
                                    refund_type: priceDropRefundType,
                                  },
                                })
                              }
                              hideSubtitle
                            />
                          )}
                        {isVITripSelected && (
                          <ButtonWrap
                            aria-labelledby="vi-missed-connection-popover"
                            className="vi-missed-connection-popover"
                            onClick={() => {
                              trackEvent({
                                eventName:
                                  VIEWED_MISSED_CONNECTION_GUARANTEE_MODAL,
                                properties: {
                                  entry_point:
                                    VirtualInterlineEntryPoint.Details,
                                } as VirtualInterlineModalProperties,
                              });
                              setOpenVIVariantModal(
                                "missedConnectionGuarantee"
                              );
                            }}
                          >
                            <Typography
                              variant="subtitle2"
                              className="title-wrapper"
                            >
                              <span className="title">
                                {isVITripSelected
                                  ? constants.MISSED_CONNECTION_REBOOKING_INCLUDED
                                  : constants.MISSED_CONNECTION_GUARANTEE_INCLUDED}
                              </span>
                            </Typography>
                            <Icon
                              name={IconName.InfoCircle}
                              className="info-icon"
                            />
                          </ButtonWrap>
                        )}
                        <Box className="price-text">
                          <Typography className="content" variant="body1">
                            {getPricesWithComma(totalCostText)}
                            {rewardText && (
                              <>
                                <span className="separator">/</span>
                                <span className="fare-reward">
                                  {rewardText}
                                </span>
                              </>
                            )}
                          </Typography>
                        </Box>
                        <Box className="description-text">
                          {constants.getTotalPriceDescription(tripCategory)}
                        </Box>
                        {isMobile && (
                          <CorpPolicyPill
                            variant="base"
                            productType="flight"
                            limit={undefined}
                            corporateTravel={
                              showPolicyBanner ? corporateTravel : undefined
                            }
                          />
                        )}
                      </Box>
                      {!isMulticity &&
                        !isAirPriceFreezeNewReviewCTAEnabled &&
                        priceFreezeEntryElement}
                      <Box className="flight-shop-review-itinerary-button-section">
                        <ActionButton
                          className={clsx("continue-button", "b2b")}
                          onClick={() => onClickContinue(false, isInPolicy)}
                          disabled={false}
                          message={getFlightShopReviewItineraryButtonCopy()}
                        />
                      </Box>
                    </>
                  )}
                </Box>
              </Box>
              {!isMulticity &&
                isAirPriceFreezeNewReviewCTAEnabled &&
                priceFreezeCap &&
                isPriceFreezeEnabled &&
                !isTripMarketedByNoPriceFreezeAirline && (
                  <Box
                    className={clsx(
                      "flight-shop-review-itinerary-bottom-wrapper",
                      "air-pf-new-review-cta"
                    )}
                  >
                    {priceFreezeEntryElement}
                  </Box>
                )}
              {isVITripSelected && (
                <Box className="virtual-interlining-no-refund-banner-container">
                  <Box className="virtual-interlining-no-refund-banner">
                    <Box className="title">
                      <Icon name={IconName.NotAllowedSign} />
                      <Typography
                        variant="body1"
                        dangerouslySetInnerHTML={{
                          __html: constants.NO_REFUNDABLE,
                        }}
                      />
                    </Box>
                    <Typography
                      variant="body1"
                      className="description"
                      dangerouslySetInnerHTML={{
                        __html: constants.NO_REFUNDABLE_DESCRIPTION,
                      }}
                    />
                  </Box>
                </Box>
              )}
              {!isMulticity && !isMobile && showOfferBanner && (
                <Box className="travel-offer-banner-container">
                  <TravelWalletDetailsBanner
                    offer={bestOffer}
                    variant="default"
                    screen={SelectedTravelOfferScreen.FLIGHT_REVIEW}
                  />
                </Box>
              )}
            </Box>
            <OutOfPolicyModal
              subtitle={
                isAutoApprovalEnabled ? (
                  "If you wish to proceed with your selection, admins will be notified upon booking that this flight was out of policy."
                ) : (
                  <Typography
                    className={clsx("flight-shop", "out-of-policy-subtitle1")}
                  >
                    If you wish to proceed with your selection, approval will be
                    required to book this flight.{" "}
                    <strong>
                      Seat selection is not available for out-of-policy flight
                      options.
                    </strong>
                  </Typography>
                )
              }
              isMobile={isMobile}
              isOpen={showPolicyModal}
              onClose={() => setShowPolicyModal(false)}
              onContinue={() => {
                onClickContinue(true, isInPolicy);
              }}
              onChangeSelection={() => {
                trackEvent(
                  getClickCancelOOPModalEvent(
                    ModalScreens.FLIGHTS_SHOP,
                    "flights",
                    modalType
                  )
                ).then(() => {
                  location.reload();
                });
              }}
              isApprovalRequired={
                policies?.settings && policies.settings.isApprovalRequired
              }
            />
          </Box>
        );
      }
    }
  };

  const ReviewItineraryModals = () => {
    switch (flightShopType) {
      case FlightShopType.DISRUPTION_PROTECTION_EXERCISE:
        return null;
      default: {
        const isDeparture = openModal === OpenModalEnum.DEPARTURE;
        const tripDetailsSlice = tripDetails?.slices[isDeparture ? 0 : 1];
        return (
          <MobileItineraryDetailsModal
            isMulticity={isMulticity}
            multicityTripSliceIndex={
              isMulticity ? (openModal as number) : undefined
            }
            openModal={openModal !== false}
            isDeparture={isDeparture}
            tripDetails={tripDetails}
            fareDetails={fareDetails}
            onClose={() => setOpenModal(false)}
            isMixedCabinClass={
              isDeparture ? isOutgoingMixedClass : isReturnMixedClass
            }
            isSeatsUXOptimizationExperiment={isSeatsUXOptimizationExperiment}
            flightCombinationBanner={
              airlinesCountTripSegment(tripDetailsSlice.segmentDetails) > 0 && (
                <FlightVICombinationBanner isMobile />
              )
            }
            missedConnectionGuarantee={
              isVITripSelected && (
                <>
                  <FlightMissedConnectionGuarantee
                    isVITripSelected={isVITripSelected}
                    isMobile={isMobile}
                    onClick={() => {
                      trackEvent({
                        eventName: VIEWED_MISSED_CONNECTION_GUARANTEE_MODAL,
                        properties: {
                          entry_point: VirtualInterlineEntryPoint.Details,
                        } as VirtualInterlineModalProperties,
                      });
                      setOpenVIVariantModal &&
                        setOpenVIVariantModal("missedConnectionGuarantee");
                    }}
                  />
                  <SelfTransferBanner
                    iconName={IconName.BookTravel}
                    onClick={() => {
                      trackEvent({
                        eventName: VIEWED_SELF_TRANSFER_MODAL,
                        properties: {
                          entry_point: VirtualInterlineEntryPoint.Details,
                        } as VirtualInterlineModalProperties,
                      });
                      setOpenVIVariantModal &&
                        setOpenVIVariantModal("selfCheck");
                    }}
                  />
                </>
              )
            }
          />
        );
      }
    }
  };
  const isOutgoing = expandedCardKey === "departure";
  return (
    <>
      <Box
        className={clsx("flight-shop-review-itinerary-root", {
          mobile: isMobile,
        })}
      >
        {!tripDetailsLoading && <ReviewItineraryContainer />}
      </Box>
      {openModal !== false && <ReviewItineraryModals />}
      {openVIVariantModal && openVIVariantModal !== "reminder" && (
        <VirtualInterliningModal
          isOpen
          variant={openVIVariantModal}
          isMobile={isMobile}
          fareDetails={tripDetails.fareDetails}
          isOutgoing={isOutgoing}
          airports={airports}
          segmentDetails={
            tripDetails.slices[isOutgoing ? 0 : 1]?.segmentDetails
          }
          onClose={() => setOpenVIVariantModal(false)}
        />
      )}
      {openVIVariantModal === "reminder" && (
        <SelfTransferReminderModal
          isOpen
          fareDetails={tripDetails.fareDetails}
          tripDetails={tripDetails}
          onClickContinue={() => {
            trackEvent({
              eventName: CONFIRMED_VI_BAGGAGE_WARNING,
              properties: {},
            });
            populateFlightBookQueryParams({ history });
          }}
          onClickDifferentFlight={() => {
            trackEvent({
              eventName: DECLINED_VI_BAGGAGE_WARNING,
              properties: {},
            });
            setFlightShopProgress(FlightShopStep.ChooseDeparture);
          }}
          onClose={() => setOpenVIVariantModal(false)}
          slices={tripDetails.slices}
          airports={airports}
        />
      )}
      {openMultipleAirlinesFares && (
        <MultipleAirlinesFareModal
          isOpen={openMultipleAirlinesFares}
          tripDetails={tripDetails}
          airports={airports}
          onClose={() => setOpenMultipleAirlinesFares(false)}
        />
      )}
    </>
  );
};

const getExpandableCardContent = (args: {
  sliceIndex: number;
  tripDetails: TripDetails;
  fareDetails: FareDetails;
  isMobile: boolean;
  sliceDate: Date;
  setFlightShopProgress:
    | ((progress: FlightShopStep) => void)
    | ((progress: MulticityFlightShopStep) => void);
  airports: { [key: string]: Airport };
  isHackerFare: boolean;
  isMixedCabinClass: boolean;
  stacked: boolean;
  nonHackerFareTitle?: string;
  hasActiveRefundableFare: boolean;
  isMulticity?: boolean;
  isSeatsUXOptimizationExperiment?: boolean;
  isVITripSelected: boolean;
  hasTravelFusionFareBrand: boolean;
  setOpenVIVariantModal: (variant: IVirtualInterliningVariant) => void;
  setOpenMultipleAirlinesFares: (value: boolean) => void;
}) => {
  const {
    tripDetails,
    fareDetails,
    isMobile,
    sliceDate,
    sliceIndex,
    setFlightShopProgress,
    airports,
    isHackerFare,
    isMixedCabinClass,
    stacked,
    nonHackerFareTitle,
    hasActiveRefundableFare,
    isMulticity = false,
    isSeatsUXOptimizationExperiment = false,
    isVITripSelected,
    hasTravelFusionFareBrand,
    setOpenVIVariantModal,
    setOpenMultipleAirlinesFares,
  } = args;

  const slice = tripDetails.slices[sliceIndex];

  const expandedTitle = isMulticity
    ? renderExpandedTitle(
        constants.getMulticityReviewHeader(
          sliceIndex,
          airports[slice.originCode] as b2bAirport,
          airports[slice.destinationCode] as b2bAirport,
          dayjs(removeTimezone(slice.departureTime)).toDate()
        ),
        sliceIndex,
        fareDetails,
        setFlightShopProgress,
        isMulticity
      )
    : renderExpandedTitle(
        constants.getReviewCardHeader(
          sliceIndex === 0,
          airports[slice.destinationCode]
            ? airports[slice.destinationCode].cityName
            : slice.destinationName,
          sliceDate,
          true
        ),
        sliceIndex,
        fareDetails,
        setFlightShopProgress,
        isMulticity
      );

  const cardContent: ExpandableCardContent = {
    title: renderDesktopFlightSummaryRow(
      sliceIndex,
      tripDetails,
      fareDetails,
      isMobile,
      isMulticity
    ),
    expandedTitle,
    body: (
      <FlightShopReviewDetails
        isVITripSelected={isVITripSelected}
        departure={sliceIndex === 0}
        isMulticity={isMulticity}
        multicitySliceIndex={sliceIndex}
        isMultiTicket={isHackerFare}
        tripDetails={tripDetails}
        fareDetails={fareDetails}
        isMixedCabinClass={isMixedCabinClass}
        stacked={stacked}
        nonHackerFareTitle={nonHackerFareTitle}
        hasActiveRefundableFare={hasActiveRefundableFare}
        isSeatsUXOptimizationExperiment={isSeatsUXOptimizationExperiment}
        setOpenVIVariantModal={setOpenVIVariantModal}
        setOpenMultipleAirlinesFares={setOpenMultipleAirlinesFares}
        hasTravelFusionFareBrand={hasTravelFusionFareBrand}
      />
    ),
  };

  return cardContent;
};

const renderDesktopFlightSummaryRow = (
  tripSliceIndex: number,
  tripDetails: TripDetails,
  fareDetails: FareDetails,
  isMobile: boolean,
  isMulticity?: boolean
) => {
  // if multicity, use the passed slice index, else we check for the `outgoing` value in the fareDetails
  const sliceIndex = isMulticity
    ? tripSliceIndex
    : getSliceIndex(tripSliceIndex === 0, fareDetails);
  const tripSlice = tripDetails.slices[sliceIndex];
  const firstTripSegment = tripSlice.segmentDetails[0];

  const fareDetailsSlice = fareDetails.slices[sliceIndex];
  const paxPricing = fareDetailsSlice?.paxPricings
    ? fareDetailsSlice.paxPricings[0]
    : undefined;

  const airlinesCount = airlinesCountTripSegment(tripSlice.segmentDetails);

  return (
    <>
      <FlightSummaryRow
        className="review-itinerary-flight-summary"
        airlineCode={firstTripSegment.airlineCode}
        airline={firstTripSegment.airlineName}
        fareClass={fareDetailsSlice.fareShelf?.brandName ?? ""}
        airlinesCount={airlinesCount}
        departureTime={dayjs(removeTimezone(tripSlice.departureTime)).format(
          "h:mm A"
        )}
        arrivalTime={dayjs(removeTimezone(tripSlice.arrivalTime)).format(
          "h:mm A"
        )}
        departureCode={tripSlice.originCode}
        arrivalCode={tripSlice.destinationCode}
        duration={formatInterval(
          dayjs(tripSlice.arrivalTime).diff(
            dayjs(tripSlice.departureTime),
            "minute",
            true
          )
        )}
        numStops={tripSlice.stops}
        layoverString={createStopoverString(tripSlice) || ""}
        bestFlightText={
          fareDetailsSlice.fareScore?.isBest
            ? constants.bestFlightText
            : undefined
        }
        cheapestFlightText={
          fareDetailsSlice.fareScore?.isCheapest
            ? constants.cheapestFlightText
            : undefined
        }
        bestQualityText={
          fareDetailsSlice.fareScore?.isBestQuality
            ? constants.bestQualityText
            : undefined
        }
        fastestText={
          fareDetailsSlice.fareScore?.isFastest
            ? constants.fastestText
            : undefined
        }
        price={
          paxPricing
            ? getTotalPriceText({ price: paxPricing.pricing.baseAmount.fiat })
            : ""
        }
        isMobile={isMobile}
        flightNumber={firstTripSegment.flightNumber}
      />
      <span className="view-details">View details</span>
    </>
  );
};

const renderExpandedTitle = (
  expandedTitle: string,
  sliceIndex: number,
  fareDetails: FareDetails,
  setFlightShopProgress:
    | ((progress: FlightShopStep) => void)
    | ((progress: MulticityFlightShopStep) => void),
  isMulticity: boolean
) => {
  const onClick = () => {
    if (isMulticity) {
      (setFlightShopProgress as (progress: MulticityFlightShopStep) => void)(
        MulticityFlightShopStep[`ChooseDeparture${sliceIndex}`]
      );
    } else {
      const flightShopStep =
        sliceIndex === 0
          ? FlightShopStep.ChooseDeparture
          : FlightShopStep.ChooseReturn;
      return (setFlightShopProgress as (progress: FlightShopStep) => void)(
        flightShopStep
      );
    }
  };
  return (
    <Box className="review-itinerary-expanded-title-wrapper">
      <Typography className="review-itinerary-expanded-title">
        {renderCardHeader(expandedTitle, isMulticity)}
        {getIsMixedClass(fareDetails.slices[sliceIndex]) && (
          <MixedCabinToolTip />
        )}
      </Typography>
      <div className="review-itinerary-expanded-title-actions-wrapper">
        <Button
          className="review-itinerary-change-button b2b"
          onClick={onClick}
        >
          {constants.CHANGE}
        </Button>
        <span className="view-details">View details</span>
      </div>
    </Box>
  );
};

const renderMobileFlightSummaryRow = (
  isDeparture: boolean,
  tripDetails: TripDetails,
  date: Date,
  setOpen: () => void,
  airports: { [key: string]: Airport },
  isMixedClass: boolean,
  isMulticity?: boolean,
  multicitySliceIndex?: number
) => {
  const sliceIndex = isMulticity
    ? multicitySliceIndex!
    : getSliceIndex(isDeparture, tripDetails);
  const tripSlice = tripDetails.slices[sliceIndex];
  const firstTripSegment = tripSlice.segmentDetails[0];
  const airlinesCount = airlinesCountTripSegment(tripSlice.segmentDetails);
  const { type, description } = constants.getReviewCardHeaderWithType(
    isDeparture,
    airports[tripSlice.destinationCode]
      ? airports[tripSlice.destinationCode].cityName
      : tripSlice.destinationName,
    date
  );

  const getAirportFromCode = (code: string) =>
    airports[code] ||
    Object.values(airports).find((airport) => airport.servedCity === code);

  const originAirport = getAirportFromCode(tripSlice.originCode);
  const destinationAirport = getAirportFromCode(tripSlice.destinationCode);
  const [locations, departureDate] = constants
    .getMulticityReviewHeader(
      multicitySliceIndex!,
      originAirport as b2bAirport,
      destinationAirport as b2bAirport,
      date,
      false
    )
    .split("-");

  return (
    <Box className="airline-details-with-chevron" onClick={setOpen}>
      <Box className="airline-details-with-title">
        <Typography className="card-header">
          <span className="bold">{isMulticity ? locations : type}</span>
          <span
            dangerouslySetInnerHTML={{
              __html: isMulticity ? departureDate : description,
            }}
          />
          {((isDeparture && isMixedClass) ||
            (!isDeparture && isMixedClass)) && <MixedCabinToolTip />}
        </Typography>
        <Box className="airline-details">
          <Box className="airline-details-left-container">
            <Typography variant="body1">
              {`${dayjs(removeTimezone(tripSlice.departureTime)).format(
                "h:mm A"
              )} - ${dayjs(removeTimezone(tripSlice.arrivalTime)).format(
                "h:mm A"
              )}`}
            </Typography>
            <Box className="card-airline-container">
              <AirlineIcon airlineCode={firstTripSegment.airlineCode} />
              <Typography variant="body2">
                {firstTripSegment.airlineName}
                {Boolean(airlinesCount) && (
                  <span className="vi-more red-text">{`+ ${airlinesCount} other`}</span>
                )}
              </Typography>
            </Box>
          </Box>
          <Box className="airline-details-right-container">
            <Typography variant="body1">
              {formatInterval(
                dayjs(tripSlice.arrivalTime).diff(
                  dayjs(tripSlice.departureTime),
                  "minute",
                  true
                )
              )}
            </Typography>
            <Typography variant="body2">
              {constants.getStopsString(tripSlice.stops)}
            </Typography>
          </Box>
        </Box>
      </Box>
      <FontAwesomeIcon className="mobile-right-chevron" icon={faChevronRight} />
    </Box>
  );
};

const renderCardHeader = (header: string, isMulticity?: boolean) => {
  const [fromHeader, dateHeader] = header.split(isMulticity ? "-" : ":");
  return (
    <>
      <span className="from">{fromHeader}</span>
      <span className="date">{dateHeader}</span>
    </>
  );
};
