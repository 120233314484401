import { all } from "redux-saga/effects";
import { sagas as flightSearch } from "../modules/search";
import { sagas as flightShop } from "../modules/shop";
import { sagas as flightBook } from "../modules/book";
import { sagas as flightFreeze } from "../modules/freeze";
import { sagas as flightAncillary } from "../modules/ancillary";
import { sagas as flightRewards } from "../modules/rewards";
import { sagas as flightTravelWallet } from "../modules/travel-wallet";
import { sagas as flightToHotelCrossSell } from "../modules/cross-sell";

export function* rootSaga() {
  yield all([
    flightSearch.watchFetchOriginCategories(),
    flightSearch.watchFetchDestinationCategories(),
    flightSearch.watchFetchDepartureCalendar(),
    flightSearch.watchFetchUserFlightPreferences(),

    flightShop.watchSetUpQueryParams(),
    flightShop.watchFetchTripSummariesSaga(),
    flightShop.watchFetchTripSummariesForPredictionSaga(),
    flightShop.watchFetchSimilarFlightsSaga(),
    flightShop.watchFetchTransferSimilarFlightsSaga(),
    flightShop.watchFetchAncillaryOfferSaga(),
    flightShop.watchFetchCorpFintechEligibilitySaga(),
    flightShop.watchFetchTripSummariesV2Saga(),
    flightShop.watchFetchTripSummariesV3Saga(),
    flightShop.watchFetchMulticityTripSummariesSaga(),
    flightShop.watchFetchTripSummariesForPredictionV2Saga(),
    flightShop.watchFetchTripDetailsSaga(),
    flightShop.watchCreateWatch(),
    flightShop.watchListWatches(),
    flightShop.watchUpdateWatch(),
    flightShop.watchDeleteWatch(),
    flightShop.watchListFlightShopPaymentMethods(),

    flightBook.watchSetUpQueryParams(),
    flightBook.watchFetchUserPassengers(),
    flightBook.watchDeletePassengerSaga(),
    flightBook.watchUpdateUserPassenger(),
    flightBook.watchUpdateUserPassengers(),
    flightBook.watchFetchCorpUserPassengers(),

    flightBook.watchDeletePaymentMethodSaga(),
    flightBook.watchFetchPaymentMethodSaga(),
    flightBook.watchListPaymentMethodsSaga(),
    flightBook.watchVerifyPaymentMethodSaga(),

    flightBook.watchCloseSessionSaga(),
    flightBook.watchSetUpFlightBookParamsSaga(),

    flightBook.watchValidatePassengersSaga(),
    flightBook.watchTripPricingSaga(),
    flightBook.watchSchedulePriceQuoteSaga(),
    flightBook.watchPollPriceQuoteSaga(),
    flightBook.watchSchedulePaymentSaga(),
    flightBook.watchPollFinalizedSaga(),

    flightBook.watchProductToEarnSaga(),
    flightBook.watchFetchAllEarnForProductSaga(),

    flightBook.watchChargeAgentBookingFeeSaga(),

    flightBook.watchFetchApplicableTravelWalletItemsSaga(),

    flightFreeze.watchSetUpFlightFreezeParams(),
    flightFreeze.watchGetPriceFreezeOffer(),
    flightFreeze.watchGetPriceFreezeOfferData(),
    flightFreeze.watchGenerateCustomPriceFreezeOffer(),
    flightFreeze.watchInitializeOfferDataAndCustomOffer(),
    flightFreeze.watchFetchPriceFreeze(),
    flightFreeze.watchRefundPriceFreeze(),
    flightFreeze.watchFetchPriceFreezeFareQuote(),
    flightFreeze.watchCancelPriceFreezeFareQuote(),
    flightFreeze.watchFetchTravelWalletCreditHistorySaga(),
    flightFreeze.experimentsSagas.watchFetchPriceFreezeOfferDataForFareSaga(),

    flightAncillary.watchFetchRebookSummary(),
    flightAncillary.watchExerciseDisruptionProtectionRebook(),
    flightAncillary.watchFetchSingleFlightItinerary(),
    flightAncillary.watchFetchFlightDisruptionByItineraryId(),
    flightAncillary.watchBatchFetchCfarOffers(),
    flightAncillary.watchFetchAncillaryPostBookingOffer(),
    flightAncillary.purchaseAncillaryPostBookingOffer(),

    flightRewards.watchFetchRewardsAccounts(),
    flightBook.watchSeatMapSaga(),

    flightBook.watchFetchPriceDropEligibilitySaga(),

    flightTravelWallet.watchFetchTravelWalletDetails(),
    flightTravelWallet.watchFetchTravelWalletCreditHistorySaga(),

    flightToHotelCrossSell.watchFetchCrossSellHotelAvailability(),
    flightToHotelCrossSell.watchfetchUpcomingFlightCrossSellSaga(),
    flightToHotelCrossSell.watchFetchPotentialCrossSellOffersSaga(),
  ]);
}
